import { useContext } from "react";
import axios from "axios";
import { Context } from "../context/Context";
import { useNavigate } from "react-router-dom";
const ENDPOINT = process.env.REACT_APP_ENDPOINT;


const useApis = () => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("bearerToken");
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const { setIsLoading, setUserData,
    setIsAuthorized, } = useContext(Context)
  const navigate = useNavigate();

  axios.interceptors.response.use(
    response => response, // If the response is successful, just return it
    error => {
      if (error.response && error.response.status === 409) {
        setIsAuthorized(false)
        setUserData({})

        navigate("/")
      }
      return Promise.reject(error);
    }
  );

  function objectToQueryString(obj) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join('&');
  }

  const loadingFun = (loadingOff, value) => {
    if (!loadingOff) {
      setIsLoading(value)
    }
  }

  const jsonApi = async (url, queryObj, data, loadingOff) => {
    try {
      loadingFun(loadingOff, true)
      const res = await axios[url.method](`${ENDPOINT}${url.path}${queryObj ? `?${objectToQueryString(queryObj)}` : ""}`, data);
      loadingFun(loadingOff, false)
      return { error: false, data: res.data }
    } catch (error) {
      loadingFun(loadingOff, false)
      return { error: true, message: error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong" }
    }
  }

  const mediaApi = async (url, queryObj, formData, loadingOff) => {
    try {
      loadingFun(loadingOff, true)
      const res = await axios[url.method](`${ENDPOINT}${url.path}${queryObj ? `?${objectToQueryString(queryObj)}` : ""}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      loadingFun(loadingOff, false)
      return { error: false, data: res.data }
    } catch (error) {
      loadingFun(loadingOff, false)
      return { error: true, message: error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong" }
    }
  }



  return {

    jsonApi,
    mediaApi
  };

};

export default useApis;