import React, { forwardRef, useImperativeHandle, useState } from "react";

const HierarchyUserInfo = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit: (param) => props.submitForm({}, param)(),
  }));
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(5);

  const filterUser =
    props?.data?.users.filter((user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const totalData = filterUser.length;
  const totalPages = Math.ceil(filterUser.length / limit);
  const startIndex = (currentPage - 1) * limit;
  const endIndex = Math.min(startIndex + limit, totalData);
  // const endIndex = Math.min(currentPage + limit, totalData);

  const currentUser = filterUser.slice(startIndex, startIndex + limit) || [];

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  return (
    <div className="my-5 w-100">
      <label className="font-weight-bold mb-4">User Info</label>
      <div className="row mb-4">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length">
            <label style={{display: "flex", alignItems: "center", gap: "8px"}}>
              Show
              <select
                className="form-control-sm"
                value={limit}
                onChange={(e) => {
                  setLimit(Number(e.target.value));
                  setCurrentPage(1); // Reset to first page on limit change
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              entries
            </label>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_filter">
            <label>
              Search:
              <input
                id="search-input"
                type="text"
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control-sm"
                style={{
                  width: "200px",
                  padding: "6px",
                  boxSizing: "border-box",
                  marginLeft:"8px",
                }}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table display no-wrap w-100 user_info_table">
          <thead>
            <tr>
              <th colSpan={3}>
                <a href="#!">{props?.data?.contactName}</a>
                <i>(Super Administrator)</i>
              </th>
            </tr>
          </thead>
          <tbody>
            {filterUser.length === 0 ? (
              <tr>
                <td colSpan={3} className="text-center font-weight-bold">
                  Data not found
                </td>
              </tr>
            ) : (
              currentUser.map((user) => (
                <tr key={user._id}>
                  <td />
                  <td>
                    <a>{user.name}</a>
                  </td>
                  <td>
                    <a>{user.email}</a>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_info" role="status" aria-live="polite">
            {/* Showing Page {currentPage} of {totalPages} */}
            Showing {(currentPage - 1) * limit + 1} to {endIndex} of {totalData}{" "}
            entries
            {/* {Math.min(currentPage * limit, totalData)} of {totalData} entries */}
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_paginate">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  className="page-link"
                >
                  Previous
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => {
                const number = index + 1;
                return (
                  <li
                    key={number}
                    className={`page-item ${
                      number === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(number)}
                    >
                      {number}
                    </button>
                  </li>
                );
              })}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  className="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HierarchyUserInfo;

// const itemsPerPage = 5;
// const totalPages = Math.ceil((props?.data?.users.length || 0) / itemsPerPage);
// const startIndex = (currentPage - 1) * itemsPerPage;
// const currentUser = filterUser.slice(startIndex, startIndex + itemsPerPage) || [];
// return (

//   <div className="my-5 w-100">
//     <label className="font-weight-bold mb-4">User Info</label>

//     <div className="d-flex justify-content-end align-items-center mb-4">
//       <label
//         htmlFor="search-input"
//         style={{ marginRight: "10px", flexShrink: 0 }}
//       >
//         Search:
//       </label>
//       <input
//         id="search-input"
//         type="text"
//         placeholder="Search by name"
//         value={searchQuery}
//         onChange={handleSearchChange}
//         className="form-control"
//         style={{ width: "200px", padding: "6px", boxSizing: "border-box" }}
//       />
//     </div>

//     <div className="table-responsive">
//       <table className="table display no-wrap w-100 user_info_table">
//         <thead>
//           <tr>
//             <th colSpan={3}>
//               <a href="#!">{props?.data?.contactName} </a>
//               <i>(Super Administrator)</i>
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {filterUser.length === 0 ? (
//             <tr>
//               <td colSpan={3} className="text-center font-weight-bold">
//                 Data not found
//               </td>
//             </tr>
//           ) : currentUser.length > 0 ? (
//             currentUser.map((user) => (
//               /* {props?.data?.users.map((user) => ( */
//               <tr key={user._id}>
//                 <td />
//                 <td>
//                   <a>{user.name}</a>
//                 </td>
//                 <td>
//                   <a>{user.email}</a>
//                 </td>
//               </tr>
//             ))
//           ) : (
//             <></>
//           )}
//         </tbody>
//       </table>
//     </div>

//     <div className="pagination">
//       <button
//         onClick={() => handlePageChange(currentPage - 1)}
//         disabled={currentPage === 1}
//         className="page-link"
//       >
//         Previous
//       </button>
//       <span>
//         Page {currentPage} of {totalPages}
//       </span>
//       <button
//         onClick={() => handlePageChange(currentPage + 1)}
//         disabled={currentPage === totalPages}
//         className="page-link"
//       >
//         Next
//       </button>
//     </div>
//   </div>
// );
