import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { validateEvent } from "../validation/Validation";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

const EditEvents = () => {
    const { id } = useParams();
    const { jsonApi } = useApis();
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [dateCalendar, setDateCalendar] = useState(null);
    const {
        control,
        register,
        reset,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const fetchEvents = useCallback(async () => {
        try {
            const response = await jsonApi(appConstant.getByIdEvents, { id });
            if (response.error) {
                setError(response.error.message || "An error occurred");
            } else {
                reset({
                    eventsName: response.data.eventsName,
                    date: response.data.date,
                    eventsDescription: response.data.eventsDescription,
                });
                setDateCalendar(new Date(response.data.date)); // Set date calendar
                setError("");
            }
        } catch (err) {
            setError("An unexpected error occurred");
        }
    }, [id, reset]);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    const onSubmit = async (data) => {
        try {
            const formattedDate = dateCalendar ? dateCalendar.toISOString() : null;
            const payload = { ...data, date: formattedDate };
            console.log("Payload to send:", payload); // Log the payload

            const response = await jsonApi(appConstant.editEvents, { id }, payload, null);
            console.log("API Response:", response); // Log the API response

            if (!response.error) {
                setError("");
                fetchEvents();
                setSuccessMessage("Events updated successfully");
                reset();
            } else {
                setError(response.error.message || "An error occurred");
            }
        } catch (err) {
            setError("An unexpected error occurred");
        }
    };

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage("");
            }, 3000); // Adjust time (in milliseconds) as needed

            return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
        }
    }, [successMessage]);

    const today = new Date(); // Current date
    const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const handleDateChange = (date) => {
        if (date && date instanceof Date && !isNaN(date)) {
            setDateCalendar(date);
            setValue("date", date.toISOString(), { shouldValidate: true }); // Ensure correct date format
            setError("");
        } else {
            setError("Invalid date format. Please select a valid date.");
        }
    };

    return (
        <>
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="db-search d-none d-lg-inline-block"></div>
                    <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                        Edit New Event
                    </h3>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-11">
                        <div className="card">
                            <div className="card-body custom-field-button">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {error && (
                                        <div className="alert alert-danger" role="alert">
                                            {error}
                                        </div>
                                    )}
                                    {successMessage && (
                                        <div className="alert alert-success" role="alert">
                                            {successMessage}
                                        </div>
                                    )}
                                    <div className="row mb-4">
                                        <label className="col-lg-2 col-md-3 col-sm-4">
                                            Event Name
                                        </label>
                                        <div className="col-lg-10 col-md-9 col-sm-8">
                                            <Controller
                                                name="eventsName"
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        type="text"
                                                        maxLength={256}
                                                        placeholder="Event Name"
                                                        className={`form-control ${errors.eventsName ? "is-invalid" : ""}`}
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: "Event Name is required",
                                                    validate: (value) => validateEvent(value, "Event Name"),
                                                }}
                                            />
                                            {errors.eventsName && (
                                                <p className="text-danger">{errors.eventsName.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label className="col-lg-2 col-md-3 col-sm-4">
                                            Date
                                        </label>
                                        <div className="col-lg-10 col-md-9 col-sm-8">
                                            <Controller
                                                name="date"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={dateCalendar}
                                                        onChange={(date) => handleDateChange(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        minDate={maxDate}
                                                        showIcon
                                                        customInput={
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.date ? "is-invalid" : ""}`}
                                                                placeholder="DD/MM/YYYY"
                                                                id="date"
                                                                {...field}
                                                            />
                                                        }
                                                    />
                                                )}
                                                rules={{
                                                    required: "Date is required",
                                                }}
                                            />
                                            {errors.date && (
                                                <p className="text-danger">{errors.date.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <label className="col-lg-2 col-md-3 col-sm-4">
                                            Event Description
                                        </label>
                                        <div className="col-lg-10 col-md-9 col-sm-8">
                                            <Controller
                                                name="eventsDescription"
                                                control={control}
                                                render={({ field }) => (
                                                    <textarea
                                                        placeholder="Event Description"
                                                        maxLength={1024}
                                                        className={`form-control ${errors.eventsDescription ? "is-invalid" : ""}`}
                                                        {...field}
                                                    />
                                                )}
                                                rules={{
                                                    required: "Event Description is required",
                                                    validate: (value) => validateEvent(value, "Event Description"),
                                                }}
                                            />
                                            {errors.eventsDescription && (
                                                <p className="text-danger">{errors.eventsDescription.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                                        <div className="col-lg-10 col-md-9 col-sm-8">
                                            <button
                                                type="submit"
                                                className="btn btn-sm btn-primary btn-rounded px-4"
                                            >
                                                Update Event
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-1 d-none d-xl-block">
                        <img
                            src="/assets/images/banner/ads.jpg"
                            className="mx-auto img-fluid"
                            title="Ads"
                        />
                    </div>
                </div>
            </div>   
        </>
    );
};
export default EditEvents;
