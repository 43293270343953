import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import "./DatePickerStyle.css"

const BeneficiaryUncoveredMonthsNotEnrolled = ({
  control,
  register,
  errors,
  item,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "beneficiaryUncoveredMonthsNotEnrolled",
  });

  return (
    <div>
      <h5 className="text-dark font-weight-medium mb-4 pt-4">
        {item.fieldShowName}
      </h5>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Start Date</th>
            <th>Incremental Number of Uncovered Months</th>
            <th>Cumulative Number of Uncovered Months</th>
          </tr>
        </thead>
        <tbody>
          {/* Render dynamic fields using the fields from useFieldArray */}
          {fields.map((beneficiaryUncoveredMonthsNotEnrolled, index) => (
            <tr key={beneficiaryUncoveredMonthsNotEnrolled.id}>
              <td>{index + 1}</td>

              <td className="w-30">
                <Controller
                  control={control}
                  name={`beneficiaryUncoveredMonthsNotEnrolled[${index}].startDate`}
                  defaultValue={
                    beneficiaryUncoveredMonthsNotEnrolled.startDate || ""
                  }

                  render={({ field }) => (
                    // <input type="text" className="form-control" {...field} />
                    <DatePicker
                      value={field.value}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      className="form-control custom-date-picker"
                      calendarPosition="bottom-center"
                      selected={field.value ? new Date(field.value) : null}
                    />
                  )}
                />
              </td>
              
              <td>
                <Controller
                  control={control}
                  name={`beneficiaryUncoveredMonthsNotEnrolled[${index}].incrementalUncoveredMonths`}
                  defaultValue={
                    beneficiaryUncoveredMonthsNotEnrolled.incrementalUncoveredMonths ||
                    ""
                  }
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>

              <td>
                <Controller
                  control={control}
                  name={`beneficiaryUncoveredMonthsNotEnrolled[${index}].cumulativeUncoveredMonths`}
                  defaultValue={
                    beneficiaryUncoveredMonthsNotEnrolled.cumulativeUncoveredMonths ||
                    ""
                  }
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  ×
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary ml-auto"
          onClick={() =>
            append({
              startDate: "",
              incrementalUncoveredMonths: "",
              cumulativeUncoveredMonths: "",
            })
          }
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default BeneficiaryUncoveredMonthsNotEnrolled;
