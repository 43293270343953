import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import "./DatePickerStyle.css"

const BeneficiaryPlanEnrollmentInformation = ({ control, register, errors, item }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "beneficiaryPlanEnrollmentInformation",
  });

  return (
    <div>
      <h5 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
       {item.fieldShowName}
      </h5>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Contract</th>
            <th>PBP </th>
            <th>Plan Type Code</th>
            <th>Description</th>
            <th>Start</th>
            <th>End</th>
            <th>Drug Plan</th>
          </tr>
        </thead>
        <tbody>
          {/* Render dynamic fields using the fields from useFieldArray */}
          {fields.map((beneficiaryEnrollment, index) => (
            <tr key={beneficiaryEnrollment.id}>
              <td>
                <Controller
                  control={control}
                  name={`beneficiaryPlanEnrollmentInformation[${index}].contract`}
                  defaultValue={beneficiaryEnrollment.contract || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              
              </td>
              <td>
                <Controller
                  control={control}
                  name={`beneficiaryPlanEnrollmentInformation[${index}].pbp`}
                  defaultValue={beneficiaryEnrollment.pbp || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              
              </td>
            


              <td>
                <Controller
                  control={control}
                  name={`beneficiaryPlanEnrollmentInformation[${index}].planTypeCode`}
                  defaultValue={beneficiaryEnrollment.planTypeCode || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
           
              </td>

              <td>
                <Controller
                  control={control}
                  name={`beneficiaryPlanEnrollmentInformation[${index}].description`}
                  defaultValue={beneficiaryEnrollment.description || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
           
              </td>

              <td>
              <Controller
                 control={control}
                 name={`beneficiaryPlanEnrollmentInformation[${index}].startDate`}
                 defaultValue={beneficiaryEnrollment.startDate || ""}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      className="form-control custom-date-picker"
                      calendarPosition="bottom-center"
                      selected={field.value ? new Date(field.value) : null}
                    />
                  )}
                />
                </td>
              <td>
                <Controller
                  control={control}
                  name={`beneficiaryPlanEnrollmentInformation[${index}].endDate`}
                  defaultValue={beneficiaryEnrollment.endDate || ""}
                  render={({ field }) => (
                    // <input type="text" className="form-control" {...field} />
                    <DatePicker
                      value={field.value}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      className="form-control custom-date-picker"
                      calendarPosition="bottom-center"
                      selected={field.value ? new Date(field.value) : null}
                    />
                  )}
                />
           
              </td>

              <td>
                <Controller
                  control={control}
                  name={`beneficiaryPlanEnrollmentInformation[${index}].drugPlan`}
                  defaultValue={beneficiaryEnrollment.drugPlan || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
           
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  ×
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary ml-auto"
          onClick={() =>
            append({
              contract: "",
              pbp: "",
              planTypeCode: "",
              description: "",
              startDate: "",
              endDate: "",
              drugPlan: "",
            })
          }
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default BeneficiaryPlanEnrollmentInformation;



// import React, { useState } from "react";

// const BeneficiaryPlanEnrollmentInformation = ({ errors, register, item }) => {
//   const [medications, setMedications] = useState([
//     { nameOfMed: "", quantity: "", dosage: "", reason: "" },
//   ]);

//   const addMedication = () => {
//     setMedications([
//       ...medications,
//       { nameOfMed: "", quantity: "", dosage: "", reason: "" },
//     ]);
//   };

//   const removeMedication = (index) => {
//     if (medications.length > 1) {
//       const newMedications = medications.filter((_, i) => i !== index);
//       setMedications(newMedications);
//     }
//   };
//   const handleChange = (index, field, value) => {
//     const newMedications = [...medications];
//     newMedications[index][field] = value;
//     setMedications(newMedications);
//   };

//   return (
//     <div>
//       {/* <h3>{item.fieldShowName}</h3> */}
      
//       <label htmlFor={item.fieldName} className="pt-4">
//           {item.fieldShowName}
//         </label>
//       <table className="table table-bordered">
//         <thead>
//           <tr>
//             <th>Contract</th>
//             <th>PBP </th>
//             <th>Plan Type Code</th>
//             <th>Description</th>
//             <th>Start</th>
//             <th>End</th>
//             <th>Drug Plan</th>
//           </tr>
//         </thead>
//         <tbody>
//           {["Current Plan", "Previous Plan"].map((medication, index) => (
//             <tr key={index}>
//               <td>{medication}</td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   onChange={(e) =>
//                     handleChange(index, "nameOfMed", e.target.value)
//                   }
//                   {...register(`medications[${index}].nameOfMeda`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   onChange={(e) =>
//                     handleChange(index, "nameOfMed", e.target.value)
//                   }
//                   {...register(`medications[${index}].nameOfMedq`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   onChange={(e) =>
//                     handleChange(index, "nameOfMed", e.target.value)
//                   }
//                   {...register(`medications[${index}].nameOfMedss`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   onChange={(e) =>
//                     handleChange(index, "nameOfMed", e.target.value)
//                   }
//                   {...register(`medications[${index}].nameOfMessd`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   onChange={(e) =>
//                     handleChange(index, "nameOfMed", e.target.value)
//                   }
//                   {...register(`medications[${index}].nameOfMedss`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   onChange={(e) =>
//                     handleChange(index, "nameOfMed", e.target.value)
//                   }
//                   {...register(`medications[${index}].nameOfMssed`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="row">
//         <div className="text-right mb-3 col-6">
//           {medications.length > 1 && (
//             <div className="d-flex">
//               <button
//                 type="button"
//                 className="btn btn-danger"
//                 onClick={() => removeMedication(medications.length - 1)}
//                 style={
//                   {
//                     // bottom: "10px",
//                     // right: "10px",
//                     // width: "15px", // Small width
//                     // height: "15px", // Small height (to make it circular)
//                     // borderRadius: "50%", // Circular shape
//                     // padding: "0", // Remove padding
//                     // fontSize: "15px", // Adjust font size to fit in the button
//                     // display: "flex", // Use flex to center the plus sign
//                     // justifyContent: "center",
//                     // alignItems: "center",
//                     // lineHeight: "1", // Ensure the text is centered vertically
//                   }
//                 }
//               >
//                 ×
//               </button>
//             </div>
//           )}
//         </div>
//         <div className="text-right mb-3 col-6 ">
//           <div className="d-flex justify-content-end">
//             <button
//               // type="button"
//               // className="btn btn-primary"
//               type="button"
//               className="btn btn-primary position-absolute"
//               onClick={addMedication}
//             >
//               +
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BeneficiaryPlanEnrollmentInformation;
