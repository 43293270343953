import React, { useContext, useEffect, useState } from "react";
import contactHeader from "../../../json/contactHeader.json";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ModelUi from "../../model/ModelUi";
import { CallCenterContext } from "../../../context/CallCenterContext";
import DataTable from "../DataTable";

const PersonalContactFavourites = () => {
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [personalFavDetails, setPersonalFavDetails] = useState([]);
  const [search, setSearch] = useState("");
  const { makeCall } = useContext(CallCenterContext);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const fav = true;

  const fetchPersonalContactFav = async () => {
    try {
      const personalFav = await jsonApi(
        appConstant.getContactPersonal,
        { page, limit, fav, search: search },
        null,
        true
      );

      if (!personalFav.error) {
        setPersonalFavDetails(personalFav.data.data || []); // Update state with actual data
        setTotalData(personalFav.data.total);
      } else {
        setError(
          personalFav.message ? personalFav.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchPersonalContactFav();
  }, [page, limit, search]);

  const [openModelForCall, setOpenModelForCall] = useState({
    model: false,
    array: [],
  });
  const setOpenModelForCallHandler = (array) => {
    setOpenModelForCall({ model: true, array });
  };
  const makeCallModelHandleClose = () => {
    setOpenModelForCall({ model: false, array: [] });
  };

  const navigate = useNavigate();

  const formattedDate = (timestamp, formatDob) => {
    if (!timestamp) return "N/A";
    const dob = moment(timestamp);
    return dob.format(formatDob);
  };

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                Personal Favorites
              </h4>
              <div className="row ">{/*/Col*/}</div>

              <DataTable
                headers={contactHeader.personalFavHeader}
                fetchedData={personalFavDetails}
                page={page}
                limit={limit}
                setPage={setPage}
                totalData={totalData}
                setLimit={setLimit}
                setSearch={setSearch}
                init={fetchPersonalContactFav}
                showDOB={true}
                showEditDelete={false}
                setOpenModelForCallHandler={setOpenModelForCallHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <ModelUi
        show={openModelForCall.model}
        handleClose={makeCallModelHandleClose}
        title="Select number to call"
      >
        <table style={{ width: "100%" }}>
          <thead>
            <th style={{ textAlign: "justify" }}>Number</th>
            <th style={{ textAlign: "justify" }}>Call</th>
          </thead>
          <tbody>
            {openModelForCall.array.map((item) => (
              <tr style={{ textAlign: "justify" }}>
                <td style={{ textAlign: "justify" }}>
                  <p>{item.value}</p>
                </td>
                <td style={{ textAlign: "justify", cursor: "pointer" }}>
                  <span
                    className="right_icon"
                    onClick={() => {
                      makeCall(item.value);
                      makeCallModelHandleClose();
                    }}
                  >
                    <i className="material-symbols-outlined">call</i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModelUi>
    </div>
  );
};

export default PersonalContactFavourites;

// import React, { useContext, useEffect, useState } from "react";
// import contactHeader from "../../../json/contactHeader.json";
// import PaginationComponent from "../../pagination/PaginationComponent";
// import appConstant from "../../../json/appConstant.json";
// import useApis from "../../../apis/useApis";
// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import ModelUi from "../../model/ModelUi";
// import { CallCenterContext } from "../../../context/CallCenterContext";
// import {
//   getEmailFromArray,
//   getNumberFromArray,
// } from "../../../apis/commonFunction/commonFunction";

// const PersonalContactFavourites = () => {
//   const { jsonApi } = useApis();
//   const [error, setError] = useState("");
//   const [allContactDetails, setAllContactDetails] = useState([]);
//   const [search, setSearch] = useState("");
//   const { makeCall } = useContext(CallCenterContext);

//   const [page, setPage] = useState(1);
//   const [limit, setLimit] = useState(10);
//   const [totalData, setTotalData] = useState(0);
//   const fav = true;

//   const fetchContactHuman = async () => {
//     try {
//       const contactHuman = await jsonApi(
//         appConstant.getContactPersonal,
//         { page, limit, fav, search: search },
//         null,
//         true
//       );

//       if (!contactHuman.error) {
//         setAllContactDetails(contactHuman.data.data || []); // Update state with actual data
//         setTotalData(contactHuman.data.total);
//       } else {
//         setError(
//           contactHuman.message ? contactHuman.message : "No data received"
//         );
//       }
//     } catch (error) {
//       setError("An unexpected error occurred");
//       console.error("Error:", error);
//     }
//   };

//   useEffect(() => {
//     fetchContactHuman();
//   }, [page, limit, search]);

//   const [openModelForCall, setOpenModelForCall] = useState({
//     model: false,
//     array: [],
//   });
//   const setOpenModelForCallHandler = (array) => {
//     setOpenModelForCall({ model: true, array });
//   };
//   const makeCallModelHandleClose = () => {
//     setOpenModelForCall({ model: false, array: [] });
//   };

//   const navigate = useNavigate();

//   const formattedDate = (timestamp, formatDob) => {
//     if (!timestamp) return "N/A";
//     const dob = moment(timestamp);
//     return dob.format(formatDob);
//   };

//   return (
//     <div className="container-fluid">
//       {/* Start Page Content */}
//       {/* basic table */}
//       <div className="row">
//         <div className="col-xl-11">
//           <div className="card">
//             <div className="card-body custom-field-button">
//               <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
//                 Personal Favorites
//               </h4>
//               <div className="row ">{/*/Col*/}</div>
//               {/*/Row*/}

//               {/*/Row*/}
//               <div className="row">
//                 <div className="col-12">
//                   <div className="table-responsive">
//                     <div
//                       id="default_order_wrapper"
//                       className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
//                     >
//                       <PaginationComponent
//                         setPage={setPage}
//                         totalData={totalData}
//                         limit={limit}
//                         setLimit={setLimit}
//                         page={page}
//                         setSearch={setSearch}
//                       >
//                         <div className="row">
//                           <div className="col-12 mb-2">
//                             <table
//                               id="default_order"
//                               className="table table-hover table-striped display no-wrap w-100"
//                             >
//                               <thead>
//                                 <tr>
//                                   {contactHeader.headers2.map(
//                                     (header, index) => (
//                                       <th
//                                         scope="col"
//                                         style={{ textAlign: "center" }}
//                                         key={index}
//                                       >
//                                         {header}
//                                       </th>
//                                     )
//                                   )}
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {allContactDetails &&
//                                 allContactDetails.length > 0 ? (
//                                   allContactDetails.map((item, index) => {
//                                     return (
//                                       <tr
//                                         style={{ textAlign: "center" }}
//                                         key={index}
//                                       >
//                                         <td>
//                                           {(page - 1) * limit + index + 1}
//                                           <span className="p-1"> </span>
//                                         </td>

//                                         <td>{item.fullName || "No Data"}</td>
//                                         <td>
//                                           {getNumberFromArray(item?.phone) ||
//                                             "No Data"}
//                                         </td>
//                                         <td>
//                                           {getEmailFromArray(item?.email) ||
//                                             "No Data"}
//                                         </td>
//                                         <td>
//                                           {formattedDate(
//                                             item?.dob,
//                                             "DD/MM/YYYY"
//                                           ) || "No Data"}
//                                         </td>

//                                         <td>
//                                           <span
//                                             className="right_icon"
//                                             onClick={() =>
//                                               item?.phone.length === 1
//                                                 ? makeCall(item.phone[0].value)
//                                                 : setOpenModelForCallHandler(
//                                                     item.phone
//                                                   )
//                                             }
//                                           >
//                                             <i className="material-symbols-outlined">
//                                               call
//                                             </i>
//                                           </span>
//                                         </td>
//                                       </tr>
//                                     );
//                                   })
//                                 ) : (
//                                   <tr>
//                                     <td colSpan="5">No data available</td>
//                                   </tr>
//                                 )}
//                               </tbody>
//                             </table>
//                           </div>
//                         </div>
//                       </PaginationComponent>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Col*/}
//               </div>
//               {/*/Row*/}
//             </div>
//           </div>
//         </div>
//       </div>
//       <ModelUi
//         show={openModelForCall.model}
//         handleClose={makeCallModelHandleClose}
//         title="Select number to call"
//       >
//         <table style={{ width: "100%" }}>
//           <thead>
//             <th style={{ textAlign: "justify" }}>Number</th>
//             <th style={{ textAlign: "justify" }}>Call</th>
//           </thead>
//           <tbody>
//             {openModelForCall.array.map((item) => (
//               <tr style={{ textAlign: "justify" }}>
//                 <td style={{ textAlign: "justify" }}>
//                   <p>{item.value}</p>
//                 </td>
//                 <td style={{ textAlign: "justify", cursor: "pointer" }}>
//                   <span
//                     className="right_icon"
//                     onClick={() => {
//                       makeCall(item.value);
//                       makeCallModelHandleClose();
//                     }}
//                   >
//                     <i className="material-symbols-outlined">call</i>
//                   </span>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </ModelUi>
//     </div>
//   );
// };

// export default PersonalContactFavourites;
