const validatePhoneNumberWithCountryCode = (value) => {
    // Regex to validate international phone numbers with mandatory country code.
    const phoneRegex = /^\+([1-9]\d{0,3})\s?\d{4,14}$/;
    return phoneRegex.test(value) || "Please enter a valid phone number";
};

const validateName = (value, fieldName) => {
    const nameRegex = /^[A-Za-z][A-Za-z\s'-]{1,49}$/;
    return nameRegex.test(value) || `Please enter a valid ${fieldName} (letters, spaces, hyphens, and apostrophes only).`;
};

const validateEmail = (value) => {
    // More comprehensive email regex
    if (value) {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
        return emailRegex.test(value) || "Please enter a valid email address.";
    }
};

const validateZipCode = (value) => {
    if (value) {

        const zipCodeRegex = /^[0-9]{5}$/;
        return zipCodeRegex.test(value) || "Zip code must be 5 length.";
    }
    // More comprehensive email regex
};

const validateSuiteNumber = (value) => {
    // Regex pattern to match suite numbers containing only letters, digits, and spaces
    const suiteRegex = /^[a-zA-Z0-9 ]*$/;

    return suiteRegex.test(value) || "Please enter a valid suite number.";
};

const validateSicCode = (value) => {
    const sicRegex = /^\d{4}$/;
    return sicRegex.test(value) || "Please enter a valid SIC code (4 digits).";
};

const validateMaicsCode = (value) => {
    const naicsRegex = /^\d{2,6}$/;
    return naicsRegex.test(value) || "Please enter a valid NAICS code (2 to 6 digits).";
};

const validateGlCode = (value) => {
    const glRegex = /^[a-zA-Z0-9]{1,20}$/;
    return glRegex.test(value) || "Please enter a valid GL code (1 to 20 alphanumeric characters).";
};

const validateNumber = (value) => {
    if (value) {
        const taxIdRegex = /^[A-Z0-9]{2,20}([- ]?[A-Z0-9]{2,10})*$/;
        return taxIdRegex.test(value) || "Please enter a valid Tax ID number.";
    }
};

const validateURL = (value) => {
    if (value) {
        // Regex to validate a URL that starts with https://
        const urlRegex = /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;
        return urlRegex.test(value) || "Please enter valid URL";
    }
};


const validateSocialSecurity = (value) => {
    if (value) {
        const ssnRegex = /^\d{3}-\d{2}-\d{4}$|^\d{9}$ /;
        // const ssnRegex =/^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$/;
        const isNumeric = /^[0-9-]*$/.test(value);
        if (!isNumeric) {
            return "Please enter only numeric characters.";
        }
        return ssnRegex.test(value) || "Please enter a valid Social Security.";
    }
};

const validateMedicareNumber = (value) => {
    if (value) {
        const ssnRegex = /^(\d{3}-\d{2}-\d{4}|\d{9}|\d{3}-\d{3}-\d{3}|\d{2}-\d{2}-\d{2}-\d{2}|\d{10})$/;
        return ssnRegex.test(value) || "Please enter a valid Medicare Number.";
    }
};

const validateDescription = (value) => {
    const descriptionRegex = /^[\s\S]{1,1024}$/;
    return descriptionRegex.test(value) || `Please add a description (up to 1024 characters).`;
};



const validatePercentOwn = (value) => {
    if (value) {
        const numericValue = Number(value);

        // Check if the value is a number
        if (isNaN(numericValue)) {
            return "Percentage Own must be a number";
        }

        // Check if the value is within the valid range
        if (numericValue < 1) {
            return "Percent Own must be at least 1";
        }
        if (numericValue > 100) {
            return "Percent Own must be at most 100";
        }

        // Ensure the value is a whole number
        if (!Number.isInteger(numericValue)) {
            return "Percent Own must be a whole number";
        }

        return true; // Valid value
    }

};
const validateEvent = (value, fieldName) => {
    // Regex to ensure the value starts with a letter and contains only letters and spaces
    const nameRegex = /^[A-Za-z][A-Za-z\s]*$/;
    return nameRegex.test(value) ? true : `Please enter a valid ${fieldName}`;
};

export {
    validatePhoneNumberWithCountryCode, validateName, validateEmail, validateZipCode, validateSuiteNumber
    , validateSicCode, validatePercentOwn, validateMaicsCode, validateGlCode, validateURL, validateNumber, validateSocialSecurity, validateDescription, validateMedicareNumber, validateEvent
}



