import React, { useEffect, useState } from "react";
import contactHeader from "../../../json/contactHeader.json";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import DataTable from "../DataTable";

const BusinessContactData = () => {
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);


  const fetchBusinessContactData = async () => {
    try {
      const businessContact = await jsonApi(
        appConstant.getBusinessContact,
        {
          page,
          limit,
          search: search,
          type: ["Human Resource", "Business Info", "Business Contact"],
        },
        null,
        true
      );

      if (!businessContact.error) {
        setAllContactDetails(businessContact.data.data || []);
        setTotalData(businessContact.data.total);
      } else {
        setError(
          businessContact.message ? businessContact.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchBusinessContactData();
  }, [page, limit, search]);

  return (
    <div className="container-fluid">

      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                Business and Human Resource Data
              </h4>
              <div className="row ">{/*/Col*/}</div>

              <DataTable
                headers={contactHeader.businessContactData}
                fetchedData={allContactDetails}
                page={page}
                limit={limit}
                setPage={setPage}
                totalData={totalData}
                setLimit={setLimit}
                setSearch={setSearch}
                init={fetchBusinessContactData}
              />
              {/*/Row*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessContactData;
