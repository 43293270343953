import React, { useState } from "react";
import ModelUi from "../model/ModelUi";
import HeadingFelid from "./HeadingFelid";
import SubHeadingFelid from "./SubHeadingFelid";
import TitleFelid from "./TitleFelid";

const AddFelidModel = ({
  openAddFelidModal,
  addFelidHandlerCloseModel,
  addFelidHandler,
}) => {
  const [felidType, setFelidType] = useState("");

  const typeChangeHandler = (e) => {};

  return (
    <ModelUi
      show={openAddFelidModal}
      handleClose={addFelidHandlerCloseModel}
      title={"Add felid in form"}
      centered={true}
    >
      <div className="row">
        <div className="col-12 align-self-center">
          <div className="form-group">
            <label htmlFor="employmentLevel">Select Felid Type</label>

            <select
              className="form-control"
              value={felidType}
              onChange={(e) => setFelidType(e.target.value)}
            >
              <option value="">Select Felid Type</option>
              <option value="Heading">Heading</option>
              <option value="SubHeading">Sub Heading</option>
              <option value="Title">Title</option>
              <option value="String">String</option>
              <option value="TextAreaString">Text Area String</option>
              <option value="Select">Select</option>
              <option value="Phone">Phone</option>
              <option value="YesNo">Yes/No</option>
            </select>
          </div>
        </div>
      </div>
      {felidType === "Heading" ? (
        <HeadingFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "SubHeading" ? (
        <SubHeadingFelid addFelidHandler={addFelidHandler} />
      ) : felidType === "Title" ? (
        <TitleFelid addFelidHandler={addFelidHandler} />
      ) : (
        <></>
      )}
    </ModelUi>
  );
};

export default AddFelidModel;
