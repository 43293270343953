import React from "react";

const GenderField = ({
  fieldName,
  fieldShowName,
  register,
  errors,
}) => {

  return (
    <div>
      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">{fieldShowName}</label>
        </div>
        <div className="col-5">
          <select
            className={`form-control borderStyle ${
              errors[fieldName] ? "border border-danger" : ""
            }`}
            id={fieldName}
            {...register(fieldName, {
              required: false,
            })}
          >
            <option value="Male">
              Male
            </option>
            <option value="Female">
              Female
            </option>
            <option value="Other">
              Other
            </option>
           
            
          </select>
          {errors[fieldName] && (
            <p className="text-danger">{errors[fieldName].message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenderField;
