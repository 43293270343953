import React, { useEffect, useState } from "react";
import appConstant from "../../../json/appConstant.json";
import businessDetails from "../../../json/businessDetails.json";
import useApis from "../../../apis/useApis";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronLeft } from "react-feather";

const BusinessContactDetails = () => {
  const { jsonApi } = useApis();
  const { id } = useParams();
  const [businessDetailData, setBusinessDetailData] = useState({});
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const init = async () => {
    const businessContactDetail = await jsonApi(
      appConstant.getSingleUserDetail,
      { id },
      null,
      true
    );
    if (!businessContactDetail.error) {
      setBusinessDetailData(businessContactDetail?.data?.data || {});
    } else {
      setError(businessContactDetail.message || "No data received");
    }
  };

  useEffect(() => {
    init();
  }, [id]);

  const renderField = (fieldKey, label) => {
    const fieldValue = businessDetailData[fieldKey];

    if (fieldValue) {
      return (
        <div className="mb-3 row" key={fieldKey}>
          <label className="col-sm-5 col-form-label text-secondary">
            {label}
          </label>
          <div className="col-sm-7">
            {Array.isArray(fieldValue) ? (
              fieldValue.length > 0 ? (
                fieldValue.map((item) => (
                  <div key={item._id} className="user-info-value">
                    {item.value}
                  </div>
                ))
              ) : (
                <span className="text-muted">No Data</span>
              )
            ) : (
              fieldValue || <span className="text-muted">No Data</span>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 align-self-center">
                  <div className="row">
                    <div className="col-sm">
                  
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e)=>e.target.style.textDecoration='underline'}
                        onMouseLeave={(e)=>e.target.style.textDecoration='none'}

                      //   onClick={
                      //     location.pathname ===
                      //     `/contact/business/details/${id}`
                      //       ? () => navigate(`/contact/business`)
                      //       : () => navigate(`/contact/lead/prospects`)
                      //   }
                      // >
                      onClick={() => {
                        if (location.pathname === `/contact/business/details/${id}`) {
                            navigate(`/contact/business`);
                        } else if (businessDetailData?.leadCheck === 'client') {
                            navigate(`/contact/lead/client`);
                        } else if (businessDetailData?.leadCheck === 'prospects') {
                            navigate(`/contact/lead/prospects`);
                        } 
                    }}
                    >
                        <ChevronLeft />
                        Back to previous page
                      </span>
                      <h2 className="page-title text-truncate text-dark font-weight-medium mb-0 pt-4">
                        {location.pathname === `/contact/business/details/${id}`
                          ? `${businessDetailData.type} Detail`
                          : `${
                              businessDetailData.leadCheck
                                ?.charAt(0)
                                .toUpperCase() +
                              businessDetailData.leadCheck?.slice(1)
                            } Lead Detail`}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h4 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
                        Information
                      </h4>
                      {businessDetails.businessDetailHeader.map(({ key, label }) =>
                        renderField(key, label)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto img-fluid"
            title="Ads"
            alt="ads"
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessContactDetails;

// import React, { useEffect, useState } from "react";
// import appConstant from "../../../json/appConstant.json";
// import useApis from "../../../apis/useApis";
// import { useParams } from "react-router-dom";
// import "../../../json/contactHeader.json"
// const BusinessContactDetails = () => {
//   const { jsonApi } = useApis();
//   const { id } = useParams();
//   const [userDetails, setUserDetails] = useState([]);
//   const [error, setError] = useState(null);

//   // console.log(userData.name);
//   const init = async () => {
//     const details = await jsonApi(
//       appConstant.getSingleUserDetail,
//       { id },
//       null,
//       true
//     );
//     console.log(details);
//     if (!details.error) {
//       setUserDetails(details?.data?.data || []);
//       console.log(details?.data?.data);
//     } else {
//       setError(setError.message ? setError.message : "No data received");
//     }
//   };

//   useEffect(() => {
//     init();
//   }, [id]);

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-xl-11">
//             <div className="card">
//               <div className="card-body">
//                 <div className="row">
//                   <div className="col-12 align-self-center">
//                     <div className="row">
//                       <div className="col-sm">
//                         <h2 className="page-title text-truncate text-dark font-weight-medium mb-0">
//                           Business Contact Detail
//                         </h2>
//                       </div>
//                     </div>
//                     {/* Start Page Content */}

//                     <div className="row">
//                       <div className="col-lg-6">
//                         <h4 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
//                           Information
//                         </h4>

//                         <div className="mb-3 row">
//                           <label
//                             htmlFor="staticPhone"
//                             className="col-sm-5 col-form-label text-secondary"
//                           >
//                             Cell No
//                           </label>
//                           <div className="col-sm-7">
//                             {userDetails.phone &&
//                             userDetails.phone.length > 0 ? (
//                               userDetails.phone.map((phone, index) => (
//                                 <div
//                                   key={phone._id}
//                                   className="user-info-value"
//                                 >
//                                   {phone.value}
//                                 </div>
//                               ))
//                             ) : (
//                               <span className="text-muted">
//                                 No Phone Number
//                               </span>
//                             )}
//                           </div>
//                         </div>

//                         {/* Email */}
//                         <div className="mb-3 row">
//                           <label
//                             htmlFor="staticEmail"
//                             className="col-sm-5 col-form-label text-secondary"
//                           >
//                             Email
//                           </label>
//                           <div className="col-sm-7">
//                             {userDetails.email &&
//                             userDetails.email.length > 0 ? (
//                               userDetails.email.map((email, index) => (
//                                 <div
//                                   key={email._id}
//                                   className="user-info-value"
//                                 >
//                                   {email.value}
//                                 </div>
//                               ))
//                             ) : (
//                               <span className="text-muted">No Email</span>
//                             )}
//                           </div>
//                         </div>

//                         {/* Relationship Type */}
//                         <div className="mb-3 row">
//                           <label
//                             htmlFor="staticRelationshipType"
//                             className="col-sm-5 col-form-label text-secondary"
//                           >
//                             Relationship Type
//                           </label>
//                           <div className="col-sm-7">
//                             {userDetails.relationshipType || (
//                               <span className="text-muted">
//                                 No relationship type
//                               </span>
//                             )}
//                           </div>
//                         </div>

//                         {/* Gender */}
//                         <div className="mb-3 row">
//                           <label
//                             htmlFor="staticGender"
//                             className="col-sm-5 col-form-label text-secondary"
//                           >
//                             Gender
//                           </label>
//                           <div className="col-sm-7">
//                             {userDetails.gender || (
//                               <span className="text-muted">No gender</span>
//                             )}
//                           </div>
//                         </div>

//                         {/* DOB */}
//                         <div className="mb-3 row">
//                           <label
//                             htmlFor="staticDOB"
//                             className="col-sm-5 col-form-label text-secondary"
//                           >
//                             DOB
//                           </label>
//                           <div className="col-sm-7">
//                             {userDetails.dob || (
//                               <span className="text-muted">No DOB</span>
//                             )}
//                           </div>
//                         </div>

//                         {/* Age */}
//                         <div className="mb-3 row">
//                           <label
//                             htmlFor="staticAge"
//                             className="col-sm-5 col-form-label text-secondary"
//                           >
//                             Age
//                           </label>
//                           <div className="col-sm-7">
//                             {userDetails.age || (
//                               <span className="text-muted">No Age</span>
//                             )}
//                           </div>
//                         </div>

//                         <hr />

//                         {/* Street Address */}
//                         <div className="mb-3 row">
//                           <label
//                             htmlFor="staticStreetAddress"
//                             className="col-sm-5 col-form-label text-secondary"
//                           >
//                             Street Address
//                           </label>
//                           <div className="col-sm-7">
//                             {userDetails.primaryResidenceStreetAddress || (
//                               <span className="text-muted">
//                                 No street address
//                               </span>
//                             )}
//                           </div>
//                         </div>

//                         {/* Suite No */}
//                         <div className="mb-3 row">
//                           <label
//                             htmlFor="staticSuiteNo"
//                             className="col-sm-5 col-form-label text-secondary"
//                           >
//                             Suite #
//                           </label>
//                           <div className="col-sm-7">
//                             {userDetails.primaryResidenceSuiteNo || (
//                               <span className="text-muted">
//                                 No suite number
//                               </span>
//                             )}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-xl-1 d-none d-xl-block">
//             <img
//               src="/assets/images/banner/ads.jpg"
//               className="mx-auto img-fluid"
//               title="Ads"
//               alt="ads"
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default BusinessContactDetails;
