import React from "react";
import { useSearchParams } from "react-router-dom";

const HierarchyActionButton = ({
  index,
  setIndex,
  formRef,
  hierarchyTopBarDataState,
  data,
  setSubIndex,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(index);
  return (
    <div className="row">
      <div className="col-12 text-center action_btn my-5">
        <a
          // onClick={() => setIndex((pre) => (pre === 1 ? 1 : pre - 1))}
          href="#"
          name="previous"
          onClick={() => {
            setIndex((pre) => (pre === 1 ? pre : pre - 1));
          }}
          className="prev-step btn_white"
        >
          Save &amp; Back
        </a>
        <a
          // onClick={() => setIndex((pre) => 1)}
          href="#"
          className="exit btn_red mx-2 px-sm-3"
          onClick={() => {
            try {
              formRef.current.handleSubmit("saveAndExit");
              setSearchParams({
                type: "list",
              });
            } catch (error) {
              console.log("first", error);
              setSubIndex(0);
              setSearchParams({
                type: "list",
              });
            }
          }}
        >
          Save &amp; Exit
        </a>
        <a
          // onClick={() => setIndex((pre) => pre + 1)}
          href="#"
          className="next-step btn_green "
          onClick={() => {
            try {
              formRef.current.handleSubmit("saveAndNext");
            } catch (error) {
              console.log("first", error);
              setIndex((pre) =>
                data?.typeOfIndustry === "Insurance"
                  ? pre === 11
                    ? pre
                    : pre + 1
                  : pre === 9
                  ? pre
                  : pre + 1
              );
            }
          }}
        >
          Save &amp; Next
        </a>
        {hierarchyTopBarDataState &&
        index &&
        hierarchyTopBarDataState[index - 1]?.name ===
          "<strong>User Info</strong>" ? (
          <>
            <span className="or">Or</span>
            <a
              href="#!"
              className="btn_white"
              data-toggle="modal"
              data-target="#personalContact"
            >
              Bulk Upload
            </a>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default HierarchyActionButton;
