import React from "react";

const ViewFelidComponent = ({ value, removeHandler }) => {
  return (
    <div className="row my-3">
      <div className="col-10 align-self-center">
        {value.type === "Heading" ? (
          <>
            <div className="row">
              <div className="col-6">
                <h3>Type:{value.type}</h3>
              </div>
              <div className="col-6">
                <h3>Heading Name :{value.heading} </h3>
              </div>
            </div>
          </>
        ) : value.type === "SubHeading" ? (
          <>
            <div className="row">
              <div className="col-6">
                <h3>Type:{value.type}</h3>
              </div>
              <div className="col-6">
                <h3>Heading Name :{value.heading} </h3>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="col-2 align-self-center">
        <button
          type="button"
          onClick={removeHandler}
          className="btn btn-danger"
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default ViewFelidComponent;
