import React, { useEffect, useState } from "react";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { Link } from "react-router-dom";
import DataTable from "../contact/DataTable";
import callHistoryHeader from "../../json/callHistory.json";

const CallHistory = () => {
  const { jsonApi } = useApis();

  const [fetchData, setFetchData] = useState([]);
  const [limit, setLimit] = useState(10); // Default limit
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const fetchCallHistory = async () => {
    const historyData = await jsonApi(
      appConstant.getCallHistory,
      { page, limit, search: search },
      null,
      true
    );
    if (!historyData.error && Array.isArray(historyData.data.data)) {
      setFetchData(historyData.data.data);
      setTotalData(historyData.data.total);
    } else {
    }
  };

  useEffect(() => {
    fetchCallHistory();
  }, [page, limit, search]);

  return (
    <>
      <div className="page-breadcrumb">
        <div className="row">
          <div className="col-12 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
              Call History
            </h3>
          </div>
        </div>
      </div>
      {/* Container fluid  */}
      <div className="container-fluid">
        {/* Start Page Content */}
        {/* basic table */}
        <div className="row">
          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <DataTable
                  headers={callHistoryHeader.callHistoryHeader}
                  fetchedData={fetchData}
                  page={page}
                  limit={limit}
                  setPage={setPage}
                  totalData={totalData}
                  setLimit={setLimit}
                  init={fetchCallHistory}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CallHistory;
