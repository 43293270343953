import React, { useContext, useEffect, useState } from "react";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { Context } from "../../../context/Context";

const SpouseProfileData = () => {
  const { jsonApi } = useApis();
  const { userData } = useContext(Context);
  const [userDetails, setUserDetails] = useState([]);

  const [error, setError] = useState(null);

  console.log(userData.name);
  const init = async () => {
    const details = await jsonApi(
      appConstant.getPersonSpouse,
      { userId: userData._id },
      null,
      true
    );
    console.log(details);
    if (!details.error) {
      setUserDetails(details?.data?.data || []); // Update state with actual data
      console.log(details?.data?.data);
    } else {
      setError(setError.message ? setError.message : "No data received");
    }
  };

  useEffect(() => {
    init();
  }, [userData._id]);

  const contactDetail = userDetails?.contactDetail?.[0] || {};
  const primaryResidence = userDetails?.primaryResidence?.[0] || {};
  const spouse = userDetails?.spouse?.contactDetail?.[0] || {};
  const workInfo = userDetails?.workInfo?.[0] || {};
  const healthInsurance = userDetails?.healthInsurance || {};
  const dependents = userDetails?.dependents || {};

  return (
    <>
      <div className="container-fluid">
        {/* Start Page Content */}
        {/* basic table */}
        <div className="row">
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 align-self-center">
                    <div className="row">
                      <div>
                        <div className="col-sm d-inline-block mb-4">
                          <img
                            src="/assets/images/users/2.jpg"
                            alt="user"
                            className="rounded-circle"
                            width={80}
                          />
                        </div>
                      </div>
                      <div className="col-sm">
                        <h2 className="page-title text-truncate text-dark font-weight-medium mb-0">
                          {userData.name || "No name"}
                        </h2>
                        <div className="pt-0 mt-0">
                          <button
                            type="button"
                            className="btn waves-effect waves-light btn-rounded btn-sm btn-primary"
                            data-toggle="modal"
                            data-target="#editprofilepicModal"
                          >
                            Edit
                          </button>
                        </div>
                        {/* Modal */}
                        <div
                          className="modal fade"
                          id="editprofilepicModal"
                          tabIndex={-1}
                          role="dialog"
                          aria-labelledby="editprofilepicModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog modal-dialog-centered modal-lg"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4
                                  className="modal-title text-dark font-weight-medium"
                                  id="exampleModalLabel"
                                >
                                  Edit Spouse Profile
                                </h4>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="form-group row">
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 col-form-label"
                                  >
                                    Last Name
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="text"
                                      maxLength={256}
                                      className="form-control"
                                      id="lastname"
                                      defaultValue="Grover"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 col-form-label"
                                  >
                                    First Name
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="text"
                                      maxLength={256}
                                      className="form-control"
                                      id="lastname"
                                      defaultValue="Hanna"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 col-form-label"
                                  >
                                    Profile Photo
                                  </label>
                                  <div className="col-sm-9">
                                    <div className="input-group">
                                      <div className="custom-file">
                                        <input
                                          type="file"
                                          className="custom-file-input"
                                          id="inputGroupFile04"
                                        />
                                        <label
                                          className="custom-file-label text-truncate"
                                          htmlFor="inputGroupFile04"
                                        />
                                      </div>
                                      <div className="input-group-append">
                                        <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                        >
                                          Upload
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Start Page Content */}
                    <div className="row">
                      <div className="col-lg-4">
                        <h4 className="page-title text-truncate text-dark font-weight-medium my-4">
                          Personal Information
                        </h4>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Cell No
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.cellNo || "No cell number"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Email
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.personalEmail || "No Email"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Relationship Type
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.relationshipType ||
                              "No relationship type"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Gender
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.gender || "No gender"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            DOB
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.dob || "No DOB"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Age
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.age || "No Age"}
                          </div>
                        </div>
                        <hr />
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Street Address
                          </label>
                          <div className="col-sm-7">
                            {primaryResidence.streetAddress ||
                              "No street address"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Suite #
                          </label>
                          <div className="col-sm-7">
                            {primaryResidence.suiteNo || "No suite number"}
                          </div>
                        </div>
                        <hr />
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Social Security #
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.socialSecurity || "NA"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Citizenship Status
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.citizenshipStatus ||
                              "No citizenship status"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Drivers License #
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.driversLicense ||
                              "No drivers license"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Drivers License State
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.driversLicenseState ||
                              "No drivers license state"}
                          </div>
                        </div>
                        <hr />
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Wedding Anniv.
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.weddingAnniv ||
                              "No wedding anniversary"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Year of Marriage
                          </label>
                          <div className="col-sm-7">
                            {contactDetail.yearOfMarriage ||
                              "No year of marriage"}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <h4 className="page-title text-truncate text-dark font-weight-medium my-4">
                          Business Information
                        </h4>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Position
                          </label>
                          <div className="col-sm-7">
                            {workInfo.position || "No position"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Company Name
                          </label>
                          <div className="col-sm-7">
                            {workInfo.companyName || "No company name"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Direct #
                          </label>
                          <div className="col-sm-7">
                            {workInfo.directNumber || "No direct number"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Corporate #
                          </label>
                          <div className="col-sm-7">
                            {workInfo.corporateNumber || "No corporate number"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Extension #
                          </label>
                          <div className="col-sm-7">
                            {workInfo.extensionNumber || "No extension number"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Street Address
                          </label>
                          <div className="col-sm-7">
                            {workInfo.streetAddress || "No street address"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Suite #
                          </label>

                          {workInfo.suiteNo || "No suite number"}
                        </div>

                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Dept{" "}
                          </label>

                          {workInfo.dept || "No department"}
                        </div>

                        <h4 className="page-title text-truncate text-dark font-weight-medium my-4">
                          Health Insurance
                        </h4>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Type of Insurance
                          </label>
                          <div className="col-sm-7">
                            {healthInsurance.typeOfInsurance ||
                              "No insurance type"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Medicare #
                          </label>
                          <div className="col-sm-7">
                            {healthInsurance.medicareNumber ||
                              "No medicare number"}
                          </div>
                        </div>
                        <h5 className="pt-2">Medicare Enrollment Dates</h5>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Part A
                          </label>
                          <div className="col-sm-7">
                            {healthInsurance.partA || "No Part A date"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Part B
                          </label>
                          <div className="col-sm-7">
                            {healthInsurance.partB || "No Part B date"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Part D
                          </label>
                          <div className="col-sm-7">
                            {healthInsurance.partD || "No Part D date"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Medicare Plan Type
                          </label>
                          <div className="col-sm-7">
                            {healthInsurance.medicarePlanType ||
                              "No medicare plan type"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Medicare Company
                          </label>
                          <div className="col-sm-7">
                            {healthInsurance.medicareCompany ||
                              "No medicare company"}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <h4 className="page-title text-truncate text-dark font-weight-medium my-4">
                          Spouse Name: {spouse?.name || "No name"}
                        </h4>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            Age
                          </label>
                          <div className="col-sm-7">
                            {spouse?.age || "No age"}
                          </div>
                        </div>
                        <div className="form-group mb-0 row">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label"
                          >
                            DOB
                          </label>
                          <div className="col-sm-7">
                            {spouse?.dob || "No DOB"}
                          </div>
                        </div>
                        <h4 className="page-title text-truncate text-dark font-weight-medium my-4">
                          Dependents
                        </h4>
                        {dependents.length > 0 ? (
                          dependents.map((dependent, index) => (
                            <div key={index}>
                              <h5 className="page-title text-truncate text-dark font-weight-medium my-4">
                                Child #{index + 1}:{" "}
                                {dependent?.name || "No name"}
                              </h5>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-5 col-form-label"
                                >
                                  Age
                                </label>
                                <div className="col-sm-7">
                                  {dependent?.age || "No age"}
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-5 col-form-label"
                                >
                                  DOB
                                </label>
                                <div className="col-sm-7">
                                  {dependent?.dob || "No DOB"}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No dependents</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
              alt="ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SpouseProfileData;
