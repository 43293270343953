import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const TotalCalls = ({ fetchData1 }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (fetchData1 && fetchData1.length > 0) {
        // Initialize an array for each month
      const monthCounts = Array(12).fill(0); 

      fetchData1.forEach(call => {
        // Get month index (0-11)
        const month = new Date(call.createdAt).getMonth(); 
        // Increment the count for the corresponding month
        monthCounts[month] += 1; 
      });

      console.log("Month Counts:", monthCounts); 
      setChartData(monthCounts);
    }
  }, [fetchData1]);

  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Calls Received per Month'
    },
    xAxis: {
      categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      title: {
        text: 'Month'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Calls'
      }
    },
    series: [{
      name: 'Total Received Calls',
      data: chartData
    }]
  };

  return (
    <div>
      {chartData.length > 0 ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      ) : (
        <div> <HighchartsReact
        highcharts={Highcharts}
        options={options}
      /></div>
      )}
    </div>
  );
};

export default TotalCalls;


