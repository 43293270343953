import React, { useEffect, useState } from "react";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";
import { Link } from "react-router-dom";
import PaginationComponent from "../pagination/PaginationComponent";

const SubscriptionAdmin = () => {
  const { jsonApi } = useApis();

  const [fetchData, setFetchData] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");

  const init = async () => {
    const res = await jsonApi(
      appConstant.getAllCompany,
      {
        page,
        limit,
        search: search,
      },
      null,
      false
    );
    if (!res.error) {
      console.log(res.data);
      setFetchData(res.data.data);
      setTotalData(res.data.total);
    }
  };
  useEffect(() => {
    init();
  }, [page, limit, search]);

  return (
    <>
      <div className="page-breadcrumb">
        <div className="row">
          <div className="col-12 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
              Subscription Data
            </h3>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                      <PaginationComponent
                        totalData={totalData}
                        page={page}
                        limit={limit}
                        setPage={setPage}
                        setLimit={setLimit}
                        setSearch={setSearch}
                      >
                        <div className="row">
                          {fetchData && fetchData.length > 0 ? (
                            fetchData.map((item, index) => (
                              <div className="col-xl-12" key={index}>
                                <div className="card mb-3">
                                  {/* <div className="card-body"> */}
                                  <div className="card-body d-flex justify-content-between align-items-center">
                                    {" "}
                                    {/* Flexbox for alignment */}
                                    <div>
                                      <h5 className="card-title">
                                        {item.companyName || "No Data"}
                                      </h5>
                                      <p className="card-text">
                                        <strong>Email:</strong>{" "}
                                        {item.email || "No Data"}
                                        <strong className="px-2">
                                          Cell Number:
                                        </strong>{" "}
                                        {item.cellNumber || "No Data"}
                                      </p>
                                    </div>
                                    <div className="nav-item dropdown">
                                      <Link
                                        to={`/company/details/${item._id}`}
                                        className="nav-link dropdown-toggle"
                                        //   onClick={toggleDropdown}
                                        // onClick={() => navigate(`/details/${item._id}`)} // Use the item's ID for navigation
                                      >
                                        {" "}
                                        Full Detail
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="col-12">
                              <div className="alert alert-warning" role="alert">
                                No data available
                              </div>
                            </div>
                          )}
                        </div>
                      </PaginationComponent>
                    </div>
                  </div>
                </div>
              </div>
              {/*/Col*/}
            </div>
            {/*/Row*/}
          </div>
          {/*/Card Body*/}
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              alt=""
              title="Ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionAdmin;
