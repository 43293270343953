import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import { validateName } from "../validation/Validation";
import DragsAndDrop from "../../image/DragsAndDrop";

const EditCoreModule = ({ show, handleClose, currentCoreData, init }) => {
  const { mediaApi, jsonApi } = useApis();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register,
    setValue,
  
  } = useForm({
    defaultValues: {
      name: currentCoreData?.name || "",
      logo: [],
    },
  });
  const [editThumbnailUrl, setEditThumbnailUrl] = useState();
  const [error, setError] = useState("");

  const [editThumbnail, setEditThumbnail] = useState();

  useEffect(() => {
    if (currentCoreData) {
      reset({
        name: currentCoreData?.name || "",
        logo: [],
      });
      setEditThumbnailUrl(currentCoreData.logo);
    }
  }, [currentCoreData, reset]);

  const onSubmit = async (data) => {
 
    if (data?.logo?.name) {
      const formData = new FormData();
      formData.append("logo", data.logo);
      const response = await mediaApi(
        appConstant.editCoreModuleImage,
        { id: currentCoreData._id },
        formData,
        null
      ); // Pass token to API call
      if (!response.error) {
        setError("");
        reset();
        setEditThumbnailUrl(null);
        handleClose();
      } else {
        setError(error.message);
      }
    }
    const response = await jsonApi(
      appConstant.editCoreModule,
      { id: currentCoreData._id },
      { name: data?.name },
      null
    ); // Pass token to API call
    if (!response.error) {
      setError("");
      reset();
      init();
      handleClose();
    } else {
      setError(error.message);
    }

   
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          setEditThumbnailUrl("");
          setEditThumbnail();
        }}
        className="modal fade"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-dark font-weight-medium">
                Edit Core Module
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleClose();
                  setEditThumbnailUrl("");
                  setEditThumbnail();
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mb-3">
                  <label>
                    Name <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        placeholder="Name"
                        maxLength={256}
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        {...register("name", {
                          required: "Name is required",
                          validate: (value) => validateName(value, "name"),
                        })}
                      />
                    )}
                  />
                  {errors.name && (
                    <p className="text-danger">{errors.name.message}</p>
                  )}
                </div>
                <div className="col-12 mb-3">
                  <label>
                    Logo <span className="text-danger"></span>
                  </label>
                  <div className="d-flex justify-content-center w-100">
                    <DragsAndDrop
                      imgKey={currentCoreData?.logo}
                      heading="Upload Image"
                      inputName="Image"
                      aspect={1 / 1}
                      uploadFile={(x) => {
                        setValue("logo", x);
                      }}
                    />
                  </div>
                  
                  {errors.logo && (
                    <p className="text-danger">{errors.logo.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditCoreModule;
