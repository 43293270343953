import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";

const HierarchyProductsOffered = forwardRef((props, ref) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      productsOffered: {
        Annuities: true,
        HospitalIndemnity: true,
        Auto: true,
        LifeGroup: true,
        bop: true,
        LifeIndividual: true,
        Canter: true,
        LongTermCare: true,
        Commerical: true,
        ma: true,
        DisabilityIndividual: true,
        MAPD: true,
        DisabilityGroup: true,
        MedciareSupplement: true,
        HealthShortTerm: true,
        PDP: true,
        HealthACA: true,
        Umbrella: true,
        HealthGroup: true,
        WorkersComp: true,
        Homeowners: true,
      },
    },
  });

  // Expose the handleSubmit method to the parent component
  useImperativeHandle(ref, () => ({
    handleSubmit: (param) =>
      handleSubmit((formData) => {
        console.log(formData);
        const productsOffered = Object.keys(formData.productsOffered)
          .filter((key) => formData.productsOffered[key])
          .map((key) => key);
        props.submitForm({ productsOffered }, param);
      })(),
  }));

  useEffect(() => {
    if (props.data) {
      const updatedValues = products.reduce(
        (acc, product) => {
          acc.productsOffered[product.id] = props.data.productsOffered.some(
            (item) => item.name === product.id
          );
          return acc;
        },
        { productsOffered: {} }
      );

      reset(updatedValues); // Reset the form with new values
    }
  }, [props.data, reset]);

  // Define the list of products
  const products = [
    { id: "Annuities", label: "Annuities" },
    { id: "HospitalIndemnity", label: "Hospital Indemnity" },
    { id: "Auto", label: "Auto" },
    { id: "LifeGroup", label: "Life-Group" },
    { id: "bop", label: "BOP (Business Owners Policy)" },
    { id: "LifeIndividual", label: "Life-Individual" },
    { id: "Canter", label: "Canter" },
    { id: "LongTermCare", label: "Long Term Care" },
    { id: "Commerical", label: "Commercial" },
    { id: "ma", label: "MA (Medicare Advantage)" },
    { id: "DisabilityIndividual", label: "Disability Individual" },
    { id: "MAPD", label: "MAPD" },
    { id: "DisabilityGroup", label: "Disability Group" },
    { id: "MedciareSupplement", label: "Medicare Supplement" },
    { id: "HealthShortTerm", label: "Health-Short Term" },
    { id: "PDP", label: "PDP" },
    { id: "HealthACA", label: "Health-ACA" },
    { id: "Umbrella", label: "Umbrella" },
    { id: "HealthGroup", label: "Health-Group" },
    { id: "WorkersComp", label: "Workers Comp" },
    { id: "Homeowners", label: "Homeowners" },
  ];

  return (
    <div className="my-5 w-100">
      <div className="row">
        <label className="col-md-3 mb-4 font-weight-bold">
          Products Offered
        </label>
        <div className="col-md-9 mb-3">
          <div className="row">
            {products.map((product) => (
              <div className="col-sm-6 mb-3" key={product.id}>
                <div className="custom-checkbox form-check-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={product.id}
                    {...register(`productsOffered.${product.id}`)}
                  />
                  <label className="custom-control-label" htmlFor={product.id}>
                    {product.label}
                  </label>
                </div>
              </div>
            ))}
          </div>
          {errors.productsOffered && (
            <p className="error">Please select at least one product.</p>
          )}
        </div>
      </div>
    </div>
  );
});

export default HierarchyProductsOffered;
