import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import { Link } from "react-router-dom";

const APIKey = () => {
  const { control } = useForm();
  const [apiKey, setApiKey] = useState("");
  const [copied, setCopied] = useState(false);

  const { jsonApi } = useApis();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const res = await jsonApi(appConstant.getApiKey, null, null);
    if (res && res.data) {
      setApiKey(res.data.data);
    }
  };

  const handleGenerate = async () => {
    try {
      const res = await jsonApi(appConstant.createApiKey);
      // Adjust the condition to check for success status
      if (!res.error) {
        await init();
      } else {
        console.error("Failed to generate API key.");
      }
    } catch (error) {
      console.error("Error generating API key:", error);
      if (error.response) {
        console.error("Error Response:", error.response.data);
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    });
  };

  return (
    <>
      <div className="page-breadcrumb">
        <div className="row">
          <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
            Your API Key
          </h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body text-center">
                <div className="row mb-4">
                  <label className="col-lg-2 col-md-3 col-sm-4">
                    Your API Key
                  </label>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                    <Controller
                      name="apiKey"
                      control={control}
                      render={() => (
                        <input
                          type="text"
                          value={apiKey}
                          readOnly
                          className="form-control"
                        />
                      )}
                    />
                  </div>
                </div>
                <button
                  onClick={handleCopy}
                  className="btn btn-sm btn-primary btn-rounded px-4"
                >
                  Copy
                </button>
                <button
                  onClick={handleGenerate}
                  className="btn btn-sm btn-success btn-rounded px-4 ml-2"
                >
                  Generate
                </button>
                {copied && (
                  <h5 className="text-success pt-4 font-weight-bold">
                    Copied to clipboard!
                  </h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer text-center text-muted">
        All Rights Reserved by Digital Company. Designed and Developed by{" "}
        <Link href="#">Digital Company</Link>.
      </footer>
    </>
  );
};

export default APIKey;
