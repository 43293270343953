import React from "react";

const QuoteField = ({ errors, fieldName, fieldShowName, register }) => {
  return (
    <div className="row my-2">
      <div className="col-12 pt-2">
        <label htmlFor={fieldName} className="">
          {fieldShowName}
        </label>
        <div className="d-flex">
          <span className="m-2">Answer</span>
          <input
            style={{ display: "inline" }}
            type="text"
            maxLength={256}
            // required
            className={`form-control borderStyle ${
              errors[fieldName] ? "border border-danger" : ""
            }`}
          />
        </div>
      </div>
      {/* <div className="col-8">
        <input
          type="text"
          maxLength={256}
          // required
          className={`form-control borderStyle ${
            errors[fieldName] ? "border border-danger" : ""
          }`}
          id={fieldName}
          {...register(fieldName, {
            required: false,
          })}
        />
        {errors[fieldName] && (
          <p className="text-danger">{errors[fieldName].message}</p>
        )}
      </div> */}
    </div>
  );
};

export default QuoteField;
