import React from "react";
import { Link, useLocation } from "react-router-dom";

const ContactPersonalSubHeader = () => {
  const location = useLocation();
  // console.log(location.pathname === "/contact/personal");
  return (
    <div
      className="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <Link
        to="/contact/personal/add"
        className="btn waves-effect waves-light btn-rounded btn-sm btn-outline-primary mb-2"
      >
        + Add Personal Contact
      </Link>

      {/*Add Personal Contact Modal End*/}
      <div className="row">
        <ul className="nav">
        <li className="nav-item">
            <Link
              // to="personal/lead/client"
              to="lead/client"
              className={`nav-link ${
                location.pathname === "/contact/lead/client" ? "active" : ""
              } `}
            >
              Client
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="lead/prospects"
              className={`nav-link ${
                location.pathname === "/contact/lead/prospects" ? "active" : ""
              } `}
            >
              Prospectes
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="personal"
              className={`nav-link ${
                location.pathname === "/contact/personal" ? "active" : ""
              } `}
            >
              All
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="personal/favorites"
              className={`nav-link ${
                location.pathname === "/contact/personal/favorites"
                  ? "active"
                  : ""
              } `}
            >
              Favorites
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Wholesalers</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Vendors</Link>
          </li>
          <li>
            <span className="separator">|</span>
          </li>
          <li className="nav-item">
            <Link className="nav-link ">Email</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Phone Call</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Contact Summary</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link ">Notes</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Calendar</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Files</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Text</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Chat</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">US Postal Mail</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Video / Schedule Event</Link>
          </li>
          <li className="nav-item">
            {/* <Link className="nav-link active" to="personal/contact/data/:id"> */}
            {/* <Link className="nav-link">Contact Profile</Link> */}
            <Link to="personal/details"
            className={`nav-link ${
                location.pathname === "/contact/personal/details"
                  ? "active"
                  : ""
              } `}> Contact Profile
            </Link> 
          </li>
          <li className="nav-item">
            {/* <Link to="spouse/profile/data/:id" className="nav-link active"> */}
            {/* <Link className="nav-link">Spouse Profile</Link> */}
            <Link to="personal/spouse/details"
            className={`nav-link ${
                location.pathname === "/contact/personal/spouse/details"
                  ? "active"
                  : ""
              } `}> Spouse Profile
            </Link> 
          </li>
          <li className="nav-item">
            <Link to="dependent-profile" className="nav-link">
              Dependent Profile
            </Link>
          </li>
          <li>
            <span className="separator">|</span>
          </li>
          <li className="nav-item">
            <Link className="nav-link ">Sale Summary</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link">Sale Opportunity</Link>
          </li>
          <li>
            <span className="separator">|</span>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Quote <i className="fa fa-angle-down" aria-hidden="true" />
            </Link>
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <Link className="dropdown-item">Medicare</Link>
              <Link className="dropdown-item">Life Insurance</Link>
              <Link className="dropdown-item">Auto Insurance</Link>
              <Link className="dropdown-item">Homeowners Insurance</Link>
              <Link className="dropdown-item">Solar Energy</Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactPersonalSubHeader;
