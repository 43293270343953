import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import "./DatePickerStyle.css"

const MedicareBeneficiaryEnrollmentDateField = ({ control, register, errors, item }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "medicationsBeneficiaryEnrollmentDate",
  });

  return (
    <div>
      <h6 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
        {item.fieldShowName}
      </h6>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Part</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Option</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((medicationEnrollment, index) => (
            <tr key={medicationEnrollment.id}>
              <td>
                <Controller
                  control={control}
                  name={`medicationsBeneficiaryEnrollmentDate[${index}].part`}
                  defaultValue={medicationEnrollment.part || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td className="w-30">
                <Controller
                  control={control}
                  name={`medicationsBeneficiaryEnrollmentDate[${index}].startDate`}
                  defaultValue={medicationEnrollment.startDate || ""}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      className="form-control custom-date-picker"
                      calendarPosition="bottom-center"
                      selected={field.value ? new Date(field.value) : null}
                    />
                  )}
                />
              </td>
              <td className="w-30">
                <Controller
                  control={control}
                  name={`medicationsBeneficiaryEnrollmentDate[${index}].endDate`}
                  defaultValue={medicationEnrollment.endDate || ""}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      className="form-control custom-date-picker"
                      calendarPosition="bottom-center"
                      selected={field.value ? new Date(field.value) : null}                   
                       />
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name={`medicationsBeneficiaryEnrollmentDate[${index}].option`}
                  defaultValue={medicationEnrollment.option || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  ×
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary ml-auto"
          onClick={() =>
            append({ part: "", startDate: "", endDate: "", option: "" })
          }
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default MedicareBeneficiaryEnrollmentDateField;

// import React from "react";
// import { Controller, useFieldArray } from "react-hook-form";
// import { DatePicker } from "rsuite"; 

// const MedicareBeneficiaryEnrollmentDateField = ({ control, register, errors,item }) => {
//   const { fields, append, remove } = useFieldArray({
//     control,
//     name: "medicationsBeneficiaryEnrollmentDate",
//   });

//   return (
//     <div>
//       <h6 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
//        {item.fieldShowName}
//       </h6>

//       <table className="table table-bordered">
//         <thead>
//           <tr>
//             <th>Part</th>
//             <th>Start Date</th>
//             <th>End Date</th>
//             <th>Option</th>
//           </tr>
//         </thead>
//         <tbody>
//           {/* Render dynamic fields using the fields from useFieldArray */}
//           {fields.map((medicationEnrollment, index) => (
//             <tr key={medicationEnrollment.id}>
//               <td >
//                 <Controller
//                   control={control}
//                   name={`medicationsBeneficiaryEnrollmentDate[${index}].part`}
//                   defaultValue={medicationEnrollment.part || ""}
//                   render={({ field }) => (
//                     <input type="text" className="form-control" {...field} />
//                   )}
//                 />
//                 {/* {errors.medicationsBeneficiaryEnrollmentDate?.[index]?.part && (
//                   <span className="text-danger">
//                     part is required
//                   </span>
//                 )} */}
//               </td>
//               <td style={{ width: "35%" }}>
//                 <Controller
//                   control={control}
//                   name={`medicationsBeneficiaryEnrollmentDate[${index}].startDate`}
//                   defaultValue={medicationEnrollment.startDate || ""}
//                   render={({ field }) => (
//                     // <input type="text" className="form-control" {...field} />
//                     <DatePicker className="form-control" format="dd/MM/yyyy" {...field} />
//                   )}
//                 />
               
//               </td>

//               <td style={{ width: "35%" }}>
//                 <Controller
//                   control={control}
//                   name={`medicationsBeneficiaryEnrollmentDate[${index}].endDate`}
//                   defaultValue={medicationEnrollment.endDate || ""}
//                   render={({ field }) => (
//                     // <input type="text" className="form-control" {...field} />
//                     <DatePicker className="form-control" format="dd/MM/yyyy" {...field} />
//                   )}
//                 />
               
//               </td>


//               <td>
//                 <Controller
//                   control={control}
//                   name={`medicationsBeneficiaryEnrollmentDate[${index}].option`}
//                   defaultValue={medicationEnrollment.option || ""}
//                   render={({ field }) => (
//                     <input type="text" className="form-control" {...field} />
//                   )}
//                 />
              
//               </td>
//               <td>
//                 <button
//                   type="button"
//                   className="btn btn-danger"
//                   onClick={() => remove(index)}
//                 >
//                   ×
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <div className="d-flex">
//         <button
//           type="button"
//           className="btn btn-primary ml-auto"
//           onClick={() =>
//             append({ part: "", startDate: "", endDate: "", option: "" })
//           }
//         >
//           Add
//         </button>
//       </div>
//     </div>
//   );
// };

// export default MedicareBeneficiaryEnrollmentDateField;

// {medications.map((medication, index) => (
//     <div className="row my-2" key={index}>
//       <div className="col-3">
//         <label htmlFor={`nameOfMed-${index}`}>Name of MED</label>
//         <input
//           type="text"
//           id={`name-${index}`}
//           className={`form-control borderStyle ${
//             errors?.[`name-${index}`] ? "border border-danger" : ""
//           }`}
//           value={medication.name}
//           onChange={(e) =>
//             handleChange(index, "name", e.target.value)
//           }
//           {...register(`medications[${index}].name`, { required: false })}
//         />
//         {errors?.[`name-${index}`] && (
//           <p className="text-danger">
//             {errors?.[`name-${index}`]?.message}
//           </p>
//         )}
//       </div>

//       <div className="col-2">
//         <label htmlFor={`quantity-${index}`}>Quantity</label>
//         <input
//           type="text"
//           id={`quantity-${index}`}
//           className={`form-control borderStyle ${
//             errors?.[`quantity-${index}`] ? "border border-danger" : ""
//           }`}
//           value={medication.quantity}
//           onChange={(e) =>
//             handleChange(index, "quantity", e.target.value)
//           }
//           {...register(`medications[${index}].quantity`, { required: false })}
//         />
//         {errors?.[`quantity-${index}`] && (
//           <p className="text-danger">
//             {errors?.[`quantity-${index}`]?.message}
//           </p>
//         )}
//       </div>

//       <div className="col-2">
//         <label htmlFor={`dosage-${index}`}>Dosage</label>
//         <input
//           type="text"
//           id={`dosage-${index}`}
//           className={`form-control borderStyle ${
//             errors?.[`dosage-${index}`] ? "border border-danger" : ""
//           }`}
//           value={medication.dosage}
//           onChange={(e) =>
//             handleChange(index, "dosage", e.target.value)
//           }
//           {...register(`medications[${index}].dosage`, { required: false })}
//         />
//         {errors?.[`dosage-${index}`] && (
//           <p className="text-danger">
//             {errors?.[`dosage-${index}`]?.message}
//           </p>
//         )}
//       </div>

//       <div className="col-3">
//         <label htmlFor={`reason-${index}`}>For What Reason</label>
//         <input
//           type="text"
//           id={`reason-${index}`}
//           className={`form-control borderStyle ${
//             errors?.[`reason-${index}`] ? "border border-danger" : ""
//           }`}
//           value={medication.reason}
//           onChange={(e) =>
//             handleChange(index, "reason", e.target.value)
//           }
//           {...register(`medications[${index}].reason`, { required: false })}
//         />
//         {errors?.[`reason-${index}`] && (
//           <p className="text-danger">
//             {errors?.[`reason-${index}`]?.message}
//           </p>
//         )}
//       </div>
//     </div>
//   ))}
