import React from "react";

const LeadField = ({ errors, fieldName, fieldShowName, register }) => {
  return (
    <div className="row my-2">
      <div className="col-7 pt-2">
        <label htmlFor={fieldName} className="">
          {fieldShowName}
        </label>
      </div>
      <div className="col-5">
        <input
          type="text"
          maxLength={256}
          // required
          className={`form-control borderStyle ${
            errors[fieldName] ? "border border-danger" : ""
          }`}
          id={fieldName}
          {...register(fieldName, {
            required: false,
          })}
        />
        {errors[fieldName] && (
          <p className="text-danger">{errors[fieldName].message}</p>
        )}
      </div>
    </div>
  );
};

export default LeadField;
