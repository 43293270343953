import React, { useEffect, useRef, useState } from "react";
import HierarchyTopBar from "./HierarchyTopBar";
import HierarchyActionButton from "./HierarchyActionButton";
import HierarchyIndex from "./HierarchyIndex";
import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import { useSearchParams } from "react-router-dom";
import hierarchyTopBarData from "./hierarchyTopBarData.json";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import companyTem from "../../Template.xlsx";

const AddHierarchy = ({ title, setSubIndex }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [index, setIndex] = useState(1);
  const [completedIndex, setCompletedIndex] = useState(1);
  const [data, setData] = useState({ completed: false, index: 1 });
  const [buttonType, setButtonType] = useState("");
  const { jsonApi, mediaApi } = useApis();
  const [initError, setInitError] = useState("");
  const [excelError, setExcelError] = useState("");
  const [UserInfo, setUserInfo] = useState("");
  const [hierarchyTopBarDataState, setHierarchyTopBarDataState] = useState([]);
  const expectedTitles = ["name", "email", "phone", "gender", "type"];
  const [selectedFileName, setSelectedFileName] = useState("");

  const convertCSVtoObjectHandler = (e) => {
    const files = e.target.files;
    const data = [];
    setExcelError("");

    if (files) {
      const file = files[0];
      setSelectedFileName(file.name);
      const fileType = file.type;

      if (fileType === "text/csv") {
        Papa.parse(file, {
          complete: function (results) {
            processData(results.data);
            setSelectedFileName("");
          },
        });
      } else if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel"
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          processData(jsonData);
        };
        reader.readAsBinaryString(file);
      } else {
        // openErrorSnackbar("Unsupported file type");
      }
    }
    e.target.value = null;
  };

  const processData = (data) => {
    const result = [];
    for (let index = 1; index < data.length; index++) {
      const element = data[index];
      const title = data[0];

      if (element.length === expectedTitles.length) {
        const gender = element[3];
        console.log(element[3]);
        if (gender === "Female" || gender === "Male" || gender === "Other") {
          if (!element.includes("")) {
            result.push({
              [title[0]]: element[0],
              [title[1]]: element[1],
              [title[2]]: element[2],
              [title[3]]: element[3],
              [title[4]]: element[4],
            });
          } else {
            setExcelError("Data is missing in file");
            break;
          }
        } else {
          setExcelError("Data is missing in file");

          break;
        }
      } else {
        break;
      }
    }
    // setEmployeeDataArray(result);
    setUserInfo(result);
    console.log(result);
    // setOpenEmployeeListModal(true);
  };

  const formRef = useRef();

  const init = async () => {
    if (!searchParams.get("id")) {
      const res = await jsonApi(appConstant.createHierarchy, null, data, false);
      if (!res.error) {
        setInitError("");
        setSearchParams({
          id: res.data.data._id,
          type: searchParams.get("type") ? searchParams.get("type") : "list",
        });
        setHierarchyTopBarDataState(hierarchyTopBarData.hasInsurance);
      } else {
        setInitError(res.message);
      }
    } else {
      const res = await jsonApi(
        appConstant.getHierarchy,
        { id: searchParams.get("id") },
        {},
        false
      );

      if (!res.error) {
        setInitError("");
        setData(res.data.data);
        setIndex(res.data.data.index);
        if (res.data.data.typeOfIndustry === "Insurance") {
          setHierarchyTopBarDataState(hierarchyTopBarData.hasInsurance);
        } else if (res.data.data.typeOfIndustry !== "Insurance") {
          setHierarchyTopBarDataState(hierarchyTopBarData.noInsurance);
        }
      } else {
        setInitError(res.message);
      }
    }
  };

  const createUsersInCompany = async () => {
    if (UserInfo) {
      const res = await jsonApi(
        appConstant.addMultipleUserInCompany,
        { id: searchParams.get("id") },
        UserInfo,
        false
      );
      // console.log(JSON.stringify(productsOffered));
      const currentIndex = index;
      await init();
      setIndex(currentIndex);
      setUserInfo("");
      setSelectedFileName("");
    }
  };

  const submitForm = async (formData, value) => {
    const res = await jsonApi(
      appConstant.updateHierarchy,
      { id: searchParams.get("id") },
      { ...formData, index },
      false
    );
    // console.log(JSON.stringify(productsOffered));

    await init();
    if (!res.error) {
      setCompletedIndex(res.data.data.completedIndex);
      setIndex(res.data.data.index);
      if (value === "saveAndNext") {
        setIndex(res.data.data.index);
      } else if (value === "saveAndPre") {
        if (!(index === 1)) {
          setIndex(res.data.data.index - 1);
        }
      } else if (value === "saveAndExit") {
        setSubIndex(0);
        // setSearchParams({
        //   type: "list",
        // });
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="tab-pane fade  show p-3 p-xl-4">
      <h4 className="page-title text-truncate text-dark font-weight-medium mb-1">
        {title ? title : "Add Hierarchy"}
      </h4>
      <div className="page-ath-form w-100">
        <div className="form-align-box">
          <div className="wizard">
            <HierarchyTopBar
              index={index}
              setIndex={setIndex}
              hierarchyTopBarData={hierarchyTopBarDataState}
            />
            <HierarchyActionButton
              index={index}
              setIndex={setIndex}
              formRef={formRef}
              buttonType={buttonType}
              setButtonType={setButtonType}
              hierarchyTopBarDataState={hierarchyTopBarDataState}
              setUserInfo={setUserInfo}
              data={data}
              setSubIndex={setSubIndex}
            />
            <HierarchyIndex
              data={data}
              setData={setData}
              index={index}
              setIndex={setIndex}
              submitForm={submitForm}
              formRef={formRef}
              UserInfo={UserInfo}
            />
            <HierarchyActionButton
              setSubIndex={setSubIndex}
              index={index}
              setIndex={setIndex}
              formRef={formRef}
              buttonType={buttonType}
              setButtonType={setButtonType}
              hierarchyTopBarDataState={hierarchyTopBarDataState}
              setUserInfo={setUserInfo}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="personalContact"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title text-dark font-weight-medium"
                id="exampleModalLabel"
              >
                Personal Contact
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setSelectedFileName("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {/* <div className="col-lg-6">
                  <h5 className="mb-3 text-black font-weight-bold">
                    Add Members to Contacts Via Email
                  </h5>
                  <p className="mb-4 text-black">
                    To invite new members to Contact List, add the email address
                    to the field below.
                  </p>
                  <h6 className="mb-3 text-black font-weight-bold">
                    Add email address
                  </h6>
                  <p className="mb-3 text-black">
                    Separate each email with a comma or by clicking
                    return/enter.
                  </p>
                  <div className="mb-3">
                    <input type="text" className=" form-control" id="newTag" />
                  </div>
                  <p className="mb-3"></p>
                  <ul id="tagList" className="email_list_tag"></ul>
                  <p />
                  <hr className="mt-3" />
                  <p className="my-4">
                    <button type="button" className="btn btn-primary">
                      Add Members
                    </button>
                  </p>
                </div> */}
                {/*/Col*/}
                {/* <div className="col-lg-1 text-center d-flex align-items-center justify-content-center py-4">
                  OR
                </div> */}
                {/*/Col*/}
                <div className="col-lg-12">
                  <h5 className="mb-3 text-black font-weight-bold">
                    Add Members to Contacts Via CSV
                  </h5>
                  <p className="mb-3 text-black">
                    To add members to Contacts via CSV, upload a CSV file
                    containing the user’s First Name, Last Name, Email can be
                    included.
                  </p>
                  <p className="mb-3 text-black">
                    Click the “Download CSV Template” button to download a
                    template containing the correct column headers.
                  </p>
                  <h6 className="mb-3 text-black font-weight-bold">
                    File Upload (.csv)
                  </h6>
                  <div className="row">
                    <div className="col-sm-5 mb-3">
                      <span className="btn btn-secondary btn-file">
                        Choose a File{" "}
                        <input
                          type="file"
                          onChange={convertCSVtoObjectHandler}
                        />
                      </span>
                    </div>
                    <div className="col-sm-7 mb-3">
                      <a href={companyTem} download="Template.xlsx">
                        <i
                          data-feather="download"
                          className="feather-icon font-12 w-16 mr-2"
                        />
                        Download CSV Template
                      </a>
                    </div>
                    <div className="col-sm-7 mb-3 d-flex align-items-center">
                      {selectedFileName && (
                        <div className="file-name ml-3">{selectedFileName}</div>
                      )}
                    </div>
                    {excelError ? (
                      <div className="col-12 my-4">
                        <div class="alert alert-danger" role="alert">
                          {excelError}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-12 my-4">
                      <button
                        type="button"
                        onClick={() => createUsersInCompany()}
                        className="btn btn-primary"
                        data-dismiss="modal"
                        aria-label="Close"
                        disabled={!selectedFileName}
                      >
                        Bulk Add Members
                      </button>
                    </div>
                  </div>
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHierarchy;
