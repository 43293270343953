import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import AllDataPersonal from "./AllPersonalContactData";
import AddPersonalContactForm from "./AddPersonalContactForm";
import SpouseDataFillUp from "./SpouseDataFillUp";
import DependentsForm from "./DependentsForm";
import "./ProgessButton.css";

const AddPersonalContact = () => {
  let location = useLocation();
  const { jsonApi } = useApis();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [currentForm, setCurrentForm] = useState(1);
  const [isSaved, setIsSaved] = useState(false);

  const init = async () => {
    console.log(location.pathname);
    if (location.pathname === "/contact/personal/add") {
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
    if (!searchParams.get("id")) {
      const res = await jsonApi(appConstant.createUserDetail, null, {}, false);
      if (!res.error) {
        setSearchParams({
          id: res.data.data._id,
        });
        setData(res.data.data);
      }
    } else {
      const res = await jsonApi(
        appConstant.createUserDetail,
        { id: searchParams.get("id") },
        {},
        false
      );
    }
  };

  useEffect(() => {
    init();
  }, [searchParams]);

  const renderForm = () => {
    switch (currentForm) {
      case 1:
        return (
          <AddPersonalContactForm
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            currentForm={currentForm}
            setIsSaved={setIsSaved}
            isSaved={isSaved}
          />
        );
      case 2:
        return (
          <SpouseDataFillUp
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            currentForm={currentForm}
            setIsSaved={setIsSaved}
            isSaved={isSaved}
          />
        );
      case 3:
        return (
          <DependentsForm
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            currentForm={currentForm}
          />
        );
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (isSaved && currentForm < 3) {
      setCurrentForm(currentForm + 1);
      setIsSaved(false); // Reset isSaved for the next form

    }
  };

  // Handler for the Previous button
  const handlePrevious = () => {
    if (currentForm > 1) {
      setCurrentForm(currentForm - 1);
    }
  };

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="row">
            <div className="col-12 align-self-center">
              <h3 className="page-title text-truncate text-dark font-weight-medium mx-3">
                {isEditing ? "Edit Personal Contact" : "Add Personal Contact"}
              </h3>
              {/* <AddPersonalContactForm />
              <SpouseDataFillUp/>
              <DependentsForm/> */}
              {/* <div className="mb-3 center-buttons">
  
                <button
                  className={` ${
                    currentForm >= 1 ? "btn-primarys" : ".btn-outline-primarys"
                  }`}
                  onClick={() => setCurrentForm(1)}
                  disabled={false}
                >
                  Add Personal Details
                </button>
                <span className="separator">-------</span>
                <button
                  className={`${
                    currentForm >= 2 ? "btn-primarys" : ".btn-outline-primarys"
                  }`}
                  onClick={() => setCurrentForm(2)}
                  disabled={currentForm === 1}
                >
                  Add Spouse Details
                </button>
                <span className="separator">-------</span>
                <button
                  className={` ${
                    currentForm === 3 ? "btn-primarys" : ".btn-outline-primarys"
                  }`}
                  onClick={() => setCurrentForm(3)}
                  disabled={currentForm === 1 || currentForm === 2}
                >
                  Add Dependents
                </button>
              </div> */}

              <div className="mb-3 center-buttons">
                <button
                  className={`${
                    currentForm >= 1 ? "btn-primarys" : "btn-outline-primarys"
                  } ${currentForm >= 1 ? "" : "btn_disabled"}`} 
                  onClick={() => setCurrentForm(1)}
                  disabled={false}
                >
                  Add Personal Details
                </button>
                <span className="separator">-------</span>
                <button
                  className={`${
                    currentForm >= 2 ? "btn-primarys" : "btn-outline-primarys"
                  } ${currentForm === 1 ? "btn_disabled" : ""}`} 
                  onClick={() => setCurrentForm(2)}
                  disabled={currentForm === 1}
                >
                  Add Spouse Details
                </button>
                <span className="separator">-------</span>
                <button
                  className={`${
                    currentForm === 3 ? "btn-primarys" : "btn-outline-primarys"
                  } ${
                    currentForm === 1 || currentForm === 2 ? "btn_disabled" : ""
                  }`} 
                  onClick={() => setCurrentForm(3)}
                  disabled={currentForm === 1 || currentForm === 2}
                >
                  Add Dependents
                </button>
              </div>
            </div>
            {renderForm()}
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto img-fluid"
            title="Ads"
            alt="Ads"
          />
        </div>
      </div>
    </div>
  );
};

export default AddPersonalContact;
