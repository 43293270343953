import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Routing from './Routing';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './context/Context';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const root = ReactDOM.createRoot(document.getElementById('root'));
const stripePromise = loadStripe('pk_test_51OaHo1KlbYazsAN8Ibhqce5gHRMY1t1rPjDqHXk1rXlsIVuoGNLKQU2yK6ojCY6JljOgnYz36iPsv5UzfwH23XvH00ulN380KN');
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthContextProvider>
      <Elements stripe={stripePromise}>

        <Routing />
      </Elements>
    </AuthContextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);


