import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const AddCorporatePhone = ({
  phoneModal,
  setPhoneModal,
  onSave,
  phoneNumber,
}) => {
  const handleCloseModal = () => {
    setPhoneModal(false);
    // setPhoneNumber('');
  };

  const handleSave = () => {
    onSave();
    handleCloseModal();
  };

  return (
    <Modal show={phoneModal} onHide={handleCloseModal} id="corporatePhonePopup">
      <div className="modal-content">
        <div className="modal-header">
          <h4
            className="modal-title text-dark font-weight-medium"
            id="exampleModalLabel"
          >
            Add Corporate Phone
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group row">
            <label htmlFor="" className="col-sm-5 col-form-label">
              Corporate Phone
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                maxLength={15}
                placeholder="Please enter valid cell number"
                className="form-control"
                value={phoneNumber}
                onChange={(e) => onSave(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={handleCloseModal}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSave}
          >
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddCorporatePhone;
