import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

function Payment({ show, handleClose, amount }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [message, setMessage] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
    } else {
    
      console.log(paymentMethod.id);
      setMessage("Payment Done ");
      await delay(5000);
      // You can handle the payment on the server side here
      // After handling the payment, close the modal
      handleClose();
      setMessage();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Make Payment of {amount}$</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <CardElement />
          {error && <div style={{ color: "red" }}>{error}</div>}
          <Button
            variant="primary"
            type="submit"
            className="my-5"
            disabled={!stripe || loading}
          >
            {loading ? "Processing..." : "Pay"}
          </Button>
        </form>
        {message ? <p>Payment Done</p> : <></>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Payment;
