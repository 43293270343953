import React, { useState } from "react";
import zipCodes from "../../json/zipCode.json";

const AddressField = ({ errors, item, register, setValue, check }) => {
  const [zipData, setZipData] = useState({
    city: "",
    state: "",
  });
  const [country, setCountry] = useState("");

  const handleZipChange = (event) => {
    const zip = event.target.value;
    const zipInfo = zipCodes.find((entry) => entry.zip === zip);
  
    if (zipInfo) {
      setZipData({
        city: zipInfo.city,
        state: zipInfo.state,
      });
      setCountry("US");
  
      // Update the liveAddress, pcpAddress, and pharmacyAddress independently
      setValue("liveAddress", {
        zip: zip,
        country: "US",
        cityState: `${zipInfo.city}, ${zipInfo.state}`,
        street: "", // Don't reset street or suiteNo here
        suiteNo: "",
      });
  
      setValue("pcpAddress", {
        zip: zip,
        country: "US",
        cityState: `${zipInfo.city}, ${zipInfo.state}`,
        street: "", // Don't reset street or suiteNo here
        suiteNo: "",
      });
  
      setValue("pharmacyAddress", {
        zip: zip,
        country: "US",
        cityState: `${zipInfo.city}, ${zipInfo.state}`,
        street: "", // Don't reset street or suiteNo here
        suiteNo: "",
      });
    } else {
      setZipData({
        city: "",
        state: "",
      });
      setCountry("");
  
      // Clear liveAddress, pcpAddress, and pharmacyAddress independently for zip and cityState when zip is invalid
      setValue("liveAddress", {
        zip: "",
        country: "US",
        cityState: "",
        street: "",
        suiteNo: "",
      });
  
      setValue("pcpAddress", {
        zip: "",
        cityState: "",
      });
  
      setValue("pharmacyAddress", {
        zip: "",
        cityState: "",
      });
    }
  };
  
  const handleCityStateChange = (event) => {
    const value = event.target.value;
    const [city, state] = value.split(",").map((item) => item.trim());
    setZipData({
      city: city || "",
      state: state || "",
    });
  
    // Update liveAddress, pcpAddress, and pharmacyAddress independently for cityState
    setValue("liveAddress", {
      zip: "", // Keep zip empty as it will be re-entered
      country: "US",
      cityState: value,
      street: "",
      suiteNo: "",
    });
  
    setValue("pcpAddress", {
      zip: "", // Keep zip empty as it will be re-entered
      cityState: value,
    });
  
    setValue("pharmacyAddress", {
      zip: "", // Keep zip empty as it will be re-entered
      cityState: value,
    });
  };
  


  return (
    <>
      <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
        {item.fieldShowName}
      </h4>
      <div className="row my-2">
        <div className="col-7 pt-2">
          <label htmlFor="zip">Zip Code</label>
        </div>
        <div className="col-5">
          <input
            type="text"
            maxLength={5}
            className={`form-control borderStyle ${
              errors.zip ? "border border-danger" : ""
            }`}
            {...register("zip", {
              required: "Zip code is required",
            })}
            onChange={handleZipChange}
          />
          {errors.zip && <p className="text-danger">{errors.zip.message}</p>}
        </div>
      </div>
      <div className="row my-2">
        <div className="col-7">
          <label htmlFor="country">Country</label>
        </div>
        <div className="col-5">
          <input
            type="text"
            maxLength={256}
            className={`form-control borderStyle ${
              errors.country ? "border border-danger" : ""
            }`}
            value={country}
            {...register("country", { required: false })}
          />
          {errors.country && (
            <p className="text-danger">{errors.country.message}</p>
          )}
        </div>
      </div>
      <div className="row my-2">
        <div className="col-7">
          <label htmlFor="cityState">City, State</label>
        </div>
        <div className="col-5">
          <input
            type="text"
            maxLength={256}
            className={`form-control borderStyle ${
              errors.cityState ? "border border-danger" : ""
            }`}
            value={`${zipData.city}, ${zipData.state}`}
            onChange={handleCityStateChange}
            {...register("cityState", {})}
          />
          {errors.cityState && (
            <p className="text-danger">{errors.cityState.message}</p>
          )}
        </div>
      </div>
      {item.streetSuiteShow && (
        <>
          <div className="row my-2">
            <div className="col-7">
              <label htmlFor="street">Street</label>
            </div>
            <div className="col-5">
              <input
                type="text"
                maxLength={256}
                className={`form-control borderStyle ${
                  errors.street ? "border border-danger" : ""
                }`}
                {...register("street", { required: false })}
              />
              {errors.street && (
                <p className="text-danger">{errors.street.message}</p>
              )}
            </div>
          </div>

          <div className="row my-2">
            <div className="col-7">
              <label htmlFor="suiteNo">Suite #</label>
            </div>
            <div className="col-5">
              <input
                type="text"
                maxLength={256}
                className={`form-control borderStyle ${
                  errors.suiteNo ? "border border-danger" : ""
                }`}
                {...register("suiteNo", { required: false })}
              />
              {errors.suiteNo && (
                <p className="text-danger">{errors.suiteNo.message}</p>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddressField;

// import React, { useState, useEffect } from "react";
// import zipCodes from "../../json/zipCode.json";

// const AddressField = ({ errors, item, register, setValue, check }) => {
//   const [zipData, setZipData] = useState({
//     city: "",
//     state: "",
//   });
//   const [country, setCountry] = useState("");
//   const [showAddressMailFields, setShowAddressMailFields] = useState(false);

//   // Use useEffect to update the address fields based on the 'item' data dynamically
//   useEffect(() => {
//     // Show address mail fields based on the `check` field in the `item`
//     if (check) {
//       setShowAddressMailFields(true);
//     }
//   }, [check]);

//   const handleCheckboxChange = () => {
//     setShowAddressMailFields(!showAddressMailFields);
//   };

//   const handleZipChange = (event) => {
//     const zip = event.target.value;
//     const zipInfo = zipCodes.find((entry) => entry.zip === zip);

//     if (zipInfo) {
//       setZipData({
//         city: zipInfo.city,
//         state: zipInfo.state,
//       });
//       setCountry("US");

//       setValue("liveAddress", [
//         {
//           zip: zip,
//           country: "US",
//           cityState: `${zipInfo.city}, ${zipInfo.state}`,
//           street: "",  // Don't reset street or suiteNo here
//           suiteNo: "",
//         }
//       ]);
//     } else {
//       setZipData({
//         city: "",
//         state: "",
//       });
//       setCountry("");
//       setValue("liveAddress", [
//         {
//           zip: "",
//           country: "US",
//           cityState: "",
//           street: "",
//           suiteNo: "",
//         }
//       ]);
//     }
//   };

//   const handleCityStateChange = (event) => {
//     const value = event.target.value;
//     const [city, state] = value.split(",").map((item) => item.trim());
//     setZipData({
//       city: city || "",
//       state: state || "",
//     });

//     setValue("liveAddress", [
//       {
//         zip: "",  // Keep zip empty, allow manual entry if necessary
//         country: "US",
//         cityState: value,
//         street: "",
//         suiteNo: "",
//       }
//     ]);
//   };

//   const renderAddressField = (fieldName, fieldLabel, fieldValue, error) => (
//     <div className="row my-2">
//       <div className="col-7">
//         <label htmlFor={fieldName}>{fieldLabel}</label>
//       </div>
//       <div className="col-5">
//         <input
//           type="text"
//           maxLength={256}
//           className={`form-control borderStyle ${error ? "border border-danger" : ""}`}
//           value={fieldValue}
//           {...register(fieldName, { required: false })}
//         />
//         {error && <p className="text-danger">{error.message}</p>}
//       </div>
//     </div>
//   );

//   return (
//     <>
//       <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
//         {item.fieldShowName}
//         {check && (
//           <input
//             type="checkbox"
//             className="ml-2"
//             checked={showAddressMailFields}
//             onChange={handleCheckboxChange}
//           />
//         )}
//       </h4>

//       {/* Dynamic Rendering of Address Fields */}
//       <div className="row my-2">
//         <div className="col-7 pt-2">
//           <label htmlFor="zip">Zip Code</label>
//         </div>
//         <div className="col-5">
//           <input
//             type="text"
//             maxLength={5}
//             className={`form-control borderStyle ${
//               errors.zip ? "border border-danger" : ""
//             }`}
//             {...register("zip", { required: "Zip code is required" })}
//             onChange={handleZipChange}
//           />
//           {errors.zip && (
//             <p className="text-danger">{errors.zip.message}</p>
//           )}
//         </div>
//       </div>

//       {renderAddressField("country", "Country", country, errors.country)}
//       {renderAddressField("cityState", "City, State", `${zipData.city}, ${zipData.state}`, errors.cityState)}

//       {item.streetSuiteShow && (
//         <>
//           {renderAddressField("street", "Street", "", errors.street)}
//           {renderAddressField("suiteNo", "Suite #", "", errors.suiteNo)}
//         </>
//       )}

//       {/* Conditionally render the "Address You Want to Mail At" fields if checkbox is checked */}
//       {showAddressMailFields && (
//         <>
//           {renderAddressField("mailZip", "Zip Code", "", errors.mailZip)}
//           {renderAddressField("mailCityState", "City, State", "", errors.mailCityState)}
//           {renderAddressField("mailStreet", "Street", "", errors.mailStreet)}
//           {renderAddressField("mailSuiteNo", "Suite #", "", errors.mailSuiteNo)}
//         </>
//       )}
//     </>
//   );
// };

// export default AddressField;
