import React, { useEffect, useState } from "react";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import ShowNotes from "../../notes/ShowNotes";
import { Link } from "react-router-dom";
import PaginationComponent from "../../pagination/PaginationComponent";
import contactHeader from "../../../json/contactHeader.json";
import FormatNumber from "../../utils/FormatNumber";
import moment from "moment";
import { Tab, Nav } from "react-bootstrap";

const PersonalContactProfileData = () => {
  const { jsonApi } = useApis();
  const { id } = useParams();
  const [toggle, setToggle] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("All"); // Default active tab

  // console.log(userData.name);
  const init = async () => {
    const details = await jsonApi(
      appConstant.getSingleUserDetail,
      { id },
      null,
      true
    );
    console.log(details);
    if (!details.error) {
      setUserDetails(details?.data?.data || []); // Update state with actual data
      console.log(details?.data?.data);
    } else {
      setError(setError.message ? setError.message : "No data received");
    }
  };
  // console.log(userDetails);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setToggle(false);
  };

  useEffect(() => {
    init();
  }, [id]);

  const spouse = userDetails?.spouse?.userDetails?.[0] || {};
  const dependents = userDetails?.dependents || {};

  return (
    <>
      <div className="container-fluid">
        {/* Start Page Content */}
        {/* basic table */}
        <div className="row">
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body">
                <span
                  style={{
                    color: "blue",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.textDecoration = "underline")
                  }
                  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                  onClick={
                    location.pathname === `/contact/personal/details/${id}`
                      ? () => navigate(`/contact/personal`)
                      : " "
                  }
                >
                  <ChevronLeft />
                  Back to previous page
                </span>
                <div className="row">
                  <div className="col-12 align-self-center pt-4">
                    <div className="row">
                      <div>
                        <div className="col-sm d-inline-block mb-4">
                          <img
                            src="/assets/images/users/2.jpg"
                            alt="user"
                            className="rounded-circle"
                            width={80}
                          />
                        </div>
                      </div>

                      <div className="col-sm">
                        <h2 className="page-title text-truncate text-dark font-weight-medium mb-0">
                          {userDetails.fullName || "No name"}
                        </h2>
                        <div className="pt-0 mt-0">
                          <button
                            type="button"
                            className="btn waves-effect waves-light btn-rounded btn-sm btn-primary"
                            data-toggle="modal"
                            data-target="#editprofilepicModal"
                            onClick={() =>
                              navigate(`/contact/personal/edit?id=${id}`)
                            }
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Start Page Content */}

                    <div className="row">
                      <div className="col-lg-4">
                        <h4 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
                          Personal Information
                        </h4>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Cell No
                          </label>
                          <div className="col-sm-7">
                            {userDetails.phone &&
                            userDetails.phone.length > 0 ? (
                              userDetails.phone.map((phone, index) => (
                                <div key="{phone._id}">{phone.value}</div>
                              ))
                            ) : (
                              <span className="text-muted">
                                No Phone Number
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Email
                          </label>
                          <div className="col-sm-7">
                            {userDetails.email &&
                            userDetails.email.length > 0 ? (
                              userDetails.email.map((email, index) => (
                                <div key="{email._id}">{email.value}</div>
                              ))
                            ) : (
                              <span className="text-muted">No Email</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Relationship Type
                          </label>
                          <div className="col-sm-7">
                            {userDetails.relationshipType || (
                              <span className="text-muted">
                                No relationship type
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Gender
                          </label>
                          <div className="col-sm-7">
                            {userDetails.gender || (
                              <span className="text-muted">No gender</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            DOB
                          </label>
                          <div className="col-sm-7">
                            {userDetails.dob || (
                              <span className="text-muted">No DOB</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Age
                          </label>
                          <div className="col-sm-7">
                            {userDetails.age || (
                              <span className="text-muted">No Age</span>
                            )}
                          </div>
                        </div>

                        <hr />

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Street Address
                          </label>
                          <div className="col-sm-7">
                            {userDetails.primaryResidenceStreetAddress || (
                              <span className="text-muted">
                                No street address
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Suite #
                          </label>
                          <div className="col-sm-7">
                            {userDetails.primaryResidenceSuiteNo || (
                              <span className="text-muted">
                                No suite number
                              </span>
                            )}
                          </div>
                        </div>

                        <hr />

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Social Security #
                          </label>
                          <div className="col-sm-7">
                            {userDetails.socialSecurity || (
                              <span className="text-muted">NA</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Citizenship Status
                          </label>
                          <div className="col-sm-7">
                            {userDetails.citizenshipStatus || (
                              <span className="text-muted">
                                No citizenship status
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Drivers License #
                          </label>
                          <div className="col-sm-7">
                            {userDetails.driversLicense || (
                              <span className="text-muted">
                                No drivers license
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Drivers License State
                          </label>
                          <div className="col-sm-7">
                            {userDetails.driversLicenseState || (
                              <span className="text-muted">
                                No drivers license state
                              </span>
                            )}
                          </div>
                        </div>

                        <hr />

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Wedding Anniv.
                          </label>
                          <div className="col-sm-7">
                            {userDetails.weddingAnniversary || (
                              <span className="text-muted">
                                No wedding anniversary
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Year of Marriage
                          </label>
                          <div className="col-sm-7">
                            {userDetails.yearOfMarriage || (
                              <span className="text-muted">
                                No year of marriage
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <h4 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
                          Business Information
                        </h4>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Position
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyPosition || (
                              <span className="text-muted">No position</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Company Name
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyName || (
                              <span className="text-muted">
                                No company name
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Direct #
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyDirectNumber || (
                              <span className="text-muted">
                                No direct number
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Corporate #
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyCorporateNumber || (
                              <span className="text-muted">
                                No corporate number
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Extension #
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyExtensionNumber || (
                              <span className="text-muted">
                                No extension number
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Street Address
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyStreetAddress || (
                              <span className="text-muted">
                                No street address
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Suite #
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companySuiteNo || (
                              <span className="text-muted">
                                No suite number
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Dept
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyDept || (
                              <span className="text-muted">No department</span>
                            )}
                          </div>
                        </div>

                        <h4 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
                          Health Insurance
                        </h4>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Type of Insurance
                          </label>
                          <div className="col-sm-7">
                            {userDetails.typeOfInsurance || (
                              <span className="text-muted">No department</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Medicare #
                          </label>
                          <div className="col-sm-7">
                            {userDetails.medicareNumber || (
                              <span className="text-muted">No department</span>
                            )}
                          </div>
                        </div>

                        <h5 className="pt-2">Medicare Enrollment Dates</h5>
                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Part A
                          </label>
                          <div className="col-sm-7">
                            {userDetails.medicareEnrollmentDates?.partA || (
                              <span className="text-muted">No department</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Part B
                          </label>
                          <div className="col-sm-7">
                            {userDetails.medicareEnrollmentDates?.partB || (
                              <span className="text-muted">No department</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Part C
                          </label>
                          <div className="col-sm-7">
                            {userDetails.medicareEnrollmentDates?.partC || (
                              <span className="text-muted">No department</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Medicare Plan Type
                          </label>
                          <div className="col-sm-7">
                            {userDetails.medicarePlanType || (
                              <span className="text-muted">
                                No medicare plan type
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Medicare Company
                          </label>
                          <div className="col-sm-7">
                            {userDetails.medicareCompany || (
                              <span className="text-muted">
                                No medicare company
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <h4 className="page-title text-dark font-weight-medium my-4 border-bottom pb-2">
                          Spouse Information
                        </h4>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Spouse Name:
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyPosition || (
                              <span className="text-muted">No position</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            Age
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyPosition || (
                              <span className="text-muted">No position</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group row mb-3 d-flex align-items-center">
                          <label
                            htmlFor="staticEmail"
                            className="col-sm-5 col-form-label text-secondary"
                          >
                            DOB
                          </label>
                          <div className="col-sm-7">
                            {userDetails.companyPosition || (
                              <span className="text-muted">No position</span>
                            )}
                          </div>
                        </div>

                        <h4 className="page-title text-truncate text-dark font-weight-medium my-4">
                          Dependents
                        </h4>
                        {dependents.length > 0 ? (
                          dependents.map((dependent, index) => (
                            <div key="{index}">
                              <h5 className="page-title text-truncate text-dark font-weight-medium my-4">
                                Child #{index + 1}:{" "}
                                {dependent?.name || "No name"}
                              </h5>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-5 col-form-label"
                                >
                                  Age
                                </label>
                                <div className="col-sm-7">
                                  {dependent?.age || "No age"}
                                </div>
                              </div>
                              <div className="form-group mb-0 row">
                                <label
                                  htmlFor="staticEmail"
                                  className="col-sm-5 col-form-label"
                                >
                                  DOB
                                </label>
                                <div className="col-sm-7">
                                  {dependent?.dob || "No DOB"}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No dependents</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row mb-5">
                <div className="col-12 align-self-center">
                  <h4 className="page-title text-truncate text-dark font-weight-medium mb-1">
                    Contact History
                  </h4>

                  {/* <div className="dropdown mb-4">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      aria-expanded={toggle}
                      onClick={handleToggle}
                    >
                      {activeTab}{" "}
                      <i
                        className={`fa ${
                          toggle ? "fa-caret-up" : "fa-caret-down"
                        }`}
                      ></i>
                    </button>
                    <ul
                      className={`dropdown-menu ${toggle ? "show" : ""}`}
                      aria-labelledby="dropdownMenuButton"
                    >
                      {contactHeader.businessInfoHeader.map((item, index) => (
                        <li key={index}>
                          <Link
                            className={`dropdown-item ${
                              activeTab === item ? "active" : ""
                            }`}
                            to="#"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default anchor behavior
                              handleTabChange(item);
                            }}
                          >
                            {item}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  <Tab.Container activeKey={activeTab}>
                    <Nav variant="tabs" className="mb-4">
                      {contactHeader.businessInfoHeader.map((item, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link
                            eventKey={item}
                            onClick={() => handleTabChange(item)}
                          >
                            {item}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content>
                      {contactHeader.businessInfoHeader.map((item, index) => (
                        <Tab.Pane eventKey={item} key={index}>
                          {/* Content for each tab */}
                          <div>{`Content for ${item}`}</div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>

                  <div className="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "all" ? "show active" : ""
                      }`}
                      id="all"
                      role="tabpanel"
                      aria-labelledby="all-tab"
                    >
                      <div className="table-responsive">
                        <div
                          id="default_order_wrapper"
                          className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                        >
                          <PaginationComponent
                            setPage={setPage}
                            totalData={totalData}
                            limit={limit}
                            setLimit={setLimit}
                            page={page}
                            setSearch={setSearch}
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  id="default_order"
                                  className="table table-striped table-bordered display no-wrap"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>S. No.</th>
                                      <th>Phone</th>
                                      <th>User Number</th>
                                      <th>Type</th>
                                      <th>Calling Time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {userDetails.callHistory &&
                                    userDetails.callHistory?.length > 0 ? (
                                      userDetails.callHistory?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td>
                                              {(page - 1) * limit + index + 1}
                                            </td>

                                            <td>{FormatNumber(item.phone)}</td>
                                            <td>
                                              {FormatNumber(item.userNumber)}
                                            </td>
                                            <td>{item.type}</td>
                                            <td>
                                              {moment(item?.createdAt).format(
                                                "YYYY-MM-DD HH:mm:ss"
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="4">No data available</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </PaginationComponent>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "notes" ? "show active" : ""
                      }`}
                      id="notes"
                      role="tabpanel"
                      aria-labelledby="notes-tab"
                    >
                      <p>
                        <ShowNotes usePageWrapper={false} add={false} />
                      </p>
                    </div>

                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "emails"
                          ? "show active"
                          : ""
                      }`}
                      id="emails"
                      role="tabpanel"
                      aria-labelledby="emails-tab"
                    >
                      <p>Emails</p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "phone calls"
                          ? "show active"
                          : ""
                      }`}
                      id="phone-calls"
                      role="tabpanel"
                      aria-labelledby="phone-calls-tab"
                    >
                      <p>Phone Calls</p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "text messages"
                          ? "show active"
                          : ""
                      }`}
                      id="text-messages"
                      role="tabpanel"
                      aria-labelledby="text-messages-tab"
                    >
                      <p>Text Messages</p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "us postal mail"
                          ? "show active"
                          : ""
                      }`}
                      id="us-postal-mail"
                      role="tabpanel"
                      aria-labelledby="us-postal-mail-tab"
                    >
                      <p>Us Potal Mail</p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "chats" ? "show active" : ""
                      }`}
                      id="chats"
                      role="tabpanel"
                      aria-labelledby="chats-tab"
                    >
                      <p>Chats</p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "fax" ? "show active" : ""
                      }`}
                      id="fax"
                      role="tabpanel"
                      aria-labelledby="fax-tab"
                    >
                      <p>Fax</p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "video conferencing"
                          ? "show active"
                          : ""
                      }`}
                      id="video-conferencing"
                      role="tabpanel"
                      aria-labelledby="video-conferencing-tab"
                    >
                      <p>Video Conferencing</p>
                    </div>
                  </div>
                  {/* end row*/}
                </div>
              </div>
              {/* multi-column ordering */}
            </div>
          </div>

          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
              alt="ads"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalContactProfileData;
