import React from "react";
import { useSearchParams } from "react-router-dom";

const HierarchyFinished = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="my-5 w-100">
      <div className="row">
        <div className="col-12 text-center py-5 my-5">
          <p>
            <span className="white_circle_withcheck">
              <i data-feather="check" />
            </span>
          </p>
          <h2 className="text-black my-5">
            Hierarchy on-boarding process
            <br /> has been done successfully!
          </h2>
          <p>
            <a
              href="#"
              onClick={() => {
                setSearchParams({
                  type: "list",
                });
              }}
              className="btn green_light_btn px-4"
            >
              Ok
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HierarchyFinished;
