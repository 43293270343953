import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import "./DatePickerStyle.css"

const NoteField = ({ control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "addNotes",
  });

  return (
    <div>
      <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
        Add Notes
      </h4>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Date</th>
            <th>Details</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Render dynamic fields using the fields from useFieldArray */}
          {fields.map((note, index) => (
            <tr key={note.id}>
              <td>{index + 1}</td>
              <td className="w-25">
                <Controller
                  control={control}
                  name={`addNotes[${index}].noteDate`}
                  defaultValue={note.noteDate || ""}
                  render={({ field }) => (
                    // <input type="text" className="form-control" {...field} />
                    <DatePicker
                      value={field.value}
                      onChange={field.onChange}
                      format="DD/MM/YYYY"
                      className="form-control custom-date-picker"
                      calendarPosition="bottom-center"
                      selected={field.value ? new Date(field.value) : null}
                    />
                  )}
                />
                {/* {errors.addNotes?.[index]?.date && (
                  <span className="text-danger">
                    Date is required
                  </span>
                )} */}
              </td>
              <td >
                <Controller
                  control={control}
                  name={`addNotes[${index}].noteDetails`}
                  defaultValue={note.noteDetails || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
                {/* {errors.addNotes?.[index]?.details && (
                  <span className="text-danger">
                    Details are required
                  </span>
                )} */}
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  ×
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary ml-auto"
          onClick={() =>
            append({ noteDate: "", noteDetails: "" })
          }
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default NoteField;


// import React, { useState } from "react";

// const NoteField = ({ errors, register, item }) => {
//   const [addNotes, setAddNotes] = useState([{ date: "", details: "" }]);

//   const addNote = () => {
//     setAddNotes([...addNotes, { date: "", details: "" }]);
//   };

//   const removeNote = (index) => {
//     if (addNotes.length > 1) {
//       const newNotes = addNotes.filter((_, i) => i !== index);
//       setAddNotes(newNotes);
//     }
//   };
//   const handleChange = (index, field, value) => {
//     const newNotes = [...addNotes];
//     newNotes[index][field] = value;
//     setAddNotes(newNotes);
//   };

//   return (
//     <div>
//       {/* <h3>{item.fieldShowName}</h3> */}
//       <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
//         {item.fieldShowName}
//       </h4>
//       <table className="table table-bordered">
//         <thead>
//           <tr>
//             <th>S.No</th>
//             <th>Date</th>
//             <th>Details </th>
//           </tr>
//         </thead>
//         <tbody>
//           {addNotes.map((note, index) => (
//             <tr key={index}>
//               <td>{index + 1}</td>
//               <td style={{ width: "35%" }}>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={note.date}
//                   onChange={(e) => handleChange(index, "date", e.target.value)}
//                   {...register(`addNotes[${index}].date`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={note.details}
//                   onChange={(e) =>
//                     handleChange(index, "details", e.target.value)
//                   }
//                   {...register(`addNotes[${index}].details`, {
//                     required: false,
//                   })}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="row">
//         <div className="text-right mb-3 col-6">
//           {addNotes.length > 1 && (
//             <div className="d-flex">
//               <button
//                 type="button"
//                 className="btn btn-danger"
//                 onClick={() => removeNote(addNotes.length - 1)}
//                 style={
//                   {
//                     // bottom: "10px",
//                     // right: "10px",
//                     // width: "15px", // Small width
//                     // height: "15px", // Small height (to make it circular)
//                     // borderRadius: "50%", // Circular shape
//                     // padding: "0", // Remove padding
//                     // fontSize: "15px", // Adjust font size to fit in the button
//                     // display: "flex", // Use flex to center the plus sign
//                     // justifyContent: "center",
//                     // alignItems: "center",
//                     // lineHeight: "1", // Ensure the text is centered vertically
//                   }
//                 }
//               >
//                 ×
//               </button>
//             </div>
//           )}
//         </div>
//         <div className="text-right mb-3 col-6 ">
//           <div className="d-flex justify-content-end">
//             <button
//               // type="button"
//               // className="btn btn-primary"
//               type="button"
//               className="btn btn-primary position-absolute"
//               onClick={addNote}
//             >
//               +
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NoteField;

