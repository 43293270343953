import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import "../DatePickerStyle.css";

const BeneficiarieNameRelationship = ({ control, register, errors, item }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "beneficiarieNameRelationship",
  });

  return (
    <div>
      <h6 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
        {item.fieldShowName}
      </h6>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Beneficiary Name</th>
            <th>Relationship</th>
            <th> Phone # </th>
            <th>Address</th>
            <th>DOB</th>
            <th>Social Security #</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((beneficiarieNameRelationships, index) => (
            <tr key={beneficiarieNameRelationships.id}>
              <td>
                <Controller
                  control={control}
                  name={`beneficiarieNameRelationship[${index}].beneficiaryName`}
                  defaultValue={
                    beneficiarieNameRelationships.beneficiaryName || ""
                  }
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name={`beneficiarieNameRelationship[${index}].beneficiaryRelationship`}
                  defaultValue={
                    beneficiarieNameRelationships.beneficiaryRelationship || ""
                  }
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name={`beneficiarieNameRelationship[${index}].beneficiaryPhone`}
                  defaultValue={
                    beneficiarieNameRelationships.beneficiaryPhone || ""
                  }
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name={`beneficiarieNameRelationship[${index}].beneficiaryAddress`}
                  defaultValue={
                    beneficiarieNameRelationships.beneficiaryAddress || ""
                  }
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td className="w-30">
                    <Controller
                    control={control}
                    name={`beneficiarieNameRelationship[${index}].beneficiaryDob`}
                    defaultValue={
                      beneficiarieNameRelationships.beneficiaryDob || ""
                    }                    
                    render={({ field }) => (
                      <DatePicker
                        value={field.value}
                        onChange={field.onChange}
                        format="DD/MM/YYYY"
                        className="form-control custom-date-picker"
                        calendarPosition="bottom-center"
                        selected={field.value ? new Date(field.value) : null}
                        maxDate={new Date()}
                      />
                    )}
                  />
                  
                
              </td>
              <td>
                <Controller
                  control={control}
                  name={`beneficiarieNameRelationship[${index}].beneficiarySocialSecurity`}
                  defaultValue={
                    beneficiarieNameRelationships.beneficiarySocialSecurity ||
                    ""
                  }
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>

              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  ×
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex">
        <button
          type="button"
          className="btn btn-primary ml-auto"
          onClick={() =>
            append({
              beneficiaryName: "",
              beneficiaryRelationship: "",
              beneficiaryPhone: "",
              beneficiaryAddress: "",
              beneficiaryDob: "",
              beneficiarySocialSecurity: "",
            })
          }
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default BeneficiarieNameRelationship;
