import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import "./DatePickerStyle.css"
const DateLead = ({ errors, fieldName, fieldShowName, register, control }) => {
  return (
    <div className="row my-2">
      <div className="col-7 pt-2">
        <label htmlFor={fieldName} className="">
          {fieldShowName}
        </label>
      </div>
      <div className="col-5">
        <Controller
          control={control}
          name={fieldName}
          defaultValue={""}
          render={({ field }) => (
            <DatePicker
              value={field.value}
              onChange={field.onChange}
              format="DD/MM/YYYY"
              className="form-control custom-date-picker"
              calendarPosition="bottom-center"
              selected={field.value ? new Date(field.value) : null}
              maxDate={new Date()}
              
            />
          )}
        />

        {errors[fieldName] && (
          <p className="text-danger">{errors[fieldName].message}</p>
        )}
      </div>
    </div>
  );
};

export default DateLead;

// <Controller
// control={control}
// name={`medicationsBeneficiaryEnrollmentDate[${index}].startDate`}
// defaultValue={medicationEnrollment.startDate || ""}
// render={({ field }) => (
//   <DatePicker
//     value={field.value}
//     onChange={field.onChange}
//     format="DD/MM/YYYY"
//     className="form-control custom-date-picker"
//     calendarPosition="bottom-center"
//     selected={field.value ? new Date(field.value) : null}
//   />
// )}
// />

{
  /* <div className="row my-2">
<div className="col-7 pt-2">
  <label htmlFor={fieldName} className="">
    {fieldShowName}
  </label>
</div>
<div className="col-5">
  <input
    type="date"
    maxLength={256}
    required
    className={`form-control borderStyle ${
      errors[fieldName] ? "border border-danger" : ""
    }`}
    max={new Date().toISOString().split("T")[0]}
    id={fieldName}
    {...register(fieldName)}
  />
  {errors[fieldName] && (
    <p className="text-danger">{errors[fieldName].message}</p>
  )}
</div>

</div> */
}
