import React from "react";
import "./Waviy.css"; // Make sure to create and import the CSS file for styling

const ComingSoon = () => {
  return (
    <div className="waviy">
      <span style={{ "--i": 1 }}>c</span>
      <span style={{ "--i": 2 }}>o</span>
      <span style={{ "--i": 3 }}>m</span>
      <span style={{ "--i": 4 }}>i</span>
      <span style={{ "--i": 5 }}>n</span>
      <span style={{ "--i": 6 }}>g</span>
      <span style={{ "--i": 7 }}> </span>
      <span style={{ "--i": 8 }}>s</span>
      <span style={{ "--i": 9 }}>o</span>
      <span style={{ "--i": 10 }}>o</span>
      <span style={{ "--i": 11 }}>n</span>
    </div>
  );
};

export default ComingSoon;
