import React, { useCallback, useEffect, useState } from "react";
import PaginationComponent from "../pagination/PaginationComponent";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";
import debounce from "lodash/debounce";
import "./spineer.css";
import { useLocation } from "react-router-dom";
import searchBasedHeader from "../../json/searchBasedHeader.json";

const SearchBased = ({ search, setSearch }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const { jsonApi } = useApis();
  const [searchBasedDetails, setSearchBasedDetails] = useState([]);
  const [error, setError] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPagination, setShowPagination] = useState(false); 
  const location = useLocation();
  const isBusinessPage = location.pathname === "/contact/business";
  const isPersonalPage = location.pathname === "/contact/personal";
  const isLeadPage = location.pathname === "/contact/personal" || location.pathname === "/contact/business";


  const contactTypeMapping = {
    "/contact/business": [
      "Human Resource",
      "Business Info",
      "Business Contact",
      "Lead"
    ],
    "/contact/personal": ["Personal", "Lead"],
    // "/contact/lead": ["Lead"],
  };

  const contactType = contactTypeMapping[location.pathname] || [];

  const fetchContactHuman = async () => {
    setLoading(true);
    setShowPagination(false);

    // let contactType=[];

    // if(location.pathname=== `/contact/business`) {
    //   contactType=["Human Resource", "Business Info","Business Contact"]
    //   }
    //   else if(location.pathname=== `/contact/personal`){
    //     contactType=["Personal"]
    //   }
    //   else if(location.pathname=== `/contact/lead`){
    //     contactType=["Lead"]
    //   }

    try {
      const contactHuman = await jsonApi(
        appConstant.getSearchContact,
        {
          page,
          limit,
          search,
          type: contactType,
        },
        null,
        true
      );
      if (!contactHuman.error) {
        if (contactHuman.data.total === 0) {
          setNoResults(true);
        } else {
          setNoResults(false);
          setSearchBasedDetails(contactHuman.data.data || []);
          setTotalData(contactHuman.data.total);
          setShowPagination(true); // Show pagination when data is available
        }
      } else {
        setError(
          contactHuman.message ? contactHuman.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    } finally {
      setLoading(false); 
    }
  };

  const debounceFetchContactHuman = useCallback(
    debounce(fetchContactHuman, 500),
    [page, limit, search]
  );

  useEffect(() => {
    if (search.length > 0) {
      debounceFetchContactHuman();
    } else {
      setSearchBasedDetails([]);
      setTotalData(0);
      setNoResults(true);
      setShowPagination(false); // Show pagination when data is available
    }

    return () => {
      debounceFetchContactHuman.cancel();
    };
  }, [page, limit, search, debounceFetchContactHuman]);

  const renderTableHeader = () => {
    const header = isBusinessPage
      ? searchBasedHeader.businessPageHeader
      : isPersonalPage
      ? searchBasedHeader.personalPageHeader
      : searchBasedHeader.leadPageHeader;

    return (
      <tr>
        {header.map((item, index) => (
          <th key={index}>{item}</th>
        ))}
      </tr>
    );
  };

  const renderData = () => {
    // const isBusinessPage = location.pathname === '/contact/business';
    // const isPersonalPage = location.pathname === '/contact/personal';

    return searchBasedDetails.map((item, index) => {
      return (
        <tr key={index}>
          <td>{(page - 1) * limit + index + 1}</td>

          {isBusinessPage ? (
            <>
              <td>{item.fullName || "Not Available"}</td>
              <td>
                {item.phone && item.phone.length > 0
                  ? item.phone.map((p) => p.value).join(", ")
                  : "Not Available"}
              </td>
              <td>
                {item.email && item.email.length > 0
                  ? item.email.map((e) => e.value).join(", ")
                  : "Not Available"}
              </td>
              <td>{item.companyDirectNumber || "Not Available"}</td>
              <td>{item.companyName || "Not Available"}</td>
              <td>{item.companyBusinessName || "Not Available"}</td>
              <td>{item.employmentLevel || "Not Available"}</td>
            </>
          ) : isPersonalPage ? (
            <>
              <td>{item.fullName || "Not Available"}</td>
              <td>
                {item.phone && item.phone.length > 0
                  ? item.phone.map((p) => p.value).join(", ")
                  : "Not Available"}
              </td>
              <td>
                {item.email && item.email.length > 0
                  ? item.email.map((e) => e.value).join(", ")
                  : "Not Available"}
              </td>
              <td>{item.type || "Not Available"}</td>
            </>
          ) 
          : isLeadPage ? (
            <>
              <td>{item.fullName || "Not Available"}</td>
              <td>
                {item.phone && item.phone.length > 0
                  ? item.phone.map((p) => p.value).join(", ")
                  : "Not Available"}
              </td>
              <td>
                {item.email && item.email.length > 0
                  ? item.email.map((e) => e.value).join(", ")
                  : "Not Available"}
              </td>
              <td>{item.leadCheck || "Not Available"}</td>
              <td>{item.gender || "Not Available"}</td>
            </>
          ) 
          : (
            <></>
          )}
        </tr>
      );
    });
  };

  return (
    <div className="container-fluid">
 
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button"></div>
            {error && <div className="alert alert-danger">{error}</div>}
            {/*/Row*/}
            {loading && (
              <div className="alert alert-info text-center fetching-message">
                <div className="spinner"></div>
                Fetching data...
              </div>
            )}
            {search.length === 0 ? (
              <div className="alert alert-warning text-center">
                Please enter text to view the required data.
              </div>
            ) : noResults && !loading ? (
              <div className="alert alert-info text-center">Data not found</div>
            ) : (
              !loading &&
              showPagination && (
                <div className="row p-4">
                  <div className="col-12 mb-5">
                    <div className="table-responsive">
                      <div
                        id="default_order_wrapper"
                        className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                      >
                        <PaginationComponent
                          setPage={setPage}
                          totalData={totalData}
                          limit={limit}
                          setLimit={setLimit}
                          page={page}
                          setSearch={setSearch}
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                id="default_order"
                                className="table table-striped table-bordered display no-wrap"
                                style={{ width: "100%" }}
                              >
                                <thead>{renderTableHeader()}</thead>
                                <tbody>{renderData()}</tbody>
                              </table>
                            </div>
                          </div>
                        </PaginationComponent>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBased;

// const renderData = () => {
//   return searchBasedDetails.map((item, index) => (
//     <tr key={index}>
//       <td>{(page - 1) * limit + index + 1}</td>
//       <td>{item.fullName || "Not Available"}</td>
//       <td>{item.firstName || "Not Available"}</td>
//       <td>{item.lastName || "Not Available"}</td>
//       <td>{item.phone || "Not Available"}</td>
//       <td>{item.email || "Not Available"}</td>
//       <td>{item.companyBusinessName || "Not Available"}</td>
//       <td>{item.type || "Not Available"}</td>
//     </tr>
//   ));
// };

// const renderData = () => {
//   return searchBasedDetails.map((item, index) => (
//     <tr key={index}>
//       <td>{(page - 1) * limit + index + 1}</td>
//       <td>{item.fullName || "Not Available"}</td>
//       <td>{item.firstName || "Not Available"}</td>
//       <td>{item.lastName || "Not Available"}</td>
//       <td>{item.phone && item.phone.length > 0 ? item.phone.map(p => p.value).join(", ") : "Not Available"}</td>
//       <td>{item.email && item.email.length > 0 ? item.email.map(e => e.value).join(", ") : "Not Available"}</td>
//       <td>{item.companyBusinessName || "Not Available"}</td>
//       <td>{item.type || "Not Available"}</td>
//     </tr>
//   ));
// };

// const renderTableHeader = () => {
//   if (isBusinessPage) {
//     return (
//       <tr>
//         {searchBasedHeader.businessPageHeader.map((item,index)=>(
//         <th key={index}>
//           {item}
//         </th>

//         ))}
//       </tr>
//     );
//   } else if (isPersonalPage) {
//     return (
//       <tr>
//          {searchBasedHeader.personalPageHeader.map((item,index)=>(
//         <th key={index}>
//           {item}
//         </th>

//         ))}
//       </tr>
//     );
//   } else if(isLeadPage) {
//     return (
//       <tr>
//        {searchBasedHeader.leadPageHeader.map((item,index)=>(
//         <th key={index}>
//           {item}
//         </th>

//         ))}
//       </tr>
//     );
//   }else (
//       <tr>
//         "No Data"
//       </tr>
//     )

// };
