import React, { useState } from "react";
import contactHeader from "../../../json/contactHeader.json";
import useContactHuman from "../../../hooks/useContactHuman";
import DataTable from "../DataTable";

const BusinessContactEmailPage = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const type = ["Human Resource", "Business Info"];
 
  const {
    error,
    contactDetails: businessEmailDetails,
    totalData,
  } = useContactHuman(type, page, limit, search);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                Business Emails
              </h4>
              <div className="row ">{/*/Col*/}</div>

              <DataTable
                headers={contactHeader.businessEmailHeader}
                fetchedData={businessEmailDetails}
                page={page}
                limit={limit}
                setPage={setPage}
                totalData={totalData}
                setLimit={setLimit}
                setSearch={setSearch}
                showBusinessInfoDetails={true}
                showEmploymentLevel={true}
                showFavButton={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessContactEmailPage;
