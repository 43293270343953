import { useContext } from "react";
import { CallCenterContext } from "../../context/CallCenterContext";
import { useNavigate } from "react-router-dom";
function HoverableNumber({ phone }) {
  const { makeCall, inCall } = useContext(CallCenterContext);

  const navigate = useNavigate();
  const convertNumber = (dd) => {
    // Check if the number is international
    if (dd.startsWith("+") && !dd.startsWith("+1")) {
      return dd; // Return the international number as is
    }

    // Remove any country code if present
    let number = dd.includes("+1") ? dd.split("+1")[1] : dd;

    // Remove any non-numeric characters
    number = number.replace(/\D/g, "");

    // Format the number into (XXX) XXX-XXXX
    if (number.length === 10) {
      return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
    } else {
      return dd;
    }
  };

  return phone.map((item) =>
    inCall ? (
      <div title="Another call in progress">{convertNumber(item.value)}</div>
    ) : (
      <div
        onClick={() => {
          makeCall(item.value);
          navigate("/call/centre");
        }}
        style={{ cursor: "pointer" }}
        title="Click to call"
      >
        {convertNumber(item.value)}
      </div>
    )
  );
}
export default HoverableNumber;
