// import React from "react";
// import InputMask from "react-input-mask";

// const SocialSecurityNumber = ({
//   errors,
//   fieldName,
//   fieldShowName,
//   register,
// }) => {
//   const SSNInput = () => {
//     return (
//       <div>
//         <label htmlFor="ssn">Social Security Number:</label>
//         <InputMask
//           mask="999-99-9999"
//           placeholder="123-45-6789"
//           id="ssn"
//           name="ssn"
//         >
//           {(inputProps) => <input {...inputProps} type="text" />}
//         </InputMask>
//       </div>
//     );
//   };
//   return (
//     <div className="row my-2">
//       <div className="col-4">
//         <label htmlFor={fieldName} className="">
//           {fieldShowName}
//         </label>
//       </div>
//       <div className="col-8">
//         <InputMask
//           mask="999-99-9999"
//           placeholder="123-45-6789"
//           id="ssn"
//           name="ssn"
//         >
//           {(inputProps) => (
//             <input
//               {...inputProps}
//               type="text"
//               className={`form-control borderStyle ${
//                 errors[fieldName] ? "border border-danger" : ""
//               }`}
//               {...register(fieldName)}
//             />
//           )}
//         </InputMask>

//         {errors[fieldName] && (
//           <p className="text-danger">{errors[fieldName].message}</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SocialSecurityNumber;

import React from "react";
import InputMask from "react-input-mask";

const SocialSecurityNumber = ({
  errors,
  fieldName,
  fieldShowName,
  register,
}) => {
  return (
    <div className="row my-2">
      <div className="col-7 pt-2">
        <label htmlFor={fieldName} className="">
          {fieldShowName}
        </label>
      </div>
      <div className="col-5">
        <InputMask
          mask="999-99-9999"
          placeholder="123-45-6789"
          id={fieldName}
          name={fieldName}
          {...register(fieldName)}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              type="text"
              className={`form-control borderStyle ${
                errors[fieldName] ? "border border-danger" : ""
              }`}
            />
          )}
        </InputMask>

        {errors[fieldName] && (
          <p className="text-danger">{errors[fieldName].message}</p>
        )}
      </div>
    </div>
  );
};

export default SocialSecurityNumber;
