import React, { useContext, useEffect, useState } from "react";
import Login from "./pages/login/Login";
import { Context } from "./context/Context";
import { Route, Routes } from "react-router-dom";
import Aside from "./components/aside/Aside";
import CallCentre from "./components/callCentre/CallCentre";
import CoreModule from "./components/coreModule/CoreModule";
import useAuthApis from "./apis/useAuthApis";
import Hierarchy from "./pages/hierarchy/Hierarchy";
import Modules from "./components/modules/Modules";
import Loader from "./components/loader/Loader";
import CallHistory from "./components/callHistory/CallHistory";
import Contact from "./components/contact/Contact";
import BusinessInformation from "./components/contact/addBusinessContact/BusinessContactInformation";
import ContactHistory from "./components/contact/ContactHistory";
import Owners from "./components/contact/addBusinessContact/humanResources/BusinessOwner";
import KeyExecutive from "./components/contact/addBusinessContact/humanResources/BusinessExecutive";
import Managers from "./components/contact/addBusinessContact/humanResources/BusinessManager";
import Employee from "./components/contact/addBusinessContact/humanResources/BusinessEmployee";
import AddTask from "./components/task/AddTask";
import ShowTask from "./components/task/ShowTask";
import ShowNotes from "./components/notes/ShowNotes";
import AddNotes from "./components/notes/AddNotes";
import EditTask from "./components/task/EditTask";
import EditNotes from "./components/notes/EditNotes";
import AddPersonalContact from "./components/contact/addPersonalContact/AddPersonalContact";
import SpouseDataFillUp from "./components/contact/addPersonalContact/SpouseDataFillUp";
import SpouseProfileData from "./components/contact/addPersonalContact/SpouseProfileData";
import DependentsForm from "./components/contact/addPersonalContact/DependentsForm";
import DependentsProfile from "./components/contact/addPersonalContact/DependentsProfile";
import AllPersonalContactData from "./components/contact/addPersonalContact/AllPersonalContactData";
import ForgetPassword from "./components/forgetPassword/ForgetPassword";
import ProfilePasswordUpdate from "./pages/profilePasswordUpdate/ProfilePasswordUpdate";
import UpdateProfile from "./components/updateProfile/UpdateProfile";
import PersonalContactFavourites from "./components/contact/addPersonalContact/PersonalContactFavourites";
import BusinessFavourites from "./components/contact/addBusinessContact/humanResources/BusinessContactFavourites";
import BusinessEmailPage from "./components/contact/addBusinessContact/BusinessContactEmailPage";
import ContactHumanData from "./components/contact/addBusinessContact/humanResources/HumanResourcesData";
import SearchBased from "./components/contact/SearchBased";
import CreateEvents from "./components/events/CreateEvents";
import EventCalendar from "./components/events/EventCalendar";
import UpcomingEvents from "./components/events/UpcomingEvents";
import EditEvents from "./components/events/EditEvents";
import AddEmployee from "./pages/hierarchy/HierarchyFormComponents/AddEmployee";
import APIKey from "./components/apiKey/APIKey";
import UpdateCompanyProfile from "./components/updateCompanyProfile/UpdateCompanyProfile";
import UpdateAdminProfile from "./components/updateAdminProfile/UpdateAdminProfile";
import Dashboard from "./components/dashboard/Dashboard";
import AddBusinessContact from "./components/contact/addBusinessContact/AddBusinessContact";
import CallCenterContextProvider from "./context/CallCenterContext";
import ClientLead from "./components/LeadData/ClientLead";
import ProspectsLead from "./components/LeadData/ProspectsLead";
import SubscriptionAdmin from "./components/subscription/SubscriptionAdmin";
import SubscriptionCompany from "./components/subscription/SubscriptionCompany";
import SubscriptionDetail from "./components/subscription/SubscriptionDetail";
import PersonalContactProfileData from "./components/contact/addPersonalContact/PersonalContactProfileData";
import BusinessContactData from "./components/contact/addBusinessContact/BusinessContactData";
import BusinessContactFavourites from "./components/contact/addBusinessContact/humanResources/BusinessContactFavourites";
import BusinessContactEmailPage from "./components/contact/addBusinessContact/BusinessContactEmailPage";
import BusinessContactInformation from "./components/contact/addBusinessContact/BusinessContactInformation";
import HumanResourcesData from "./components/contact/addBusinessContact/humanResources/HumanResourcesData";
import BusinessOwner from "./components/contact/addBusinessContact/humanResources/BusinessOwner";
import BusinessManager from "./components/contact/addBusinessContact/humanResources/BusinessManager";
import BusinessEmployee from "./components/contact/addBusinessContact/humanResources/BusinessEmployee";
import BusinessExecutive from "./components/contact/addBusinessContact/humanResources/BusinessExecutive";
import LeadDataDetails from "./components/LeadData/LeadDataDetails";
import BusinessContactDetails from "./components/contact/addBusinessContact/BusinessContactDetails";
import ClientProspectsDataUpdate from "./components/LeadData/ClientProspectsDataUpdate";
import ComingSoon from "./components/comingSoon/ComingSoon";
import UpdateLeadForm from "./components/LeadData/UpdateLeadForm";
import UpdateLeadLifeForm from "./components/LeadData/life/UpdateLeadLifeForm";
import CustomFrom from "./components/customForm/CustomFrom";

const Routing = () => {
  const { isAuthorized, isLoading, setIsLoading, userData } =
    useContext(Context);
  const [firstLoading, setFirstLoading] = useState(true);
  const { homeApi } = useAuthApis();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isAuthorized) {
        console.log("first");
        await homeApi();
      }
    }, 30000); // Corrected interval duration

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    checkLogin();
  }, []);
  const checkLogin = async () => {
    setIsLoading(true);
    await homeApi();
    setFirstLoading(false);
    setIsLoading(false);
  };

  return (
    <>
      <Loader open={isLoading} />

      {firstLoading ? (
        <></>
      ) : isAuthorized ? (
        userData.defaultPasswordReset ? (
          <CallCenterContextProvider>
            <Routes>
              <Route path="/" element={<Aside />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/call/centre" element={<CallCentre />} />
                <Route path="/hierarchy" element={<Hierarchy />} />
                <Route path="/core-module" element={<CoreModule />} />
                <Route path="/add-new-task" element={<AddTask />} />
                <Route path="/list-of-tasks" element={<ShowTask />} />
                <Route path="/list-of-notes" element={<ShowNotes />} />
                <Route path="/add-new-note" element={<AddNotes />} />
                <Route path="/list-of-tasks/:id" element={<EditTask />} />
                <Route path="/list-of-notes/:id" element={<EditNotes />} />
                <Route path="/calendar" element={<EventCalendar />} />
                <Route path="/add-new-event" element={<CreateEvents />} />
                <Route path="/upcoming-events" element={<UpcomingEvents />} />
                <Route path="/upcoming-events/:id" element={<EditEvents />} />
                <Route path="/add-employee" element={<AddEmployee />} />
                <Route path="/api-key" element={<APIKey />} />
                <Route path="/custom-form" element={<CustomFrom />} />
                <Route
                  path="/profile-password-update"
                  element={<ProfilePasswordUpdate />}
                />
                <Route path="/profile" element={<UpdateProfile />} />
                <Route
                  path="/company-profile"
                  element={<UpdateCompanyProfile />}
                />
                <Route path="/admin-profile" element={<UpdateAdminProfile />} />
                <Route path="/module" element={<Modules />} />
                <Route path="/call-history" element={<CallHistory />} />
                <Route path="/contact" element={<Contact />}>
                  <Route path="personal" element={<AllPersonalContactData />} />
                  <Route
                    path="personal/favorites"
                    element={<PersonalContactFavourites />}
                  />
                  <Route path="personal/add" element={<AddPersonalContact />} />
                  <Route
                    path="personal/edit"
                    element={<AddPersonalContact />}
                  />
                  <Route
                    path="personal/details/:id"
                    element={<PersonalContactProfileData />}
                  />
                  <Route
                    path="personal/spouse/details"
                    element={<SpouseProfileData />}
                  />

                  <Route path="business" element={<BusinessContactData />} />
                  <Route path="business/add" element={<AddBusinessContact />} />
                  <Route
                    path="business/favorites"
                    element={<BusinessContactFavourites />}
                  />
                  <Route
                    path="business/emailPage"
                    element={<BusinessContactEmailPage />}
                  />
                  <Route
                    path="business/information"
                    element={<BusinessContactInformation />}
                  />
                  <Route path="business/owner" element={<BusinessOwner />} />
                  <Route
                    path="business/manager"
                    element={<BusinessManager />}
                  />
                  <Route
                    path="business/employee"
                    element={<BusinessEmployee />}
                  />
                  <Route
                    path="business/executive"
                    element={<BusinessExecutive />}
                  />
                  <Route
                    path="business/human/resource"
                    element={<HumanResourcesData />}
                  />
                  <Route
                    path="business/details/:id"
                    element={<BusinessContactDetails />}
                  />
                  
                  {/* <Route path="business/lead/client" element={<ClientLead />} />
                  <Route path="business/lead/prospects" element={<ProspectsLead />} /> */}


                  <Route path="lead/client" element={<ClientLead />} />
                  <Route path="lead/prospects" element={<ProspectsLead />} />
                  {/* <Route path="lead" element={<LeadDataDetails />} />
                  <Route path="lead/client" element={<ClientLead />} />
                  <Route path="lead/prospects" element={<ProspectsLead />} />
                  <Route
                    path="lead/data/update"
                    element={<ClientProspectsDataUpdate />}
                  />*/}
                  <Route
                    path="lead/details/:id"
                    element={<BusinessContactDetails />}
                  /> 

                  <Route path="search" element={<SearchBased />} />
                </Route>

                <Route
                  path="/subscription-admin"
                  element={<SubscriptionAdmin />}
                />
                <Route
                  path="/subscription-company"
                  element={<SubscriptionCompany />}
                />
                <Route
                  path="/company/details/:id"
                  element={<SubscriptionDetail />}
                />
                <Route
                  path="/updateLeadForm/:id"
                  element={<UpdateLeadForm />}
                />
                <Route
                  path="/updateLeadForm/life/:id"
                  element={<UpdateLeadLifeForm />}
                />
                <Route path="/*" element={<ComingSoon />} />
              </Route>
            </Routes>
          </CallCenterContextProvider>
        ) : (
          <Routes>
            <Route
              path="/password-update"
              element={<ProfilePasswordUpdate />}
            />
          </Routes>
        )
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
        </Routes>
      )}
    </>
  );
};

export default Routing;
