import React, { useEffect, useState, useRef} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import contactHeader from "../../../json/contactHeader.json";
import { Controller, useForm } from "react-hook-form";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import {
  validateName,
  validateEmail,
  validateZipCode,
  validateSuiteNumber,
  validateSicCode,
  validateMaicsCode,
  validateGlCode,
  validateURL,
  validateNumber,
  validatePercentOwn,
} from "../../validation/Validation";
import "react-datepicker/dist/react-datepicker.css";
import useContactHuman from "../../../hooks/useContactHuman";
import { PhoneInput } from "react-international-phone";
import DataTable from "../DataTable";

const BusinessContactInformation = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const { jsonApi } = useApis();
  const formRef = useRef(null); 
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [error, setError] = useState();
  const [dateError, setDateError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [successMessage, setSuccessMessage] = useState();
  const navigate=useNavigate();
  const type = ["Business Info"];

  const {
    contactDetails: allContactDetails,
    totalData,
    fetchContactHuman,
  } = useContactHuman(type, page, limit, search);


  const onSubmit = async (data) => {
    const formData = {
      ...data,
      type: "Business Info",
    };

    const formatContact = (contact, type = "Personal") =>
      contact && typeof contact === "string"
        ? [{ value: contact, default: false, type }]
        : [];
    formData.phone = formatContact(formData.phone);
    formData.email = formatContact(formData.email);

    try {
      let response;
      if (isEditing && editData) {
        response = await jsonApi(
          appConstant.updatedBusinessInformation,
          { id: editData._id },
          formData,
          null
        );
      } else {
        response = await jsonApi(
          appConstant.createBusinessInformation,
          null,
          formData,
          null
        );
      }
      console.log("API Response:", response);
      if (!response.error) {
        setError("");
        setSuccessMessage(isEditing ? response.data?.message : response.data?.message);       
        reset();
        setIsEditing(false);
        fetchContactHuman();
        handleMessage();
        setSearchParams({ id: "", });
        navigate('/contact/business/information');
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError("An unexpected error occurred");
    }
  };

  const handleMessage = () => {
    setInterval(() => {
      setSuccessMessage("");
    }, 5000);
  };

  const hasError = Object.keys(errors).length > 0;
  const getGeneralErrorMessage = () => {
    if (hasError) {
      return "Enter all the details!";
    }
    return "";
  };

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      setSelectedDate(date);
      setValue("dateBusinessStarted", date, { shouldValidate: true });
      setDateError("");
    } else {
      setDateError("Invalid date format. Please select a valid date.");
    }
  };

  const employmentLevel = watch("employmentLevel");

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res?.error) {
      setSearchParams({
        id: res.data.data._id,
      });
      console.log("first",res)
      setData(res.data.data);
      populateForm(res.data.data);
    }
  };
  useEffect(() => {
    init();
  }, [searchParams.get("id")]);


  const populateForm = (data) => {
    if (!data) return;
  
    setIsEditing(true);
    setEditData(data);
  
    const getValue = (key) => Array.isArray(data[key]) ? data[key][0]?.value : data[key];
  
    const fields = [
      "companyName", "firstName", "lastName", "companyDirectNumber", "employmentLevel","companyPercentageOwn",
      "companyStreetAddress", "companySuiteNo", "companyZip", "companyState", "companyCity", "companyUrl","sicCode",
      "maicsCode","glCode","legalEntityType","dateBusinessStarted","taxIdNumber","industry","companyBusinessName"
    ];
  
    fields.forEach(field => setValue(field, data[field]));
    setValue("phone", getValue("phone"));
    setValue("email", getValue("email"));
  
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }  };

  const firstName = watch("firstName");
  const lastName = watch("lastName");

  useEffect(() => {
    if (firstName && lastName) {
      setValue("fullName", `${firstName} ${lastName}`);
    }
  }, [firstName, lastName, setValue]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
          <h4 className="page-title text-truncate text-dark font-weight-medium mx-4 pt-4">
                Business Information    
              </h4>
            <div className="card-body custom-field-button">
              <div className="row mb-5">
                <div className="col-lg-4">
                  {contactHeader.fields.map((item, index) => (
                    <div
                      className="row align-items-center mb-3 mb-sm-0"
                      key={index}
                    >
                      <label className="col-md-5 col-sm-4 mb-0 font-weight-bold">
                        {item.label}
                      </label>
                      <div className="col-md-7 col-sm-8">
                        <input
                          maxLength={256}
                          type={item.type}
                          className={item.class}
                          //placeholder={item.value}
                          readOnly={item.readOnly}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-lg-8">
                  <textarea
                    className="form-control"
                    id=""
                    rows={8}
                    value=""
                    //placeholder={"Notes: 01/01/22, 6:00 AM John Doe"}
                  />
                </div>
              </div>

              <DataTable
                headers={contactHeader.businessInformationHeader}
                fetchedData={allContactDetails}
                page={page}
                limit={limit}
                setPage={setPage}
                totalData={totalData}
                setLimit={setLimit}
                setSearch={setSearch}
                init={fetchContactHuman}
              />

              {/* <h4 className="page-title text-truncate text-dark font-weight-medium mb-2 pt-4">
                Business Information
              </h4> */}
               <h4 className="page-title text-truncate text-dark font-weight-medium pt-4">
                {isEditing
                  ? "Edit Business Information"
                  : "Add Business Information"}
                <h4 className="text-success text-center pt-2"> {successMessage}</h4>
              </h4>

              <form onSubmit={handleSubmit(onSubmit)}>
                {hasError && (
                  <div className="alert alert-danger text-center">
                    {getGeneralErrorMessage()}
                  </div>
                )}
                <div className="row mb-5">
                  <div className="row mb-5">
                    <div className="container m-4">
                      {/* First Row */}
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="companyName">Company Name</label>
                            <input
                              type="text"
                              maxLength={256}
                              className="form-control"
                              id="companyName"
                              //placeholder="ABC Company"
                              {...register("companyName", {
                                required: true,
                                validate: (value) =>
                                  validateName(value, "name"),
                              })}
                            />
                            {errors.companyName && (
                              <p className="text-danger">
                                {errors.companyName.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input
                              type="text"
                              maxLength={256}
                              //placeholder="John"
                              className="form-control"
                              id="firstName"
                              {...register("firstName", {
                                required: true,
                                validate: (value) =>
                                  validateName(value, "name"),
                              })}
                            />
                            {errors.firstName && (
                              <p className="text-danger">
                                {errors.firstName.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="lastName">LastName/Surname</label>
                            <input
                              type="text"
                              maxLength={256}
                              className="form-control"
                              id="lastName"
                              //placeholder="Smith"
                              {...register("lastName", {
                                required: true,
                                validate: (value) =>
                                  validateName(value, "Title"),
                              })}
                            />
                            {errors.lastName && (
                              <p className="text-danger">
                                {errors.lastName.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <input type="hidden" {...register("fullName")} />

                      {/* Second Row */}
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              maxLength={256}
                              //placeholder="abc@gmail.com"
                              {...register("email", {
                                required: true,
                                validate: validateEmail,
                              })}
                            />
                            {errors.email && (
                              <p className="text-danger">
                                {errors.email.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <Controller
                              name="phone"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <PhoneInput
                                    preferredCountries={"us"}
                                    containerClass="phone-input-container"
                                    value={field.value}
                                    onChange={(phone) => field.onChange(phone)}
                                    onBlur={field.onBlur}
                                    inputProps={{ required: true }}
                                    disableDialCodeAndPrefix={true}
                                    disableFocusAfterCountrySelect={false}
                                    hideDropdown={true}
                                    disableCountryGuess={true}
                                  />
                                  {errors.phone && (
                                    <p className="text-danger">
                                      {errors.phone.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="companyDirectNumber">
                              Company Contact
                            </label>
                            <Controller
                              name="companyDirectNumber"
                              control={control}
                              render={({ field }) => (
                                <div className="form-control-container">
                                  <>
                                    <PhoneInput
                                      preferredCountries={"us"}
                                      containerClass="phone-input-container"
                                      value={field.value}
                                      onChange={(companyDirectNumber) =>
                                        field.onChange(companyDirectNumber)
                                      }
                                      onBlur={field.onBlur}
                                      inputProps={{ required: true }}
                                      disableDialCodeAndPrefix={true}
                                      disableFocusAfterCountrySelect={false}
                                      hideDropdown={true}
                                      disableCountryGuess={true}
                                    />
                                  </>
                                  {errors.companyDirectNumber && (
                                    <p className="text-danger">
                                      {errors.companyDirectNumber.message}
                                    </p>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="employmentLevel">
                              Employee Level
                            </label>
                            {/* <input
                              type="text"
                              className="form-control"
                              id="employmentLevel"
                              //placeholder="Employee Level"
                              maxLength={256}
                              {...register("employmentLevel", {
                                required: true,
                                validate: (value) =>
                                  validateName(value, "employee"),
                              })}
                            /> */}

                            <select
                              className="form-control"
                              id="employmentLevel"
                              {...register("employmentLevel", {
                                required: true,
                              })}
                            >
                              <option value="" disabled>
                                Select employment level
                              </option>
                              <option value="Owner">Owner</option>
                              <option value="Manager">Manager</option>
                              <option value="Executive">Executive</option>
                              <option value="Employee">Employee</option>
                            </select>
                            {errors.employmentLevel && (
                              <p className="text-danger">
                                Employment Level is required
                              </p>
                            )}

                            {errors.employmentLevel && (
                              <p className="text-danger">
                                {errors.employmentLevel.message}
                              </p>
                            )}
                          </div>
                        </div>

                        {employmentLevel === "Owner" && (
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="companyPercentageOwn">
                                Percent Own
                              </label>
                              <div className="row align-items-center">
                                <div className="col-12 input-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="companyPercentageOwn"
                                    //placeholder="75"
                                    {...register("companyPercentageOwn", {
                                      required: true,
                                      validate: validatePercentOwn,
                                      valueAsNumber: true,
                                    })}
                                    max="100"
                                    min="1"
                                    step="1"
                                    onInput={(e) => {
                                      let value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      if (value.length > 3) {
                                        value = value.slice(0, 3);
                                      }
                                      if (Number(value) > 100) {
                                        value = "100";
                                      }
                                      e.target.value = value;
                                    }}
                                  />
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">%</div>
                                  </div>
                                </div>
                                {errors.companyPercentageOwn && (
                                  <p className="text-danger">
                                    {errors.companyPercentageOwn.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {/* Third Row */}
                        {/* <div className="row"> */}
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="companyStreetAddress">
                              Street Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="companyStreetAddress"
                              maxLength={256}
                              //placeholder="1435 Carewood Drive"
                              {...register("companyStreetAddress", {
                                required: true,
                              })}
                            />
                            {errors.companyStreetAddress && (
                              <p className="text-danger">
                                {errors.companyStreetAddress.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="companySuiteNo">Suite #</label>
                            <input
                              type="text"
                              className="form-control"
                              id="companySuiteNo"
                              maxLength={256}
                              //placeholder="STE 200"
                              {...register("companySuiteNo", {
                                required: true,
                                validate: validateSuiteNumber,
                              })}
                            />
                            {errors.companySuiteNo && (
                              <p className="text-danger">
                                {errors.companySuiteNo.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="companyCity">City</label>
                            <input
                              type="text"
                              className="form-control"
                              //placeholder="Amsterdam"
                              maxLength={128}
                              id="companyCity"
                              {...register("companyCity", {
                                required: true,
                                validate: (value) =>
                                  validateName(value, "city"),
                              })}
                            />
                            {errors.companyCity && (
                              <p className="text-danger">
                                {errors.companyCity.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="companyState">State</label>
                            <input
                              type="text"
                              className="form-control"
                              //placeholder="CA"
                              id="companyState"
                              maxLength={128}
                              {...register("companyState", {
                                required: true,
                                validate: (value) =>
                                  validateName(value, "state"),
                              })}
                            />
                            {errors.companyState && (
                              <p className="text-danger">
                                {errors.companyState.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="companyZip">Zip Code</label>
                            <input
                              type="text"
                              className="form-control"
                              id="companyZip"
                              maxLength={5}
                              //placeholder="89521"
                              {...register("companyZip", {
                                required: true,
                                validate: validateZipCode,
                              })}
                            />
                            {errors.companyZip && (
                              <p className="text-danger">
                                {errors.companyZip.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label htmlFor="companyUrl">URL</label>

                            <input
                              type="text"
                              className="form-control"
                              id="companyUrl"
                              maxLength={256}
                              //placeholder="https://www.abccompany.com"
                              {...register("companyUrl", {
                                required: true,
                                validate: validateURL,
                              })}
                            />
                            {errors.companyUrl && (
                              <p className="text-danger">
                                {errors.companyUrl.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* </div> */}

                        {/* Fourth Row */}
                        {/* <div className="row"> */}
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label htmlFor="sicCode">SIC Code</label>

                            <input
                              type="text"
                              className="form-control"
                              id="sicCode"
                              maxLength={4}
                              //placeholder="8457"
                              {...register("sicCode", {
                                required: true,
                                validate: validateSicCode,
                              })}
                            />
                            {errors.sicCode && (
                              <p className="text-danger">
                                {errors.sicCode.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* MAICS Code */}
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label htmlFor="maicsCode">MAICS Code</label>
                            <input
                              type="text"
                              className="form-control"
                              id="maicsCode"
                              maxLength={6}
                              //placeholder="584875"
                              {...register("maicsCode", {
                                required: true,
                                validate: validateMaicsCode,
                              })}
                            />
                            {errors.maicsCode && (
                              <p className="text-danger">
                                {errors.maicsCode.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* GL Code */}
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label htmlFor="glCode">GL Code</label>

                            <input
                              type="text"
                              className="form-control"
                              id="glCode"
                              maxLength={256}
                              //placeholder="A100"
                              {...register("glCode", {
                                required: true,
                                validate: validateGlCode,
                              })}
                            />
                            {errors.glCode && (
                              <p className="text-danger">
                                {errors.glCode.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* Legal Entity Type */}
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label htmlFor="legalEntityType">
                              Legal Entity Type
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="legalEntityType"
                              maxLength={256}
                              //placeholder=""
                              {...register("legalEntityType", {
                                required: true,
                                validate: (value) =>
                                  validateName(value, "Legal Entity Type"),
                              })}
                            />
                            {errors.legalEntityType && (
                              <p className="text-danger">
                                {errors.legalEntityType.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="dateBusinessStarted">
                              Date Business Started
                            </label>
                            <input
                              type="date"
                              maxLength={256}
                              className="form-control"
                              //placeholder="DD/MM/YYYY"
                              max={new Date().toISOString().split("T")[0]}
                              {...register("dateBusinessStarted", {
                                onChange: (e) =>
                                  handleDateChange(
                                    e.target.value,
                                    "dateBusinessStarted"
                                  ),
                              })}
                            />

                            {/* <div className="input-group-prepend">
                                  <div className="px-2">
                                    <Calendar />
                                  </div>
                                </div> */}
                          </div>
                          {errors.dateBusinessStarted && (
                            <p className="text-danger">
                              {errors.dateBusinessStarted.message}
                            </p>
                          )}
                        </div>

                        {/* Tax ID Number */}
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label htmlFor="taxIdNumber">Tax ID Number</label>

                            <input
                              type="text"
                              className="form-control"
                              id="taxIdNumber"
                              maxLength={256}
                              //placeholder="123-45-6789"
                              {...register("taxIdNumber", {
                                required: true,
                                validate: validateNumber,
                              })}
                            />
                            {errors.taxIdNumber && (
                              <p className="text-danger">
                                {errors.taxIdNumber.message}
                              </p>
                            )}
                          </div>
                        </div>

                        {/* Industry */}
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label htmlFor="industry">Industry</label>

                            <input
                              type="text"
                              className="form-control"
                              id="industry"
                              maxLength={256}
                              //placeholder="Construction"
                              {...register("industry", {
                                required: true,
                                validate: (value) =>
                                  validateName(value, "industry"),
                              })}
                            />
                            {errors.industry && (
                              <p className="text-danger">
                                {errors.industry.message}
                              </p>
                            )}
                          </div>
                        </div>

                        {/* Name Of Business */}
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label htmlFor="businessName">
                              Name Of Business
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              id="companyBusinessName"
                              maxLength={256}
                              //placeholder="ABC Construction"
                              {...register("companyBusinessName", {
                                required: true,
                                validate: (value) =>
                                  validateName(value, "business name"),
                              })}
                            />
                            {errors.companyBusinessName && (
                              <p className="text-danger">
                                {errors.companyBusinessName.message}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-10 offset-sm-2">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto img-fluid"
            title="Ads"
            alt="Ads"
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessContactInformation;
