import React, { useState } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-multi-date-picker";

const CompanyCoverageInfo = ({
  errors,
  register,
  fieldShowName,
  setValue,
  control,
}) => {

  const [typeOfCompanyCoverage, setTypeOfCompanyCoverage] = useState("");
  const [howLongCompanyCoverage, setHowLongCompanyCoverage] = useState("");
  const [companyCoverageAmount, setCompanyCoverageAmount] = useState("");



  const handleTypeOfCompanyCoverage = (e) => {
    setTypeOfCompanyCoverage(e.target.value);
    console.log(typeOfCompanyCoverage);
    setHowLongCompanyCoverage("");
  };

  const companyCoverageAmounts = [
    5000, 10000, 15000, 20000, 25000, 30000, 40000, 50000, 60000, 70000, 80000,
    90000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 500000,
    600000, 700000, 800000, 900000, 1000000,
  ];

  const handleCoverageAmount = (e) => {
    setCompanyCoverageAmount(e.target.value);
    setValue("companyCoverageAmounts", e.target.value);
  };

  return (
    <>
      <div className="row my-2">
        <div className="col-7 pt-2">
          <label className="">Name of the company</label>
        </div>
        <div className="col-5">
          <input
            type="text"
            maxLength={256}
            // required
            className="form-control border border-dark"
            id="nameOfCompany"
            {...register("nameOfCompany", {
              required: false,
            })}
          />
        </div>
      </div>

      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="typeOfCompanyCoverage">Type of Coverage:</label>
        </div>
        <div className="col-5">
          <select
            className="form-control border border-dark"
            id="typeOfCompanyCoverage"
            {...register("typeOfCompanyCoverage")}
            value={typeOfCompanyCoverage}
            onChange={handleTypeOfCompanyCoverage}
          >
            <option value="">Select Type</option>
            <option value="Whole Life">Whole Life</option>
            <option value="IUL">IUL</option>
            <option value="UL">UL</option>
            <option value="Final expense">Final expense</option>
          </select>
          {errors.typeOfCompanyCoverage && (
            <p className="text-danger">{errors.typeOfCompanyCoverage.message}</p>
          )}
        </div>
      </div>

      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">How Long is the Coverage Guaranteed:</label>
        </div>
        <div className="col-5">
          <select
            className="form-control border border-dark"
            id="howLongCompanyCoverageGuaranteed"
            {...register("howLongCompanyCoverageGuaranteed")}
            value={howLongCompanyCoverage}
            onChange={(e) => setHowLongCompanyCoverage(e.target.value)}
          >
            <option value="">Select Duration</option>
            <option value="5">5 Years</option>
            <option value="10">10 Years</option>
            <option value="15">15 Years</option>
            <option value="20">20 Years</option>
            <option value="25">25 Years</option>
            <option value="30">30 Years</option>
            <option value="35">35 Years</option>
            <option value="40">40 Years</option>
          </select>
        </div>
      </div>

      <div className="row my-2">
        <div className="col-7 pt-2">
          <label>Plan Effective Date</label>
        </div>
        <div className="col-5">
          <Controller
            control={control}
            name="planEffectiveDate"
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onChange={field.onChange}
                format="DD/MM/YYYY"
                className="form-control custom-date-picker"
                calendarPosition="bottom-center"
                selected={field.value ? new Date(field.value) : null}
                maxDate={new Date()}
              />
            )}
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col-7 pt-2">
          <label htmlFor="yearsInPlan" className="">
            Years in Plan
          </label>
        </div>
        <div className="col-5 d-flex align-items-center">
          <input
            type="text"
            maxLength={256}
            className="form-control border border-dark "
            
            max={new Date().toISOString().split("T")[0]}
            id={"yearsInPlan"}
            {...register("yearsInPlan")}
          />

          <div className="input-group-text">yrs.</div>
        </div>
      </div>

      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">Monthly Premium of Plan:</label>
        </div>
        <div className="col-5 input-group">
          <span className="input-group-text">$</span>

          <input
            type="number"
            maxLength={256}
            // required
            className="form-control border border-dark"
            id="monthlyPremium"
            {...register("monthlyPremium", {
              required: false,
            })}
          />
        </div>
      </div>

      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">Coverage Amount</label>
        </div>
        <div className="col-5">
          <input
            list="companyCoverageAmounts"
            className="form-control border border-dark"
            id="companyCoverageAmount"
            {...register("companyCoverageAmount")}
            value={companyCoverageAmount}
            onChange={handleCoverageAmount}
          />
          <datalist id="companyCoverageAmounts">
            {companyCoverageAmounts.map((amount) => (
              <option key={amount} value={amount}>
                {amount}
              </option>
            ))}
          </datalist>
        </div>
      </div>

      {(typeOfCompanyCoverage === "Whole Life" || typeOfCompanyCoverage === "IUL") && (
        <div className="row my-2 pt-4">
          <div className="col-7 pt-2">
            <label className="">
              How Much Cash Value Do You Have In Your Policy?
            </label>
          </div>
          <div className="col-5 input-group">
            <span className="input-group-text">$</span>
            <input
              type="text"
              className="form-control border border-dark"
              id="cashValueInPolicy"
              {...register("cashValueInPolicy")}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyCoverageInfo;
