import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContactPersonalSubHeader from "./ContactPersonalSubHeader";
import ContactBusinessSubHeader from "./ContactBusinessSubHeader";
import ContactLeadSubHeader from "./ContactLeadSubHeader";

const ContactHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="col-12 business-contact">
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link btn-lg active"
            onClick={() => navigate("/contact/personal")}
            id="pills-home-tab"
            data-toggle="pill"
            data-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Personal
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link btn-lg"
            onClick={() => navigate("/contact/business")}
            id="pills-profile-tab"
            data-toggle="pill"
            data-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Business
          </button>
        </li>
        {/* <li className="nav-item" role="presentation">
          <button
            onClick={() => navigate("/contact/lead")}
            className="nav-link btn-lg"
            id="pills-lead-tab"
            data-toggle="pill"
            data-target="#pills-lead"
            type="button"
            role="tab"
            aria-controls="pills-lead"
            aria-selected="false"
          >
            Lead
          </button>
        </li> */}
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <ContactPersonalSubHeader />
        <ContactBusinessSubHeader/>
        {/* <ContactLeadSubHeader/> */}
      </div>
    </div>
  );
};

export default ContactHeader;
