import React from 'react'
import Modal from 'react-bootstrap/Modal';

const EditPermission = ({setEditPermissionModal,editPermissionModal}) => {

const handleEditPermissionClose=()=>{
  setEditPermissionModal(false)
}



  return (
  

      <Modal
      className="modal fade"
      id="editPermissionPopup"
      tabIndex={-1}
      size="xl"
      show={editPermissionModal} onHide={handleEditPermissionClose}
    >
  
      <div className="modal-header">
        <h4
          className="modal-title text-dark font-weight-medium"
          id="exampleModalLabel"
        >
          User Permission
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleEditPermissionClose}

        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="table-responsive mb-4">
          <table className="table table-striped display no-wrap w-100">
            <thead>
              <tr>
                <th
                  style={{ width: "60%" }}
                  className="font-weight-bold text-black"
                >
                  User Last Name, First Name
                </th>
                <th
                  style={{ width: "40%" }}
                  className="font-weight-bold text-black"
                >
                  Permission
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Brown, David</td>
                <td>
                  <select className="form-control">
                    <option>Select</option>
                    <option>Administrator</option>
                    <option>User</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row text-black">
          <div className="col-lg-8 mb-4">
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-9">
                    <label className="font-weight-bold h4">
                      Contact Center
                    </label>
                  </div>
                  <div className="col-3">
                    <div className="toggle_medium_switch">
                      <label htmlFor="ContactCenterp">
                        <input type="checkbox" id="ContactCenterp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-9">Barge-n</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="Bargep">
                        <input type="checkbox" id="Bargep" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">Call Monitoring</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="CallMonitoringp">
                        <input type="checkbox" id="CallMonitoringp"/>
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">Call Recording</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="CallRecordingp">
                        <input type="checkbox" id="CallRecordingp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">Call Transfer</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="CallTransferp">
                        <input type="checkbox" id="CallTransferp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">Call Waiting</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="CallWaitingp">
                        <input type="checkbox" id="CallWaitingp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">Call Whispering</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="CallWhisperingp">
                        <input type="checkbox" id="CallWhisperingp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">Conferenong</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="Conferenongp">
                        <input type="checkbox" id="Conferenongp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">Chat</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="Chatp">
                        <input type="checkbox" id="Chatp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-9">Do Not Disturb</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="DoNotDisturbp">
                        <input type="checkbox" id="DoNotDisturbp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">
                    Email{" "}
                    <a href="#!" className="ml-3">
                      create
                    </a>
                  </div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="Emailp">
                        <input type="checkbox" id="Emailp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">
                    Fax{" "}
                    <a href="#!" className="ml-3">
                      create
                    </a>
                  </div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="Faxp">
                        <input type="checkbox" id="Faxp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">File Sharing</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="FileSharingp">
                        <input type="checkbox" id="FileSharingp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">
                    Phone{" "}
                    <a href="#!" className="ml-3">
                      create
                    </a>
                  </div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="Phonep">
                        <input type="checkbox" id="Phonep" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">SMS</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="SMSp">
                        <input type="checkbox" id="SMSp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">Text</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="Textp">
                        <input type="checkbox" id="Textp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
                <div className="row">
                  <div className="col-9">Video Conferencing</div>
                  <div className="col-3">
                    <div className="toggle_small_switch">
                      <label htmlFor="VideoConferencingp">
                        <input type="checkbox" id="VideoConferencingp" />
                        <span>
                          <small />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*/Row*/}
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-4">
            <div className="row">
              <div className="col-9">
                <label className="font-weight-bold h4">Marketing</label>
              </div>
              <div className="col-3">
                <div className="toggle_medium_switch">
                  <label htmlFor="Marketingp">
                    <input type="checkbox" id="Marketingp" />
                    <span>
                      <small />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/*/Row*/}
            <div className="row">
              <div className="col-9">Direct Mail</div>
              <div className="col-3">
                <div className="toggle_small_switch">
                  <label htmlFor="DirectMailp">
                    <input type="checkbox" id="DirectMailp" />
                    <span>
                      <small />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/*/Row*/}
            <div className="row">
              <div className="col-9">Email</div>
              <div className="col-3">
                <div className="toggle_small_switch">
                  <label htmlFor="mEmailp">
                    <input type="checkbox" id="mEmailp" />
                    <span>
                      <small />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/*/Row*/}
            <div className="row">
              <div className="col-9">Facebook</div>
              <div className="col-3">
                <div className="toggle_small_switch">
                  <label htmlFor="Facebookp">
                    <input type="checkbox" id="Facebookp" />
                    <span>
                      <small />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/*/Row*/}
            <div className="row">
              <div className="col-9">Google</div>
              <div className="col-3">
                <div className="toggle_small_switch">
                  <label htmlFor="Googlep">
                    <input type="checkbox" id="Googlep" />
                    <span>
                      <small />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/*/Row*/}
            <div className="row">
              <div className="col-9">Instagram</div>
              <div className="col-3">
                <div className="toggle_small_switch">
                  <label htmlFor="Instagramp">
                    <input type="checkbox" id="Instagramp" />
                    <span>
                      <small />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/*/Row*/}
            <div className="row">
              <div className="col-9">Linkedin</div>
              <div className="col-3">
                <div className="toggle_small_switch">
                  <label htmlFor="Linkedinp">
                    <input type="checkbox" id="Linkedinp" />
                    <span>
                      <small />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/*/Row*/}
            <div className="row">
              <div className="col-9">SMS/Text</div>
              <div className="col-3">
                <div className="toggle_small_switch">
                  <label htmlFor="SMSTextp">
                    <input type="checkbox" id="SMSTextp" />
                    <span>
                      <small />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/*/Row*/}
            <div className="row">
              <div className="col-9">Twitter</div>
              <div className="col-3">
                <div className="toggle_small_switch">
                  <label htmlFor="Twitterp">
                    <input type="checkbox" id="Twitterp" />
                    <span>
                      <small />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {/*/Row*/}
          </div>
        </div>
      </div>
   

    </Modal>
  

  )
}

export default EditPermission




// import React, { useState } from 'react'
// import Modal from 'react-bootstrap/Modal';

// const EditPermission = ({setEditPermissionModal,editPermissionModal}) => {

//   const handleEditPermissionClose = () => {
//     setEditPermissionModal(false);
//   };

//   // State to manage checkbox values
//   const [checkboxes, setCheckboxes] = useState({
//     ContactCenterp: false,
//     Bargep: false,
//     CallMonitoringp: false,
//     CallRecordingp: false,
//     CallTransferp: false,
//     CallWaitingp: false,
//     CallWhisperingp: false,
//     Conferenongp: false,
//     Chatp: false,
//     DoNotDisturbp: false,
//     Emailp: false,
//     Faxp: false,
//     FileSharingp: false,
//     Phonep: false,
//     SMSp: false,
//     Textp: false,
//     VideoConferencingp: false,
//     Marketingp: false,
//     DirectMailp: false,
//     mEmailp: false,
//     Facebookp: false,
//     Googlep: false,
//     Instagramp: false,
//     Linkedinp: false,
//     SMSTextp: false,
//     Twitterp: false,
//   });

//   const handleCheckboxChange = (event) => {
//     const { id, checked } = event.target;
//     setCheckboxes((prevCheckboxes) => ({
//       ...prevCheckboxes,
//       [id]: checked,
//     }));
//   };
//   return (
  

//       <Modal
//       className="modal fade"
//       id="editPermissionPopup"
//       tabIndex={-1}
//       size="xl"
//       show={editPermissionModal} onHide={handleEditPermissionClose}
//     >
  
//       <div className="modal-header">
//         <h4
//           className="modal-title text-dark font-weight-medium"
//           id="exampleModalLabel"
//         >
//           User Permission
//         </h4>
//         <button
//           type="button"
//           className="close"
//           data-dismiss="modal"
//           aria-label="Close"
//           onClick={handleEditPermissionClose}

//         >
//           <span aria-hidden="true">×</span>
//         </button>
//       </div>
//       <div className="modal-body">
//         <div className="table-responsive mb-4">
//           <table className="table table-striped display no-wrap w-100">
//             <thead>
//               <tr>
//                 <th
//                   style={{ width: "60%" }}
//                   className="font-weight-bold text-black"
//                 >
//                   User Last Name, First Name
//                 </th>
//                 <th
//                   style={{ width: "40%" }}
//                   className="font-weight-bold text-black"
//                 >
//                   Permission
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>Brown, David</td>
//                 <td>
//                   <select className="form-control">
//                     <option>Select</option>
//                     <option>Administrator</option>
//                     <option>User</option>
//                   </select>
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//         <div className="row text-black">
//           <div className="col-lg-8 mb-4">
//             <div className="row">
//               <div className="col-sm-6">
//                 <div className="row">
//                   <div className="col-9">
//                     <label className="font-weight-bold h4">
//                       Contact Center
//                     </label>
//                   </div>
//                   <div className="col-3">
//                     <div className="toggle_medium_switch">
//                       <label htmlFor="ContactCenterp">
//                         <input type="checkbox" id="ContactCenterp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-sm-6">
//                 <div className="row">
//                   <div className="col-9">Barge-n</div>
//                   <div className="col-3">
//             <div className="toggle_small_switch">
//               <label htmlFor="Bargep">
//                 <input
//                   type="checkbox"
//                   id="Bargep"
//                   // checked={checkboxes.Bargep}
//                   onChange={handleCheckboxChange}
//                 />
//                 <span>
//                   <small />
//                 </span>
//               </label>
//             </div>
            
//           </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">Call Monitoring</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="CallMonitoringp">
//                         <input type="checkbox" id="CallMonitoringp" checked={checkboxes.CallMonitoringp} onChange={handleCheckboxChange} />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">Call Recording</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="CallRecordingp">
//                         <input type="checkbox" id="CallRecordingp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">Call Transfer</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="CallTransferp">
//                         <input type="checkbox" id="CallTransferp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">Call Waiting</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="CallWaitingp">
//                         <input type="checkbox" id="CallWaitingp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">Call Whispering</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="CallWhisperingp">
//                         <input type="checkbox" id="CallWhisperingp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">Conferenong</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="Conferenongp">
//                         <input type="checkbox" id="Conferenongp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">Chat</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="Chatp">
//                         <input type="checkbox" id="Chatp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//               </div>
//               <div className="col-sm-6">
//                 <div className="row">
//                   <div className="col-9">Do Not Disturb</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="DoNotDisturbp">
//                         <input type="checkbox" id="DoNotDisturbp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">
//                     Email{" "}
//                     <a href="#!" className="ml-3">
//                       create
//                     </a>
//                   </div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="Emailp">
//                         <input type="checkbox" id="Emailp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">
//                     Fax{" "}
//                     <a href="#!" className="ml-3">
//                       create
//                     </a>
//                   </div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="Faxp">
//                         <input type="checkbox" id="Faxp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">File Sharing</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="FileSharingp">
//                         <input type="checkbox" id="FileSharingp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">
//                     Phone{" "}
//                     <a href="#!" className="ml-3">
//                       create
//                     </a>
//                   </div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="Phonep">
//                         <input type="checkbox" id="Phonep" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">SMS</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="SMSp">
//                         <input type="checkbox" id="SMSp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">Text</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="Textp">
//                         <input type="checkbox" id="Textp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//                 <div className="row">
//                   <div className="col-9">Video Conferencing</div>
//                   <div className="col-3">
//                     <div className="toggle_small_switch">
//                       <label htmlFor="VideoConferencingp">
//                         <input type="checkbox" id="VideoConferencingp" />
//                         <span>
//                           <small />
//                         </span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/*/Row*/}
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-4 mb-4">
//             <div className="row">
//               <div className="col-9">
//                 <label className="font-weight-bold h4">Marketing</label>
//               </div>
//               <div className="col-3">
//                 <div className="toggle_medium_switch">
//                   <label htmlFor="Marketingp">
//                     <input type="checkbox" id="Marketingp" />
//                     <span>
//                       <small />
//                     </span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             {/*/Row*/}
//             <div className="row">
//               <div className="col-9">Direct Mail</div>
//               <div className="col-3">
//                 <div className="toggle_small_switch">
//                   <label htmlFor="DirectMailp">
//                     <input type="checkbox" id="DirectMailp" />
//                     <span>
//                       <small />
//                     </span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             {/*/Row*/}
//             <div className="row">
//               <div className="col-9">Email</div>
//               <div className="col-3">
//                 <div className="toggle_small_switch">
//                   <label htmlFor="mEmailp">
//                     <input type="checkbox" id="mEmailp" />
//                     <span>
//                       <small />
//                     </span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             {/*/Row*/}
//             <div className="row">
//               <div className="col-9">Facebook</div>
//               <div className="col-3">
//                 <div className="toggle_small_switch">
//                   <label htmlFor="Facebookp">
//                     <input type="checkbox" id="Facebookp" />
//                     <span>
//                       <small />
//                     </span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             {/*/Row*/}
//             <div className="row">
//               <div className="col-9">Google</div>
//               <div className="col-3">
//                 <div className="toggle_small_switch">
//                   <label htmlFor="Googlep">
//                     <input type="checkbox" id="Googlep" />
//                     <span>
//                       <small />
//                     </span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             {/*/Row*/}
//             <div className="row">
//               <div className="col-9">Instagram</div>
//               <div className="col-3">
//                 <div className="toggle_small_switch">
//                   <label htmlFor="Instagramp">
//                     <input type="checkbox" id="Instagramp" />
//                     <span>
//                       <small />
//                     </span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             {/*/Row*/}
//             <div className="row">
//               <div className="col-9">Linkedin</div>
//               <div className="col-3">
//                 <div className="toggle_small_switch">
//                   <label htmlFor="Linkedinp">
//                     <input type="checkbox" id="Linkedinp" />
//                     <span>
//                       <small />
//                     </span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             {/*/Row*/}
//             <div className="row">
//               <div className="col-9">SMS/Text</div>
//               <div className="col-3">
//                 <div className="toggle_small_switch">
//                   <label htmlFor="SMSTextp">
//                     <input type="checkbox" id="SMSTextp" />
//                     <span>
//                       <small />
//                     </span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             {/*/Row*/}
//             <div className="row">
//               <div className="col-9">Twitter</div>
//               <div className="col-3">
//                 <div className="toggle_small_switch">
//                   <label htmlFor="Twitterp">
//                     <input type="checkbox" id="Twitterp" />
//                     <span>
//                       <small />
//                     </span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             {/*/Row*/}
//           </div>
//         </div>
//       </div>
   

//     </Modal>
  

//   )
// }
// export default EditPermission;