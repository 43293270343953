import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import appConstant from "./../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
const HierarchyUserPermissions = forwardRef((props, ref) => {
  // You can define methods here if needed and expose them through the ref
  const { jsonApi } = useApis();

  useImperativeHandle(ref, () => ({
    handleSubmit: (param) => props.submitForm({}, param)(),
  }));


  const [currentUser, setCurrentUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(5);
  const [checkedPermissions, setCheckedPermissions] = useState(
    currentUser.permission ? currentUser.permission : []
  );

  useEffect(() => {
    if (currentUser?.permission) {
      setCheckedPermissions(currentUser?.permission);
    }
  }, [currentUser?.permission]);

  const handleCheckboxChange = (permission) => {
    setCheckedPermissions((prev) =>
      prev.includes(permission)
        ? prev.filter((item) => item !== permission)
        : [...prev, permission]
    );
  };

  const filterUser =
    props?.data?.users.filter((user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const totalData = filterUser.length;
  const totalPages = Math.ceil(filterUser.length / limit);
  const startIndex = (currentPage - 1) * limit;
  const endIndex = Math.min(startIndex + limit, totalData);
  const currentUser1 = filterUser.slice(startIndex, startIndex + limit) || [];

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleSubmit = async () => {
    const res = await jsonApi(
      appConstant.updateUserPermission,
      null,
      {
        permission: checkedPermissions,
        type: currentUser.type,
        _id: currentUser._id,
      },
      false
    );
    setCurrentUser({});
    // You can send the checkedPermissions array to your server or use it as needed
  };

  return (
    <div className="my-5 w-100">
      <label className="font-weight-bold mb-4">User Permission</label>
      <div className="row mb-4">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length">
            <label
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              Show
              <select
                className="form-control-sm"
                value={limit}
                onChange={(e) => {
                  setLimit(Number(e.target.value));
                  setCurrentPage(1); // Reset to first page on limit change
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              entries
            </label>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_filter">
            <label>
              Search:
              <input
                id="search-input"
                type="text"
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control-sm"
                style={{
                  width: "200px",
                  padding: "6px",
                  boxSizing: "border-box",
                  marginLeft: "8px",
                }}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table display no-wrap w-100 user_info_table">
          <thead>
            <tr>
              <th colSpan={3}>
                <a href="#!">{props?.data?.contactName} </a>
                <i>(Super Administrator)</i>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {props?.data?.users.map((user) => ( */}
            {currentUser1.map((user) => (
              <tr>
                <td />
                <td>
                  <a href="#!">{user.name}</a>
                </td>

                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-success green_light_btn"
                    data-toggle="modal"
                    data-target="#editPermissionPopup"
                    onClick={() => setCurrentUser(user)}
                  >
                    Edit Permission
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="dataTables_info" role="status" aria-live="polite">
              {/* Showing Page {currentPage} of {totalPages} */}
              Showing {(currentPage - 1) * limit + 1} to {endIndex} of{" "}
              {totalData} entries
              {/* {Math.min(currentPage * limit, totalData)} of {totalData} entries */}
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="dataTables_paginate">
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="page-link"
                  >
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => {
                  const number = index + 1;
                  return (
                    <li
                      key={number}
                      className={`page-item ${
                        number === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(number)}
                      >
                        {number}
                      </button>
                    </li>
                  );
                })}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="page-link"
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="editPermissionPopup"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4
                className="modal-title text-dark font-weight-medium"
                id="exampleModalLabel"
              >
                User Permission
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="table-responsive mb-4">
                <table className="table table-striped display no-wrap w-100">
                  <thead>
                    <tr>
                      <th
                        style={{ width: "60%" }}
                        className="font-weight-bold text-black"
                      >
                        User Last Name, First Name
                      </th>
                      <th
                        style={{ width: "40%" }}
                        className="font-weight-bold text-black"
                      >
                        Permission
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{currentUser?.name}</td>
                      <td>
                        <select
                          className="form-control"
                          value={currentUser?.type}
                          onChange={(e) =>
                            setCurrentUser({
                              ...currentUser,
                              type: e.target.value,
                            })
                          }
                        >
                          <option value={"Employee"}>Employee</option>
                          <option value={"Executive"}>Executive</option>
                          <option value={"Manager"}>Manager</option>
                          <option value={"Owner"}>Owner</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row text-black">
                <div className="col-lg-8 mb-4">
                  <div className="row">
                    <div className="col-sm-6">
                      {props?.data?.corporatePermissions.map((permission) => (
                        <div className="row" key={permission}>
                          <div className="col-9">
                            <label className="font-weight-bold h4">
                              {permission.replace(/([A-Z])/g, " $1").trim()}
                            </label>
                          </div>
                          <div className="col-3">
                            <div className="toggle_small_switch">
                              <label htmlFor={`${permission}p`}>
                                <input
                                  type="checkbox"
                                  id={`${permission}p`}
                                  checked={checkedPermissions.includes(
                                    permission
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(permission)
                                  }
                                />
                                <span>
                                  <small />
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                className="next-step btn_green "
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HierarchyUserPermissions;
