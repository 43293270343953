import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";

const SubscriptionDetail = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const { jsonApi } = useApis();
  const [fetchData, setFetchData] = useState([]);


  console.log(id);

  const getCompanyId = async () => {
    if (id) {
      try {
        const companyData = await jsonApi(
          appConstant.getCompanyById,
          { id },
          null,
          true
        );

        console.log(companyData);
        if (!companyData.error && companyData.data) {
          setFetchData(companyData.data);
          setError(companyData.message || "Something went wrong");
        }
      } catch (err) {
        setError("An error occurred while fetching company data.");
        console.error(err);
      }
    }
  };
  useEffect(() => {
    getCompanyId();
  }, [id]);

  return (
    <>
      <div className="page-breadcrumb">
        <div className="row">
          <div className="col-12 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
              Company Detail{" "}
            </h3>
          </div>
        </div>
        
      </div>

      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row">
                      <h3 style={{ color: "black" }}>Company Details</h3>
                    </div>
                    <div className="row justify-content-between p-2" style={{ color: "black" }}>
                   <div className="col-md-3">
                        Name:- {fetchData.companyName}
                      </div>
                   <div className="col-md-3">
                        Address:- {fetchData.companyAddress}
                      </div>
                   <div className="col-md-3">
                        Url:- {fetchData.companyUrl}
                      </div>
                   <div className="col-md-3">Email:- {fetchData.email}</div>
                   <div className="col-md-3">Type of Industry:- {fetchData.typeOfIndustry}</div>
                   <div className="col-md-3">UserName:-{fetchData.userName}</div>
                   <div className="col-md-3">Products Offered:-
                        {fetchData.productsOffered}
                      </div>
                   <div className="col-md-3">
                        {" "}
                        {fetchData.corporatePermissions}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row justify-content-between p-2">
                      <h3 style={{ color: "black" }}>Address Details</h3>
                    </div>
                    <div className="row" style={{ color: "black" }}>
                   <div className="col-md-3">City:- {fetchData.city}</div>
                   <div className="col-md-3">State:- {fetchData.State}</div>
                   <div className="col-md-3">Zip:- {fetchData.zip}</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row justify-content-between p-2">
                      <h3 style={{ color: "black" }}>Contact Details</h3>
                    </div>
                    <div className="row" style={{ color: "black" }}>
                   <div className="col-md-3">
                        Contact Name:- {fetchData.contactName}{" "}
                      </div>
                   <div className="col-md-3">
                        Contact Email:- {fetchData.contactEmail}
                      </div>
                   <div className="col-md-3">
                        Corporate Phone:- {fetchData.corporatePhone}
                      </div>
                   <div className="col-md-3">
                        Corporate Fax:- {fetchData.corporateFax}
                      </div>
                   <div className="col-md-3">
                        Contact Extension:-{fetchData.contactExtension}
                      </div>
                   <div className="col-md-3">
                        Cell Number:- {fetchData.cellNumber}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row justify-content-between p-2">
                      <h3 style={{ color: "black" }}>Bank Details </h3>
                    </div>
                    <div className="row" style={{ color: "black" }}>
                   <div className="col-md-3">
                        Bank Account:-{" "}
                        {fetchData.bankAccount &&
                        fetchData.bankAccount.length > 0
                          ? fetchData.bankAccount
                          : "Not Available"}
                      </div>
                   <div className="col-md-3">
                        Bank Card:-{" "}
                        {fetchData.bankCard && fetchData.bankCard.length > 0
                          ? fetchData.bankCard
                          : "Not Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              title="Ads"
              alt="Ads"
            />
          </div>
        </div>
      </div> */}

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div
              className="card mb-3 shadow-sm"
              style={{ borderRadius: "8px" }}
            >
              <div className="card-header bg-primary text-white">
                <h3 className="mb-0">Company Profile</h3>
              </div>

              <div className="card-body">
                <section className="mb-4">
                  <h4 className="text-primary border-bottom pb-2 mb-3">
                    <i className="bi bi-building me-2"></i>Company Details
                  </h4>
                  <div className="row text-secondary">
                    {[
                      { label: "Name", value: fetchData.companyName },
                      { label: "Address", value: fetchData.companyAddress },
                      { label: "URL", value: fetchData.companyUrl },
                      { label: "Email", value: fetchData.email },
                      {
                        label: "Industry Type",
                        value: fetchData.typeOfIndustry,
                      },
                      { label: "User Name", value: fetchData.userName },
                      // {
                      //   label: "Products Offered",
                      // },
                      // {
                      //   label: "Permissions",
                      //   value: fetchData.corporatePermissions ? fetchData.corporatePermissions.join(", ") : "N/A",
                      // },
                    
                     
                    ].map((item, index) => (
                      <div key={index} className="col-md-6 mb-2">
                        <div className="d-flex justify-content-between text-nowrap">
                          <strong>{item.label}:</strong>
                          <span>{item.value || "N/A"}</span>   
                        </div>
                      </div> 
                    ))}
                  <div classNmae="d-flex justify-content-between text-nowrap">
                <strong className="px-3">Permissions:</strong>
                  <span className="px-4">{fetchData.corporatePermissions ? fetchData.corporatePermissions.join(", ") : "N/A"}</span></div>
                </div>
              
                </section>

                <section className="mb-4">
                  <h4 className="text-primary border-bottom pb-2 mb-3">
                    <i className="bi bi-geo-alt me-2"></i>Address Details
                  </h4>
                  <div className="row text-secondary">
                    {[
                      { label: "City", value: fetchData.city },
                      { label: "State", value: fetchData.State },
                      { label: "Zip", value: fetchData.zip },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="col-md-4 mb-2 d-flex text-nowrap"
                      >
                        <strong className="me-2">{item.label}:</strong>
                        <span>{item.value || "N/A"}</span>
                      </div>
                    ))}
                  </div>
                </section>

                <section className="mb-4">
                  <h4 className="text-primary border-bottom pb-2 mb-3">
                    <i className="bi bi-person-lines-fill me-2"></i>Contact
                    Details
                  </h4>
                  <div className="row text-secondary">
                    {[
                      { label: "Contact Name", value: fetchData.contactName },
                      { label: "Contact Email", value: fetchData.contactEmail },
                      {
                        label: "Corporate Phone",
                        value: fetchData.corporatePhone,
                      },
                      { label: "Corporate Fax", value: fetchData.corporateFax },
                      {
                        label: "Contact Extension",
                        value: fetchData.contactExtension,
                      },
                      { label: "Cell Number", value: fetchData.cellNumber },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="col-md-4 mb-2 d-flex text-nowrap"
                      >
                        <strong className="me-2">{item.label}:</strong>
                        <span>{item.value || "N/A"}</span>
                      </div>
                    ))}
                  </div>
                </section>

                <section className="mb-4">
                  <h4 className="text-primary border-bottom pb-2 mb-3">
                    Bank Details
                  </h4>
                  <div className="row text-secondary">
                    {[
                      {
                        label: "Bank Account",
                        value:
                          fetchData.bankAccount?.length > 0
                            ? fetchData.bankAccount
                            : "Not Available",
                      },
                      {
                        label: "Bank Card",
                        value:
                          fetchData.bankCard?.length > 0
                            ? fetchData.bankCard
                            : "Not Available",
                      },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="col-md-6 mb-2 d-flex text-nowrap"
                      >
                        <strong className="me-2">{item.label}:</strong>
                        <span>{item.value}</span>
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
              <img
                src="/assets/images/banner/ads.jpg"
                className="mx-auto d-block img-fluid"
                alt=""
                title="Ads"
              />
            </div>
        </div>
      </div>
      
    </>
  );
};

export default SubscriptionDetail;


// {
//   label: "Permissions",
//   value: Array.isArray(fetchData.corporatePermissions)
//     ? fetchData.corporatePermissions
//         .slice(0, 3)
//         .join(", ") +
//       " " +
//       (fetchData.corporatePermissions.length > 3
//         ? "..."
//         : "")
//     : "N/A",
// },

// {Array.isArray(fetchData.corporatePermissions) &&
//   fetchData.corporatePermissions.length > 3 && (
//     <div className="mt-2">
//       <button
//         className="btn btn-link p-0"
//         onClick={handleTogglePermissions}
//       >
//         {showAllPermissions ? "See Less" : "See All"}
//       </button>
//       {showAllPermissions && (
//         <div className="mt-2 p-2 border">
//           {fetchData.corporatePermissions.join(", ")}
//         </div>
//       )}
//     </div>
//   )}





// const [showAllPermissions, setShowAllPermissions] = useState(false);

// const handleSeeMoreClick = () => {
//   setShowAllPermissions((prev) => !prev);
// };

// const displayedPermissions = showAllPermissions
//   ? fetchData.corporatePermissions
//   : fetchData.corporatePermissions?.slice(0, 3) || [];


// {fetchData.corporatePermissions?.length > 3 && (
// <div className="mt-2">
// <span
// className="text-primary cursor-pointer"
// onClick={handleSeeMoreClick}
// >
// {showAllPermissions ? "See less" : "See more"}
// </span>
// </div>
// )}