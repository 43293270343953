import React from "react";

const HierarchyTopBar = ({ index, setIndex, hierarchyTopBarData }) => {
  return (
    <ul className="nav nav-tabs justify-content-center mb-3" role="tablist">
      {hierarchyTopBarData.map((item, itemIndex) => (
        <li
          role="presentation"
          className={index > itemIndex + 1 ? "done" : ""}
          key={itemIndex + 1}
        >
          <a
            href={`step${itemIndex + 1}`}
            onClick={() =>
              index < itemIndex + 1 ? true : setIndex(itemIndex + 1)
            }
            className={
              index === itemIndex + 1
                ? "active"
                : index < itemIndex + 1
                ? "disabled"
                : ""
            }
            data-toggle="tab"
            aria-controls={`step${itemIndex + 1}`}
            role="tab"
            aria-expanded="true"
            data-step={itemIndex + 1}
          >
            <span className="round-tab">{itemIndex + 1}</span>
            <strong
              dangerouslySetInnerHTML={{
                __html: item.name,
              }}
            />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default HierarchyTopBar;
