import { useForm, useFieldArray } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";

import React, { useContext, useEffect, useState } from "react";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";
import { CallCenterContext } from "../../context/CallCenterContext";
import { useNavigate, useParams } from "react-router-dom";
import { PhoneOff } from "react-feather";
import LeadField from "./LeadField";
import medicareInputField from "./../../json/medicareInputField.json";
import YesNoField from "./YesNoField";
import ArrayDoubleFieldPhone from "./ArrayDoubleFieldPhone";
import ArrayDoubleFieldEmail from "./ArrayDoubleFieldEmail";
import DateLead from "./DateLead";
import SocialSecurityNumber from "./SocialSecurityNumber";
import MBIField from "./MBIField";
import MedicaidId from "./MedicaidId";
import YearsField from "./YearsField";
import AddressField from "./AddressField";
import MedicationInformation from "./MedicationInformation";
import AccountNumberField from "./AccountNumberField";
import MedicareBeneficiaryEnrollmentDateField from "./MedicareBeneficiaryEnrollmentDateField";
import BeneficiaryPlanEnrollmentInformation from "./BeneficiaryPlanEnrollmentInformation";
import QuoteField from "./QuoteField";
import NoteField from "./NoteField";
import BeneficiaryUncoveredMonthsNotEnrolled from "./BeneficiaryUncoveredMonthsNotEnrolled";
import ElectionTypeCodeUsage from "./ElectionTypeCodeUsage";
import RoutingNumber from "./RoutingNumber";
import LowIncomeSubsidy from "./LowIncomeSubsidy";
import MedicaidEligibilityLevel from "./MedicaidEligibilityLevel";
import MailAddress from "./MailAddress";
import POARelationship from "./POARelationship";

const UpdateLeadForm = ({ id }) => {
  const { jsonApi } = useApis();
  // const { id } = useParams();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [dob, setDob] = useState(null);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      addNotes: [{ noteDate: "", noteDetails: "" }],
      medicationsBeneficiaryEnrollmentDate: [
        { part: "", startDate: "", endDate: "", option: "" },
      ],
      beneficiaryPlanEnrollmentInformation: [
        {
          contract: "",
          pbp: "",
          planTypeCode: "",
          description: "",
          startDate: "",
          endDate: "",
          drugPlan: "",
        },
      ],
      beneficiaryUncoveredMonthsNotEnrolled: [
        {
          startDate: "",
          incrementalUncoveredMonths: "",
          cumulativeUncoveredMonths: "",
        },
      ],
      lowIncomeSubsidys: [
        {
          subsidyStartDate: "",
          subsidyEndDate: "",
          premiumSubsidyLevel: "",
          coPaymentLevel: "",
          subsidySource: "",
        },
      ],
      medicationInformations: [
        { nameOfMed: "", quantity: "", dosage: "", reason: "" },
      ],
    },
  });

  const { setPicked, handleEndCall, inCall, callDuration } =
    useContext(CallCenterContext);

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: id },
      {},
      false
    );
    console.log(res);

    if (!res?.error) {
      setData(res.data.data);
      populateForm(res.data.data);
    }
  };
  useEffect(() => {
    init();
  }, [id]);

  const onSubmit = async (data) => {
    const dataWithUserId = {
      ...data,
      id: id,
    };

    // Make API call to update user data
    const response = await jsonApi(
      appConstant.updateLeadMedicare,
      { id: id }, // Ensure 'id' is in the request URL or query
      dataWithUserId // Send the form data
    );

    console.log("Response:", response);

    if (!response.error) {
      reset();

      // navigate("/contact/personal");
    } else {
      console.error("Unexpected response:", response);
    }
  };

  const fetch = async () => {
    try {
      const businessFav = await jsonApi(
        appConstant.getZipCodesByCompany,
        {},
        null,
        true
      );

      if (!businessFav.error) {
      } else {
        setError();
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  // const onSubmit=(data)=>{
  //   console.log(data)
  //     }
  const endCall = () => {
    handleEndCall();
    setPicked("");
  };
  const populateForm = (data) => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(`${key}`, data[key]);
      });
      console.log(data.phone);
      if (data.phone.length === 0) {
        setValue("phone", [{ value: "", type: "Personal" }]);
      }
      if (data.email.length === 0) {
        setValue("email", [{ value: "", type: "Personal" }]);
      }
      setValue("married", true);
    }
  };

  useEffect(() => {
    const calculateAge = (date) => {
      if (!date) return "";
      var today = new Date();
      var birthDate = new Date(date); // create a date object directly from `dob1` argument
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };
    const age = calculateAge(dob);
    setError("");
    setValue("age", age || "");
  }, [dob, setValue]);

  const handleDateChange = (date, type) => {
    const parsedDate = new Date(date);
    if (parsedDate instanceof Date && !isNaN(parsedDate)) {
      if (type === "dob") {
        setDob(parsedDate);
        setValue("dob", date, { shouldValidate: true });
      }
      setError("");
    } else {
      setError("Invalid date format. Please select a valid date.");
    }
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <>
      <div className="w-100">
        <div className="row">
          <div className="col-12 mb-3">
            {inCall ? (
              <div>
                <div className="col-12 text-center">
                  Call Duration: {formatDuration(callDuration)}
                </div>
                <div className="col-12 text-center">
                  <button onClick={endCall} className="btn btn-danger">
                    <PhoneOff />
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <form className="col" onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body custom-field-button">
                <div className="row">
                  {/* <div className="col-3 d-flex flex-column justify-content-start"> */}
                  <div
                    className="col-4 sticky-col"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="row text-dark font-weight-medium "
                      style={{ position: "fixed" }}
                    >
                      <div>
                        <div className="mb-4   ">
                          <a href="#beneficiary">
                            <h3>I. Beneficiary Information </h3>
                          </a>
                        </div>
                        <div className="mb-4   ">
                          <a href="#eligibility">
                            <h3>II. Eligibility Information</h3>
                          </a>
                        </div>
                        <div className="mb-4   ">
                          <a href="#enrollment">
                            <h3> III. Enrollment Information</h3>
                          </a>
                        </div>
                        <div className="mb-4   ">
                          <a href="#medication">
                            <h3> IV. Medication Information </h3>
                          </a>
                        </div>
                        <div className=" mb-4 w-50">
                          <a href="#quote">
                            <h3> V. Quote </h3>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-8">
                    <div>
                      {medicareInputField.map((item) =>
                        item.type === "heading" ? (
                          <h3
                            className="page-title text-truncate text-dark font-weight-medium mb-4"
                            id={item.id}
                          >
                            {item?.fieldShowName}
                          </h3>
                        ) : item.type === 1 ? (
                          <LeadField
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                          />
                        ) : item.type === 2 ? (
                          <YesNoField
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                          />
                        ) : item.type === 3 ? (
                          <ArrayDoubleFieldPhone
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            showButton={item.button}
                          />
                        ) : item.type === 4 ? (
                          <ArrayDoubleFieldEmail
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            showButton={item.button}
                          />
                        ) : item.type === 5 ? (
                          <DateLead
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                          />
                        ) : item.type === 6 ? (
                          <SocialSecurityNumber
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                          />
                        ) : item.type === 7 ? (
                          <MBIField
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                          />
                        ) : item.type === 8 ? (
                          <MedicaidId
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                          />
                        ) : item.type === "Break" ? (
                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
                            {item.fieldShowName}
                          </h4>
                        ) : item.type === 9 ? (
                          <YearsField
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            yrsBox={item.yrsBox}
                          />
                        ) : item.type === 10 ? (
                          <AddressField
                            errors={errors}
                            register={register}
                            setValue={setValue}
                            item={item}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            check={item.check}
                          />
                        ) : item.type === 22 ? (
                          <MailAddress
                            errors={errors}
                            register={register}
                            setValue={setValue}
                            item={item}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            check={item.check}
                          />
                        ) : item.type === 11 ? (
                          <MedicationInformation
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : item.type === 12 ? (
                          <AccountNumberField
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                          />
                        ) : item.type === 20 ? (
                          <RoutingNumber
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                          />
                        ) : item.type === 13 ? (
                          <MedicareBeneficiaryEnrollmentDateField
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : item.type === 14 ? (
                          <BeneficiaryPlanEnrollmentInformation
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : item.type === 17 ? (
                          <BeneficiaryUncoveredMonthsNotEnrolled
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : item.type === 18 ? (
                          <ElectionTypeCodeUsage
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : item.type === 19 ? (
                          <LowIncomeSubsidy
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : item.type === 21 ? (
                          <MedicaidEligibilityLevel
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : item.type === 15 ? (
                          <QuoteField
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : item.type === 16 ? (
                          <NoteField
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : item.type === 23 ? (
                          <POARelationship
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                            item={item}
                          />
                        ) : (
                          <ArrayDoubleFieldPhone
                            errors={errors}
                            fieldName={item.fieldName}
                            fieldShowName={item.fieldShowName}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            useFieldArray={useFieldArray}
                            control={control}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 d-flex justify-content-center align-items-center">
                {/* Start Page Content */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      {/* Button Container */}
                      <div className="col-12 d-flex justify-content-center">
                        <div className="form-group row">
                          <button
                            type="submit"
                            className="next-step btn_green mx-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end row*/}
                </div>
              </div>
              {/* multi-column ordering */}
            </div>

            {/* multi-column ordering */}
          </form>
        </div>
      </div>

      {/* End Container fluid  */}
    </>
  );
};

export default UpdateLeadForm;
