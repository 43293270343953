import React, { useContext, useEffect, useState } from "react";
import contactHeader from "../../json/contactHeader.json";
import appConstant from "../../json/appConstant.json";
import useApis from "../../apis/useApis";
import FormatNumber from "../utils/FormatNumber";
import { CallCenterContext } from "../../context/CallCenterContext";
import ModelUi from "../model/ModelUi";
import DataTable from "../contact/DataTable";

const ClientLead = () => {
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [clientLeadDetails, setClientLeadDetails] = useState([]);
  const [search, setSearch] = useState("");
  const { makeCall } = useContext(CallCenterContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [openModelForCall, setOpenModelForCall] = useState({
    model: false,
    array: [],
  });


  const setOpenModelForCallHandler = (array) => {
    setOpenModelForCall({ model: true, array });
  };
  const makeCallModelHandleClose = () => {
    setOpenModelForCall({ model: false, array: [] });
  };

  const fetchClientLeadData = async () => {
    try {
      const clientLeadData = await jsonApi(
        appConstant.getClientLead,
        {
          page,
          limit,
          search: search,
        },
        null,
        true
      );

      if (!clientLeadData.error) {
        setClientLeadDetails(clientLeadData.data.data || []); // Update state with actual data
        setTotalData(clientLeadData.data.total);
      } else {
        setError(
          clientLeadData.message ? clientLeadData.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchClientLeadData();
  }, [page, limit, search]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                Client Lead data
              </h4>
              <div className="row ">{/*/Col*/}</div>

              <DataTable
                headers={contactHeader.clientLead}
                fetchedData={clientLeadDetails}
                page={page}
                limit={limit}
                setPage={setPage}
                totalData={totalData}
                setLimit={setLimit}
                setSearch={setSearch}
                init={fetchClientLeadData}
              />
            </div>
          </div>
        </div>
      </div>
      <ModelUi
        show={openModelForCall.model}
        handleClose={makeCallModelHandleClose}
        title="Select number to call"
      >
        <table style={{ width: "100%" }}>
          <thead>
            <th style={{ textAlign: "justify" }}>Number</th>
            <th style={{ textAlign: "justify" }}>Call</th>
          </thead>
          <tbody>
            {openModelForCall.array.map((item) => (
              <tr style={{ textAlign: "justify" }}>
                <td style={{ textAlign: "justify" }}>
                  <p>{item.value}</p>
                </td>
                <td style={{ textAlign: "justify", cursor: "pointer" }}>
                  <span
                    className="right_icon"
                    onClick={() => {
                      makeCall(item.value);
                      makeCallModelHandleClose();
                    }}
                  >
                    <i className="material-symbols-outlined">call</i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModelUi>
    </div>
  );
};

export default ClientLead;
