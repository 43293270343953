import React from "react";
import Modal from "react-bootstrap/Modal";

const ModelUi = ({ show, handleClose, children, title, centered }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={centered}
      className="modal fade"
      backdrop="static"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title text-dark font-weight-medium">{title}</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </Modal>
  );
};

export default ModelUi;
