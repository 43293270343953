
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";

const TakingAnyMedicineDetail = ({ control, register, errors, item }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "takingAnyMedicineDetail",
  });

  return (
    <div>
      <h5 className="page-title text-truncate text-dark font-weight-medium mb-4 pt-4">
      Please describe Your Medical Condition Below
      </h5>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name of Medication</th>
            <th>Dosage</th>
            <th>Frequency</th>
            <th>Explanation as to why you are taking the Medication</th>
            
          </tr>
        </thead>
        <tbody>
          {fields.map((takingMedicine, index) => (
            <tr key={takingMedicine.id}>
              <td>
                <Controller
                  control={control}
                  name={`takingAnyMedicineDetail[${index}].medicineName`}
                  defaultValue={takingMedicine.medicineName || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <Controller
                  control={control}
                  name={`takingAnyMedicineDetail[${index}].dosage`}
                  defaultValue={takingMedicine.dosage || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>

              <td>
                <Controller
                  control={control}
                  name={`takingAnyMedicineDetail[${index}].frequency`}
                  defaultValue={takingMedicine.frequency || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>

              <td>
                <Controller
                  control={control}
                  name={`takingAnyMedicineDetail[${index}].explanationOfTakingMedicine`}
                  defaultValue={takingMedicine.explanationOfTakingMedicine || ""}
                  render={({ field }) => (
                    <input type="text" className="form-control" {...field} />
                  )}
                />
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => remove(index)}
                >
                  ×
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex">
        <button
          type="button"
          className="btn btn-success ml-auto"
          onClick={() =>
            append({
                medicineName: "",
                dosage: "",
                frequency: "",
                explanationOfTakingMedicine: "",
            })
          }
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default TakingAnyMedicineDetail;
