import React from 'react'
import Modal from 'react-bootstrap/Modal';

const AddStates = ({setAddStateModal,addStateModal, addState, onSave}) => {

  const handleAddStateClose=()=>{
    setAddStateModal(false)
  }

  const handleStateSave = () => {
    onSave();
    handleAddStateClose();
  };


  return (
    <Modal  show={addStateModal} onHide={handleAddStateClose} id="addStates">
    <div className="modal-content">
      <div className="modal-header">
        <h4
          className="modal-title text-dark font-weight-medium"
          id="exampleModalLabel"
        >
          Add States
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleAddStateClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <label className="font-weight-bold">Product Name</label>
        <p>Disability Individual</p>
        <label className="font-weight-bold">Select States</label>
        <ul className="select_state">
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="AL" value={addState}  onChange={(e)=>onSave(e.target.value)}/>
              <label className="custom-control-label" htmlFor="AL">
                AL
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="AK" value={addState}  onChange={(e)=>onSave(e.target.value)} />
              <label className="custom-control-label" htmlFor="AK">
                AK
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="AZ" value={addState}  onChange={(e)=>onSave(e.target.value)} />
              <label className="custom-control-label" htmlFor="AZ">
                AZ
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="AR" value={addState}  onChange={(e)=>onSave(e.target.value)}/>
              <label className="custom-control-label" htmlFor="AR">
                AR
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="CA" value={addState}  onChange={(e)=>onSave(e.target.value)}/>
              <label className="custom-control-label" htmlFor="CA">
                CA
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="CO" value={addState}  onChange={(e)=>onSave(e.target.value)}/>
              <label className="custom-control-label" htmlFor="CO">
                CO
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="CT" value={addState}  onChange={(e)=>onSave(e.target.value)}/>
              <label className="custom-control-label" htmlFor="CT">
                CT
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="DE" />
              <label className="custom-control-label" htmlFor="DE">
                DE
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="DC" />
              <label className="custom-control-label" htmlFor="DC">
                DC
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="FL" />
              <label className="custom-control-label" htmlFor="FL">
                FL
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="GA" />
              <label className="custom-control-label" htmlFor="GA">
                GA
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="HI" />
              <label className="custom-control-label" htmlFor="HI">
                HI
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="ID" />
              <label className="custom-control-label" htmlFor="ID">
                ID
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="IL" />
              <label className="custom-control-label" htmlFor="IL">
                IL
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="IN" />
              <label className="custom-control-label" htmlFor="IN">
                IN
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="IA" />
              <label className="custom-control-label" htmlFor="IA">
                IA
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="KS" />
              <label className="custom-control-label" htmlFor="KS">
                KS
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="KY" />
              <label className="custom-control-label" htmlFor="KY">
                KY
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="LA" />
              <label className="custom-control-label" htmlFor="LA">
                LA
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="ME" />
              <label className="custom-control-label" htmlFor="ME">
                ME
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="MD" />
              <label className="custom-control-label" htmlFor="MD">
                MD
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="MA" />
              <label className="custom-control-label" htmlFor="MA">
                MA
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="MI" />
              <label className="custom-control-label" htmlFor="MI">
                MI
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="NE" />
              <label className="custom-control-label" htmlFor="NE">
                NE
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="NV" />
              <label className="custom-control-label" htmlFor="NV">
                NV
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="NH" />
              <label className="custom-control-label" htmlFor="NH">
                NH
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="NJ" />
              <label className="custom-control-label" htmlFor="NJ">
                NJ
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="NM" />
              <label className="custom-control-label" htmlFor="NM">
                NM
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="NY" />
              <label className="custom-control-label" htmlFor="NY">
                NY
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="NC" />
              <label className="custom-control-label" htmlFor="NC">
                NC
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="ND" />
              <label className="custom-control-label" htmlFor="ND">
                ND
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="OH" />
              <label className="custom-control-label" htmlFor="OH">
                OH
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="OK" />
              <label className="custom-control-label" htmlFor="OK">
                OK
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="OR" />
              <label className="custom-control-label" htmlFor="OR">
                OR
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="PA" />
              <label className="custom-control-label" htmlFor="PA">
                PA
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="RI" />
              <label className="custom-control-label" htmlFor="RI">
                RI
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="SC" />
              <label className="custom-control-label" htmlFor="SC">
                SC
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="SD" />
              <label className="custom-control-label" htmlFor="SD">
                SD
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="TN" />
              <label className="custom-control-label" htmlFor="TN">
                TN
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="TX" />
              <label className="custom-control-label" htmlFor="TX">
                TX
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="UT" />
              <label className="custom-control-label" htmlFor="UT">
                UT
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="VT" />
              <label className="custom-control-label" htmlFor="VT">
                VT
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="VA" />
              <label className="custom-control-label" htmlFor="VA">
                VA
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="WA" />
              <label className="custom-control-label" htmlFor="WA">
                WA
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="WV" />
              <label className="custom-control-label" htmlFor="WV">
                WV
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="WI" />
              <label className="custom-control-label" htmlFor="WI">
                WI
              </label>
            </div>
          </li>
          <li>
            <div className="custom-checkbox form-check-inline">
              <input type="checkbox" className="custom-control-input" id="WY" />
              <label className="custom-control-label" htmlFor="WY">
                WY
              </label>
            </div>
          </li>
        </ul>
        <div className="row">
          <div className="col-12 mt-4 mb-3">
            <label className="font-weight-bold">Select US Territory</label>
          </div>
          <div className="col-sm-6 mb-2">
            <div className="custom-checkbox form-check-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="AmericanSamoa"
              />
              <label className="custom-control-label" htmlFor="AmericanSamoa">
                American Samoa
              </label>
            </div>
          </div>
          <div className="col-sm-6 mb-2">
            <div className="custom-checkbox form-check-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="PuertoRico"
              />
              <label className="custom-control-label" htmlFor="PuertoRico">
                Puerto Rico
              </label>
            </div>
          </div>
          <div className="col-sm-6 mb-2">
            <div className="custom-checkbox form-check-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="Guam"
              />
              <label className="custom-control-label" htmlFor="Guam">
                Guam
              </label>
            </div>
          </div>
          <div className="col-sm-6 mb-2">
            <div className="custom-checkbox form-check-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="VirginIslands"
              />
              <label className="custom-control-label" htmlFor="VirginIslands">
                The U.S. Virgin Islands
              </label>
            </div>
          </div>
          <div className="col-sm-6 mb-2">
            <div className="custom-checkbox form-check-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="TheNorthernMarianaIslands"
              />
              <label
                className="custom-control-label"
                htmlFor="TheNorthernMarianaIslands"
              >
                The Northern Mariana Islands
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center py-5">
            <a href="#!" className="btn_white py-3 px-5">
              Cancel
            </a>
            <a href="#!" className="btn_red py-3 px-5" onClick={handleStateSave}>
              Save
            </a>
          </div>
        </div>
      </div>
    </div>

</Modal>

  

  )
}

export default AddStates