import React from "react";

const SalesInformation = ({ errors, fieldName, fieldShowName, register }) => {
  return (
    <div className="row my-2">
      <div className="col-12 pt-2">
        {/* Display the question above the input field */}
        <label htmlFor={fieldName} className="">
          {fieldShowName}
        </label>
      </div>
      <div className="col-12">
        <input
          type="text"
          maxLength={256}
          className="form-control border border-dark"
          id={fieldName}
          
          {...register(fieldName, {
            required: false,
          })}
        />
        {/* Display error message below the input */}
        {errors[fieldName] && (
          <p className="text-danger">{errors[fieldName].message}</p>
        )}
      </div>
    </div>
  );
};

export default SalesInformation;
