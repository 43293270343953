import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAuthApis from "../../apis/useAuthApis";
import { useForm, Controller } from "react-hook-form";
import { Eye, EyeOff } from "react-feather";

const Login = () => {
  document.title = "Digital Company";
  const { loginApi } = useAuthApis();
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const onSubmit = async (data) => {
    const res = await loginApi(data);
    if (res.error) {
      setError(res.message);
    } else {
      setError("");
    }
  };

  return (
    <>
      <div className="d-lg-flex half">
        <div
          className="bg order-1 order-md-2"
          style={{
            backgroundImage: 'url("/assets/images/background/login-bg.jpg")',
          }}
        />
        <div className="contents order-2 order-md-1">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7">
                <h3>
                  Login to <strong>User Control Panel</strong>
                </h3>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group first">
                    <label htmlFor="email">Email</label>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: "Email is required" }}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          maxLength={256}
                          {...field}
                          onClick={() => setError("")}
                          placeholder="Your Email"
                        />
                      )}
                    />
                    {errors.email && (
                      <medium className="text-danger d-block mt-2">
                        {errors.email.message}
                      </medium>
                    )}
                  </div>

                  <div className="form-group last mb-3">
                    <label htmlFor="password">Password</label>

                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: "Password is required" }}
                      render={({ field }) => (
                        <div className="input-group mb-3">
                          <input
                            className="form-control"
                            maxLength={256}
                            {...field}
                            type={showPassword ? "text" : "password"}
                            onClick={() => setError("")}
                            placeholder="Your Password"
                            id="password"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                            className="input-group-text"
                          >
                            {showPassword ? (
                              <Eye className="feather-icon" />
                            ) : (
                              <EyeOff className="feather-icon" />
                            )}
                          </span>
                        </div>
                      )}
                    />
                    {errors.password && (
                      <medium className="text-danger d-block mt-2">
                        {errors.password.message}
                      </medium>
                    )}
                  </div>

                  <div className="d-flex mb-5 align-items-center">
                    <label className="control control--checkbox mb-0">
                      <span className="caption">Remember me</span>
                      <input type="checkbox" defaultChecked="checked" />
                      <div className="control__indicator" />
                    </label>
                    <span className="ml-auto">
                      <Link to="/forgetPassword" className="forgot-pass">
                        Forgot Password
                      </Link>
                    </span>
                  </div>
                  <input
                    type="submit"
                    defaultValue="Log In"
                    className="btn btn-block btn-primary"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
