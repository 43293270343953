import React, { useState, useEffect } from "react";

import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import AddFelidModel from "./AddFelidModel";
import ViewFelidComponent from "./ViewFelidComponent";

const CustomFrom = () => {
  const [initError, setInitError] = useState("");
  const [data, setData] = useState([]);
  const [dummyData, setDummyData] = useState([]);
  const [formName, setFormName] = useState([]);
  const [ready, setReady] = useState(false);
  const [openAddFelidModal, setOpenAddFelidModal] = useState(false);

  const { jsonApi } = useApis();
  const initFunctions = async () => {
    if (formName) {
      const fetchData = await jsonApi(
        appConstant.formGet,
        {},
        { formName },
        true
      );
      if (!fetchData.error) {
        console.log(fetchData.data.data.data);
        console.log(fetchData);
        setData(JSON.parse(fetchData.data.data.data));
        setDummyData(JSON.parse(fetchData.data.data.dummyData));
        setReady(true);
      } else {
        setData([]);
        setDummyData([]);
        setReady(false);
      }
    } else {
      setReady(false);
      setData([]);
      setDummyData([]);
    }
  };
  useEffect(() => {
    initFunctions();
  }, [formName]);
  console.log(data);

  const addFelidHandler = (dataFelid) => {
    data.push(dataFelid);
    setData(data);
    addFelidHandlerCloseModel();
  };

  useEffect(() => {}, []);
  const addFelidButtonHandler = () => {
    setOpenAddFelidModal(true);
  };
  const addFelidHandlerCloseModel = () => {
    setOpenAddFelidModal(false);
  };
  return (
    <>
      <div className="page-breadcrumb">
        <div className="row">
          <div className="col-10 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
              Custom Form
            </h3>
          </div>
          <div className="col-2 align-self-center"></div>
        </div>
      </div>
      {/* Container fluid  */}

      <div className="container-fluid">
        {initError ? (
          <div class="alert alert-danger" role="alert">
            {initError}
          </div>
        ) : (
          <></>
        )}
        {/* Start Page Content */}
        {/* basic table */}
        <div className="row">
          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-10 align-self-center">
                    <div className="form-group">
                      <label htmlFor="employmentLevel">Select from name</label>

                      <select
                        className="form-control"
                        value={formName}
                        onChange={(e) => setFormName(e.target.value)}
                        disabled={ready}
                      >
                        <option value="">Select from name</option>
                        <option value="Medicare">Medicare</option>
                        <option value="">Manager</option>
                        <option value="">Executive</option>
                        <option value="">Employee</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-2 align-self-center">
                    <button type="button" className="btn btn-primary">
                      See Form
                    </button>
                  </div>
                </div>
                {data.map((value, index) => (
                  <ViewFelidComponent
                    value={value}
                    key={index}
                    removeHandler={() =>
                      setData((pre) => {
                        return pre.filter((item, i) => i !== index);
                      })
                    }
                  />
                ))}
                <div className="row">
                  <div className="col-12 ">
                    <div className="w-100 d-flex justify-content-center align-item-center">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addFelidButtonHandler}
                      >
                        Add Felid
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              alt=""
              title="Ads"
            />
          </div>
        </div>
      </div>
      <AddFelidModel
        addFelidHandlerCloseModel={addFelidHandlerCloseModel}
        openAddFelidModal={openAddFelidModal}
        addFelidHandler={addFelidHandler}
      />
    </>
  );
};

export default CustomFrom;
