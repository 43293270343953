import React, { useEffect, useState } from "react";
import contactHeader from "../../json/contactHeader.json";
import appConstant from "../../json/appConstant.json";
import useApis from "../../apis/useApis";
import FormatNumber from "../utils/FormatNumber";
import DataTable from "../contact/DataTable";

const ProspectsLead = () => {
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [prospectsDetails, setProspectsDetails] = useState([]);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);

  const fetchProspectsLeadData = async () => {
    try {
      const prospectLeadData = await jsonApi(
        appConstant.getProspectLead,
        {
          page,
          limit,
          search: search,
          type: ["Human Resource", "Business Info", "Business Contact"],
        },
        null,
        true
      );

      if (!prospectLeadData.error) {
        setProspectsDetails(prospectLeadData.data.data || []); // Update state with actual data
        setTotalData(prospectLeadData.data.total);
      } else {
        setError(
          prospectLeadData.message
            ? prospectLeadData.message
            : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchProspectsLeadData();
  }, [page, limit, search]);

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                Prospects Lead Data
              </h4>
              <div className="row ">{/*/Col*/}</div>

              <DataTable
                headers={contactHeader.prospectLead}
                fetchedData={prospectsDetails}
                page={page}
                limit={limit}
                setPage={setPage}
                totalData={totalData}
                setLimit={setLimit}
                setSearch={setSearch}
                init={fetchProspectsLeadData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProspectsLead;
