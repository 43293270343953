import React, { useState, useEffect } from "react";

const ElectionTypeCodeUsage = ({
  fieldName,
  fieldShowName,
  register,
  errors,
}) => {
  const [defaultOption, setDefaultOption] = useState("");

  useEffect(() => {
    const today = new Date();
    const month = today.getMonth() + 1; 
    const day = today.getDate();

    if ((month === 10 && day >= 15) || (month === 12 && day <= 7)) {
      setDefaultOption("AEP");
    } else if (month === 1 || (month === 3 && day <= 31)) {
      setDefaultOption("OEP");
    } else if (
      (month === 4 && day >= 1) ||
      (month === 10 && day < 15) ||
      (month > 4 && month < 10)
    ) {
      setDefaultOption("SEP");
    }
  }, []);

  return (
    <div>
      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <h5 className="text-dark font-weight-medium">
        {fieldShowName}
      </h5>
        </div>
        <div className="col-5">
          <select
            className={`form-control borderStyle ${
              errors[fieldName] ? "border border-danger" : ""
            }`}
            id={fieldName}
            {...register(fieldName, {
              required: false,
            })}
            defaultValue={defaultOption}
          >
            <option value="AEP" selected={defaultOption === "AEP"}>
              AEP (Annual Election Period): October 15 - December 7
            </option>
            <option value="OEP" selected={defaultOption === "OEP"}>
              OEP (Open Enrollment Period): January 1 - March 31
            </option>
            <option value="SEP" selected={defaultOption === "SEP"}>
              SEP (Special Election Period): April 1 - October 14
            </option>
          </select>{" "}
          {errors[fieldName] && (
            <p className="text-danger">{errors[fieldName].message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElectionTypeCodeUsage;
