import React, { useState } from "react";

const YesNoField = ({
  errors,
  fieldName,
  fieldShowName,
  register,
  setValue,
  watch,
}) => {
  const handleClick = (value) => {
    setValue(fieldName, value);
  };
  const value = watch(fieldName);
  return (
    <div className="row my-2">
      <div className="col-7 pt-2">
        <label htmlFor={fieldName} className="">
          {fieldShowName}
        </label>
      </div>
      <div className="col-5">
        <div className="btn-group" role="group" aria-label={fieldName}>
          <button
            type="button"
            className={`btn ${
              value === true ? "btn-success" : "btn-secondary"
            } mr-2`}
            {...register(fieldName)} 
            value={true}
            onClick={() => handleClick(true)}
          >
            Yes
          </button>
          <button
            type="button"
            className={`btn ${
              value === false ? "btn-success" : "btn-secondary"
            }`}
            {...register(fieldName)} 
            value={false}
            onClick={() => handleClick(false)}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default YesNoField;
