import React, { useContext, useEffect, useState } from "react";

import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";
import moment from "moment";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";

const SubscriptionCompany = () => {
  const { jsonApi } = useApis();

  const [fetchData, setFetchData] = useState([]);
  const { userData } = useContext(Context);

  const [error, setError] = useState(null);

  const getCompanyId = async () => {
    const companyId = userData.company;
    if (companyId) {
      try {
        const companyData = await jsonApi(
          appConstant.getCompanyById,
          { id: companyId },
          null,
          true
        );

        if (!companyData.error && companyData.data) {
          setFetchData(companyData.data);
          setError(companyData.message || "Something went wrong");
        }
      } catch (err) {
        setError("An error occurred while fetching company data.");
        console.error(err);
      }
    }
  };

  useEffect(() => {
    getCompanyId();
  }, [userData.company]);

  console.log(fetchData);

  const costPerUserMapping = {
    SingleAgent: 200,
    GeneralAgent: 190,
    MGA: 180,
    SGA: 150,
    FMO: 125,
    InsuranceCompany: 115,
  };

  const [contractTypeState, setContractTypeState] = useState("");
  // You can define methods here if needed and expose them through the ref
  useEffect(() => {
    // Determine the default industry based on the number of users
    const userCount = fetchData?.users?.length;
    let contractType = "SingleAgent"; // Default fallback

    if (userCount >= 301) {
      contractType = "InsuranceCompany";
    } else if (userCount >= 51) {
      contractType = "FMO";
    } else if (userCount >= 11) {
      contractType = "SGA";
    } else if (userCount >= 6) {
      contractType = "MGA";
    } else if (userCount >= 2) {
      contractType = "GeneralAgent";
    } else if (userCount === 1) {
      contractType = "SingleAgent";
    }
    setContractTypeState(contractType);
  }, [fetchData?.users?.length]);

  const numberOfUsers = fetchData?.users?.length || 0;
  // Calculate amount and total
  const costPerUser = contractTypeState
    ? costPerUserMapping[contractTypeState]
    : 0;
  const amount = numberOfUsers * costPerUser;
  const serviceCharge = 0;
  const taxRate = 0;
  const taxAmount = ((amount + serviceCharge) * taxRate) / 100;
  const total = amount + serviceCharge + taxAmount;

  const formatTimestamp = (timestamp, formatStr) => {
    if (!timestamp) return "N/A";
    const date = moment(timestamp);
    return date.format(formatStr);
  };

  const defaultMonth = formatTimestamp(fetchData.createdAt, "MMMM");
  const defaultYear = formatTimestamp(fetchData.createdAt, "yyyy");

  // const defaultMonths = moment(props.data?.createdAt).month() + 1; // Month is 0-indexed

  console.log(defaultMonth, defaultYear);

  //  Get the current month and year
  const currentMonth = moment().format("MMMM");
  const currentYear = moment().format("YYYY");
  console.log(currentMonth, currentYear);

  const months = moment.months();
  console.log(months);

  const showAllMonthsAndYears = defaultYear < currentYear;
  console.log(showAllMonthsAndYears);

  const monthOptions = months.map((month, index) => (
    <option key={month} value={index + 1}>
      {month}
    </option>
  ));

  const yearOptions = [];
  yearOptions.push(
    <option key={currentYear - 1} value={currentYear - 1}>
      {currentYear - 1}
    </option>
  );
  yearOptions.push(
    <option key={currentYear} value={currentYear}>
      {currentYear}
    </option>
  );

  const currentDate = moment().format("l");

  return (
    <>
      <div className="page-breadcrumb">
        <div className="row">
          <div className="col-12 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
              Subscription
            </h3>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-6">
                    <div className="row align-items-center">
                      <label className="col-lg-3 mb-3 font-weight-bold pr-1">
                        Statement Period
                      </label>
                      <div className="col-lg-3 mb-3">
                        <select className="form-control">
                          <option value="" disabled>
                            Year
                          </option>
                          {yearOptions}
                        </select>
                      </div>
                      <label className="col-lg-3 mb-3 font-weight-bold">
                        Display Mode
                      </label>
                      <div className="col-lg-3 mb-3">
                        <select className="form-control">
                          <option value="" disabled>
                            Month
                          </option>
                          {monthOptions}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 text-center text-lg-right mb-3">
                    <button
                      type="button"
                      className="btn btn-rounded btn-primary mr-2 mb-1"
                    >
                      Make Payment
                    </button>
                    <button
                      type="button"
                      className="btn btn-rounded btn-outline-secondary mr-1 mb-1"
                    >
                      <i className="icon-printer" />
                      Print
                    </button>
                    <button
                      type="button"
                      className="btn btn-rounded btn-outline-secondary mr-1 mb-1"
                    >
                      <i className="fas fa-envelope" />
                      Email
                    </button>
                    <button
                      type="button"
                      className="btn btn-rounded btn-outline-secondary mb-1"
                    >
                      <i className="icon-speech" />
                      Text/SMS
                    </button>
                  </div>
                </div>

                <h1 className="text-right text-dark my-4">Statement</h1>
                <div className="row">
                  <div className="col-xl-4 col-lg-5 mb-3 text-dark">
                    <p className="mb-0">Contact Name</p>
                    <h3 className="text-dark font-weight-medium mb-3">
                      {fetchData.userName}
                    </h3>
                    <p className="mb-3">
                      {fetchData.companyAddress}
                      <br />
                      {fetchData.companyUrl}
                    </p>
                    <p className="mb-3">
                      Email: <a href="#!">{fetchData.email}</a>
                      <br />
                      Cell#: {fetchData.cellNumber}
                    </p>
                  </div>
                  <div className="col-xl-8 col-lg-7 mb-3">
                    <div className="table-responsive mb-3 pl-0 pl-xl-5">
                      <table className="table w-100 no-wrap table-bordered">
                        <tbody>
                          <tr>
                            <td className="text-right">
                              <label className="font-weight-bold">
                                Company Name
                              </label>
                              <p className="mb-0">{fetchData.companyName}</p>
                            </td>
                            <td className="text-right">
                              <label className="font-weight-bold">
                                Account #
                              </label>
                              <p className="mb-0">14011</p>
                            </td>
                            <td className="text-right">
                              <label className="font-weight-bold">
                                Statement Date
                              </label>
                              <p className="mb-0">{currentDate}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="table-responsive">
                      <table className="table w-100 no-wrap table-bordered">
                        <tbody>
                          <tr>
                            <td className="text-right">
                              <label className="font-weight-bold">
                                Current
                              </label>
                              <p className="mb-0">$0.00</p>
                            </td>
                            <td className="text-right">
                              <label className="font-weight-bold">
                                Over 30
                              </label>
                              <p className="mb-0">$1,650(min.)</p>
                            </td>
                            <td className="text-right">
                              <label className="font-weight-bold">
                                Over 60
                              </label>
                              <p className="mb-0">$6,375(min.)</p>
                            </td>
                            <td className="text-right">
                              <label className="font-weight-bold">
                                Over 300
                              </label>
                              <p className="mb-0">$34,615(min.)</p>
                            </td>
                            <td className="text-right">
                              <label className="font-weight-bold">
                                Balance Due
                              </label>
                              <p className="mb-0">$0.00</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table w-100 no-wrap table-bordered">
                    <tbody>
                      <tr>
                        <td>Date</td>
                        <td>Reference</td>
                        <td>Type Of Business</td>
                        <td>Contract Level</td>
                        <td>Users #</td>
                        <td>Cost Per User</td>
                        <td>Amount</td>
                        <td className="text-right">Total</td>
                      </tr>
                      <tr>
                        <td>{currentDate}</td>
                        <td />
                        <td>{fetchData.typeOfIndustry}</td>
                        <td>{contractTypeState}</td>
                        <td>
                          <a href="#!">{fetchData?.users?.length}</a>
                        </td>
                        <td>${costPerUser}</td>
                        <td>${amount}</td>
                        <td className="text-right">${amount}</td>
                      </tr>
                      <tr>
                        <td colSpan={6} className="border_lb_none" />
                        <td className="font-weight-bold">Sub Total</td>
                        <td className="text-right">${amount}</td>
                      </tr>
                      <tr>
                        <td colSpan={6} className="border_lb_none" />
                        <td className="font-weight-bold">Service Charge</td>
                        <td className="text-right">$0.00</td>
                      </tr>
                      <tr>
                        <td colSpan={6} className="border_lb_none" />
                        <td className="font-weight-bold">Tax</td>
                        <td className="text-right">$0.00%</td>
                      </tr>
                      <tr>
                        <td colSpan={6} className="border_lb_none" />
                        <td className="font-weight-bold">Total</td>
                        <td className="text-right">${total}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <Payment show={showModal} handleClose={handleClose} amount={total} /> */}
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
              <img
                src="/assets/images/banner/ads.jpg"
                className="mx-auto d-block img-fluid"
                alt=""
                title="Ads"
              />
            </div>  
        </div>
       
      </div>

      <footer className="footer text-center text-muted">
        All Rights Reserved by Digital Company. Designed and Developed by{" "}
        <Link to="/">Digital Company</Link>.
      </footer>
    </>
  );
};

export default SubscriptionCompany;

// useEffect(() => {
//   if (userData.company) {
//     getCompanyId(userData.company.id); // Assuming userData.company has an 'id' property
//   }
// }, [userData.company]); // Dependency array to call on company change

// useEffect(() => {
//   if (userData.company) {
//     const companyId = userData.company.id; // Make sure this is defined
//     if (companyId) {
//       getCompanyId(companyId);
//     } else {
//       console.error('Company ID is undefined');
//     }
//   }
// }, [userData.company]);.
