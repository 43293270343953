import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import useApis from "../../../apis/useApis";
import appConstant from "./../../../json/appConstant.json";
import { validateName } from "../../../components/validation/Validation";

const FormComponent = forwardRef((props, ref) => {
  const { jsonApi } = useApis();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setError,
    setValue,
    clearErrors,
  } = useForm();
  useImperativeHandle(ref, () => ({
    handleSubmit: (param) =>
      handleSubmit((formData) => props.submitForm({ ...formData }, param))(),
  }));

  useEffect(() => {
    console.log(props.data);
    if (props.data) {
      reset({
        companyName: props.data.companyName,
        userName: props.data.userName,
        email: props.data.email,
        typeOfIndustry: props.data.typeOfIndustry,
      });
    }
  }, [props.data, reset, props.index]);

  const handleUsernameChange = async (e) => {
    console.log(e.target.name);
    if (!(e.target.value.length === 0)) {
      const res = await jsonApi(
        appConstant.checkOldEmailAndUsername,
        null,
        { text: e.target.value, type: e.target.name },
        true
      );
      if (res.error) {
        setError(e.target.name, {
          type: "manual",
          message: res.message,
        });
      } else {
        clearErrors(e.target.name);
      }
    }
  };

  return (
    <div>
      <div className="my-5 w-100">
        <div className="row align-items-center">
          <label className="col-xl-3 col-md-4 col-sm-5 mb-3">
            Company Name
          </label>
          <div className="col-xl-5 col-md-8 col-sm-7 mb-3">
            <input
              maxLength={256}
              type="text"
              placeholder="Company Name"
              className="form-control"
              {...register("companyName", {
                required: "Company name is required",
                validate: (value) => validateName(value, "company name"),
              })}
            />
            {errors.companyName && (
              <p className="text-danger">{errors.companyName.message}</p>
            )}
          </div>
        </div>
        <div className="row align-items-center">
          <label className="col-xl-3 col-md-4 col-sm-5 mb-3">
            Create User Name
          </label>
          <div className="col-xl-5 col-md-8 col-sm-7 mb-3">
            <input
              type="text"
              maxLength={256}
              className="form-control"
              {...register("userName", {
                required: "User name is required",
                validate: (value) => validateName(value, "user name"),
              })}
              onChange={(e) => {
                handleUsernameChange(e);
                setValue("userName", e.target.value, {
                  shouldValidate: true,
                });
              }}
            />
            {errors.userName && (
              <p className="text-danger">{errors.userName.message}</p>
            )}
          </div>
        </div>
        <div className="row align-items-center">
          <label className="col-xl-3 col-md-4 col-sm-5 mb-3">Email</label>
          <div className="col-xl-5 col-md-8 col-sm-7 mb-3">
            <input
              type="text"
              maxLength={256}
              className="form-control"
              {...register("email", {
                required: "Please enter Email Id",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              })}
              onChange={(e) => {
                handleUsernameChange(e);
                setValue("email", e.target.value, {
                  shouldValidate: true,
                });
              }}
            />
            {errors.email && (
              <p className="text-danger">{errors.email.message}</p>
            )}
          </div>
        </div>

        <div className="row">
          <label className="col-xl-3 col-md-4 col-sm-5 mb-3">
            Type Of Industry
          </label>
          <div className="col-xl-5 col-md-8 col-sm-7 mb-3">
            <div className="row">
              <div className="col-7 mb-3">
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Architecture"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Architecture"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="Architecture"
                  >
                    Architecture
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="AutoRepair"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="AutoRepair"
                  />
                  <label className="custom-control-label" htmlFor="AutoRepair">
                    Auto Repair
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="AutoSales"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="AutoSales"
                  />
                  <label className="custom-control-label" htmlFor="AutoSales">
                    Auto Sales
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Construction"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Construction"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="Construction"
                  >
                    Construction
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="FinancialPlanning"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="FinancialPlanning"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="FinancialPlanning"
                  >
                    Financial Planning
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="GeneralBusinessType"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="GeneralBusinessType"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="GeneralBusinessType"
                  >
                    General Business Type
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Insurance"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Insurance"
                  />
                  <label className="custom-control-label" htmlFor="Insurance">
                    Insurance
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Legal"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Legal"
                  />
                  <label className="custom-control-label" htmlFor="Legal">
                    Legal
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Medical"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Medical"
                  />
                  <label className="custom-control-label" htmlFor="Medical">
                    Medical
                  </label>
                </div>
              </div>
              <div className="col-5 mb-3">
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Mortgage"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Mortgage"
                  />
                  <label className="custom-control-label" htmlFor="Mortgage">
                    Mortgage
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Other"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Other"
                  />
                  <label className="custom-control-label" htmlFor="Other">
                    Other
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="RealEstate"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="RealEstate"
                  />
                  <label className="custom-control-label" htmlFor="RealEstate">
                    Real Estate
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Restaurant"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Restaurant"
                  />
                  <label className="custom-control-label" htmlFor="Restaurant">
                    Restaurant
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Retail"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Retail"
                  />
                  <label className="custom-control-label" htmlFor="Retail">
                    Retail
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                  <input
                    type="radio"
                    id="Trucking"
                    name="typeOfIndustry"
                    className="custom-control-input"
                    {...register("typeOfIndustry")}
                    value="Trucking"
                  />
                  <label className="custom-control-label" htmlFor="Trucking">
                    Trucking
                  </label>
                </div>
              </div>
            </div>
            {errors.typeOfIndustry && (
              <p className="text-danger">{errors.typeOfIndustry.message}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default FormComponent;
