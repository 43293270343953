import React from "react";
import InputMask from "react-input-mask";

const MedicaidId = ({ errors, fieldName, fieldShowName, register }) => {
    return (
        <div className="row my-2">
            <div className="col-7 pt-2">
                <label htmlFor={fieldName} className="">
                    {fieldShowName}
                </label>
            </div>
            <div className="col-5">
                <InputMask
                    mask="AA9999999"
                    placeholder="AB1234567"
                    id={fieldName}
                    name={fieldName}
                    {...register(fieldName, {
                        // required: "Medicaid ID is required",
                        pattern: {
                            value: /^[A-Z]{2}[0-9]{7}$/,
                            message: "Invalid Medicaid ID format"
                        }
                    })}
                >
                    {(inputProps) => (
                        <input
                            {...inputProps}
                            type="text"
                            className={`form-control borderStyle ${errors[fieldName] ? "border border-danger" : ""
                                }`}
                        />
                    )}
                </InputMask>


                {errors[fieldName] && (
                    <p className="text-danger">{errors[fieldName].message}</p>
                )}
            </div>
        </div>
    );
};

export default MedicaidId;
