import React, { useEffect, useState } from "react";
import HierarchyTopBar from "./HierarchyTopBar";
import HierarchyActionButton from "./HierarchyActionButton";
import HierarchyIndex from "./HierarchyIndex";
import AddHierarchy from "./AddHierarchy";
import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import { Edit2 } from "react-feather";
import { useSearchParams } from "react-router-dom";
import PaginationComponent from "../../components/pagination/PaginationComponent";

const Hierarchy = () => {
  const { jsonApi } = useApis();
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [subIndex, setSubIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    console.log('type parameter not found, setting default to "list"');

    if (!searchParams.get("type")) {
      console.log('type parameter not found, setting default to "list"');
      setSearchParams({ type: "list" });
    }
    if (searchParams.get("type") === "list") {
      setTabIndex(0);
    } else {
      setTabIndex(1);
    }
  }, [searchParams, setSearchParams]);
  const init = async () => {
    const res = await jsonApi(
      appConstant.getAllCompany,
      {
        page,
        limit,
        search: search,
      },
      null,
      false
    );
    if (!res.error) {
      console.log(res.data);
      setData(res.data.data);
      setTotalData(res.data.total);
      setUser(res.data?.data[0]?.users);
    }
  };
  useEffect(() => {
    init();
  }, [page, limit, search]);

  return (
    <>
      <div className="page-breadcrumb">
        <div className="row">
          <div className="col-12 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
              Hierarchy
            </h3>
          </div>
        </div>
      </div>
      {/* Container fluid  */}
      <div className="container-fluid">
        {/* Start Page Content */}
        {/* basic table */}
        <div className="row">
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body p-0">
                <div className="row page_height_min">
                  <div className="col-xl-2 align-self-stretch">
                    <div className="sidebar_tab w-100 h-100">
                      <div className="list-group" id="list-tab" role="tablist">
                        <a
                          className={`list-group-item list-group-item-action ${
                            tabIndex === 0 ? "active" : ""
                          } `}
                          onClick={() => {
                            init();

                            setSearchParams({ type: "list" });
                            setSubIndex(0);
                          }}
                        >
                          List Of Hierarchy
                        </a>
                        <a
                          className={`list-group-item list-group-item-action ${
                            tabIndex === 1 ? "active" : ""
                          } `}
                          onClick={() => {
                            init();
                            setTabIndex(1);
                            setSubIndex(0);
                            setSearchParams({ type: "add" });
                          }}
                        >
                          Add Hierarchy
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-10 align-self-stretch">
                    <div className="h-100">
                      {tabIndex === 0 ? (
                        <div className=" minus_left_30 h-100 m-2">
                          {subIndex === 0 ? (
                            <div className="row h-100">
                              <div className="col-xl-9 align-self-stretch">
                                <div className="sidebar_tab w-100 h-100 table-responsive ">
                                  <PaginationComponent
                                    setPage={setPage}
                                    totalData={totalData}
                                    limit={limit}
                                    setLimit={setLimit}
                                    page={page}
                                    setSearch={setSearch}
                                  >
                                    <div
                                      className="list-group m-2"
                                      id="list_hierarchy"
                                      role="tablist"
                                    >
                                      {data.map((item) => (
                                        <a
                                          className="list-group-item list-group-item-action"
                                          id="Listtab1-list"
                                          data-toggle="list"
                                          href="#Listtab1"
                                          role="tab"
                                          aria-controls="home"
                                          aria-selected="false"
                                          onClick={() => setUser(item.users)}
                                        >
                                          <div className="row">
                                            <div className="col-10">
                                              <span>{item.companyName}</span>
                                            </div>
                                            <div className="col-2">
                                              <span className="right_icon">
                                                <Edit2
                                                  className="feather-icon"
                                                  onClick={() => {
                                                    setSearchParams({
                                                      id: item._id,
                                                      type: "edit",
                                                    });
                                                    setSubIndex(1);
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </a>
                                      ))}
                                    </div>
                                  </PaginationComponent>
                                </div>
                              </div>

                              <div className="col-xl-3 align-self-stretch">
                                <div
                                  className="tab-content"
                                  id="ListTabContent"
                                >
                                  <div className="tab-pane fade p-3 p-xl-4">
                                    Atena Writing Agents
                                  </div>
                                  {/*/List Of Hierarchy Tab 1*/}
                                  <div
                                    className="tab-pane fade p-3 p-xl-4"
                                    id="Listtab2"
                                    role="tabpanel"
                                    aria-labelledby="Listtab2-list"
                                  >
                                    Add Agent
                                  </div>
                                  {/*/List Of Hierarchy Tab 1*/}
                                  <div
                                    className="tab-pane fade active show minus_left_30"
                                    id="Listtab3"
                                    role="tabpanel"
                                    aria-labelledby="Listtab3-list"
                                  >
                                    <table className="table table-bordered">
                                      <tbody>
                                        {user?.map((item) => (
                                          <tr>
                                            <td>
                                              {item?.name} || {item?.email}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <AddHierarchy
                              title={"Edit Hierarchy "}
                              setSubIndex={setSubIndex}
                            />
                          )}
                        </div>
                      ) : (
                        <AddHierarchy setSubIndex={setSubIndex} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="/assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              title="Ads"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hierarchy;
