import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CallTypeChart = () => {
  const options = {
    chart: {
      type: "spline",
    },
    title: {
      text: "Call Types",
    },
    subtitle: {
      text:
        "Source: " +
        '<a href="https://en.wikipedia.org/wiki/List_of_cities_by_average_temperature" ' +
        'target="_blank">Wikipedia.com</a>',
    },
    xAxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      tickInterval: 1 // Set the tick interval to 1
    },
    yAxis: {
      title: {
        text: null // Remove the title
      },
      labels: {
        formatter: function () {
          return this.value + 'k'; // Append 'k' to the value
        }
      },
      tickPositions: [0, 5, 10, 15, 20, 25, 30] // Specify the tick values
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
        },
      },
    },
    series: [{
        name: 'Outgoing Calls',
        marker: {
          symbol: 'square'
        },
        data: [0, 2, 5, 10, 12, 15, 13] // Adjusted to match the number of categories (7)
      }, {
        name: 'Incoming Calls',
        marker: {
          symbol: 'diamond'
        },
        data: [8, 9, 11, 20, 25, 30, 28] // Adjusted to match the number of categories (7)
      }]
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default CallTypeChart;

 // series: [{
    //     name: 'Outgoing Calls',
    //     marker: {
    //       symbol: 'square'
    //     },
    //     data: [5200, 5700, 8700, 13900, 18200, 21400, 25000, {
    //       y: 26400,
    //       marker: {
    //         symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)'
    //       },
    //       accessibility: {
    //         description: 'Sunny symbol, this is the warmest point in the chart.'
    //       }
    //     }, 22800, 17500, 12100, 7600]
    //   }, {
    //     name: 'Incoming Calls',
    //     marker: {
    //       symbol: 'diamond'
    //     },
    //     data: [{
    //       y: 1500,
    //       marker: {
    //         symbol: 'url(https://www.highcharts.com/samples/graphics/snow.png)'
    //       },
    //       accessibility: {
    //         description: 'Snowy symbol, this is the coldest point in the chart.'
    //       }
    //     }, 1600, 3300, 5900, 10500, 13500, 14500, 14400, 11500, 8700, 4700, 2600]
    //   }]

 // series: [
    //   {
    //     name: "Tokyo",
    //     marker: {
    //       symbol: "square",
    //     },
    //     data: [
    //       5.2,
    //       5.7,
    //       8.7,
    //       13.9,
    //       18.2,
    //       21.4,
    //       25.0,
    //       {
    //         y: 26.4,
    //         // marker: {
    //         //   symbol:
    //         //     "url(https://www.highcharts.com/samples/graphics/sun.png)",
    //         // },
    //         // accessibility: {
    //         //   description:
    //         //     "Sunny symbol, this is the warmest point in the chart.",
    //         // },
    //       },
    //       22.8,
    //       17.5,
    //       12.1,
    //       7.6,
    //     ],
    //   },
    //   {
    //     name: "Bergen",
    //     marker: {
    //       symbol: "diamond",
    //     },
    //     data: [
    //       {
    //         y: 1.5,
    //         // marker: {
    //         //   symbol:
    //         //     "url(https://www.highcharts.com/samples/graphics/snow.png)",
    //         // },
    //         // accessibility: {
    //         //   description:
    //         //     "Snowy symbol, this is the coldest point in the chart.",
    //         // },
    //       },
    //       1.6,
    //       3.3,
    //       5.9,
    //       10.5,
    //       13.5,
    //       14.5,
    //       14.4,
    //       11.5,
    //       8.7,
    //       4.7,
    //       2.6,
    //     ],
    //   },
    // ],