const FormatNumber = (phoneNumber) => {
  if (!phoneNumber) return "No Data";

  const cleanedNumber = phoneNumber.replace(/^\D+/g, ""); // Clean non-digit characters

  if (cleanedNumber.length < 10) return "No Data"; // Check if the number is valid

  // Format number with the + sign and proper grouping
  const number = cleanedNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})$/,"($2) $3-$4");

  return number;
};

export default FormatNumber;


{/* {item.primaryContactNumber
                                              ? `+ ${item.primaryContactNumber
                                                  .replace(/^\D+/g, "")
                                                  .replace(
                                                    /(\d{3})(\d{3})(\d{4})$/,
                                                    "($1) $2-$3"
                                                  )}`
                                              : "No Data"} */}