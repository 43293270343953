import React, { useState } from "react";
import MedicalConditionDetail from "./MedicalConditionDetail";
import TakingAnyMedicineDetail from "./TakingAnyMedicineDetail";

const UnderwritingInformation = ({
  errors,
  register,
  fieldShowName,
  setValue,
  control,
}) => {
  const [heightFeet, setHeightFeet] = useState("");
  const [heightInches, setHeightInches] = useState("");

  const handleHeightFeetChange = (e) => {
    setHeightFeet(e.target.value);
    setValue("heightFeet", e.target.value);
  };

  const handleHeightInchesChange = (e) => {
    setHeightInches(e.target.value);
    setValue("heightInches", e.target.value);
  };
  const feetOptions = Array.from({ length: 7 }, (_, i) => i + 2);
  const inchesOptions = Array.from({ length: 10 }, (_, i) => i);

  const [disorder, setDisorder] = useState("");
  const [descriptionForDisorder, setDescriptionForDisorder] = useState("");

  const handleDisorderChange = (e) => {
    const value = e.target.value;
    setDisorder(value);
    setValue("disorder", value);
    if (value === "No") {
      setDescriptionForDisorder("");
      setValue("descriptionForDisorder", "");
    }
  };

  const [treatment, setTreatment] = useState("");
  const [descriptionForTreatment, setDescriptionForTreatment] = useState("");

  const handleTreatmentChange = (e) => {
    const value = e.target.value;
    setTreatment(value);
    setValue("treatment", value);
    if (value === "No") {
      setDescriptionForTreatment("");
      setValue("descriptionForTreatment", "");
    }
  };

  const [medicalConditions, setMedicalConditions] = useState("");
  const [medicalConditionDetails, setMedicalConditionDetails] = useState("");

  const handleMedicalConditionsChange = (e) => {
    const value = e.target.value;
    setMedicalConditions(value);
    setValue("medicalConditions", value);
    if (value === "No") {
      setMedicalConditionDetails("");
      setValue("medicalConditionDetails", "");
    }
  };

  const [medicationYesNo, setMedicationYesNo] = useState("");
  const [takingAnyMedicineDetail, setTakingAnyMedicineDetail] = useState("");

  const handleMedicationYesNoChange = (e) => {
    const value = e.target.value;
    setMedicationYesNo(value);
    setValue("medicationYesNo", value);
    if (value === "No") {
      setTakingAnyMedicineDetail("");
      setValue("takingAnyMedicineDetail", "");
    }
  };
  return (
    <>
      <div className="row my-2">
        <div className="col-7 pt-2">
          <label className="">What Is Your Height?</label>
        </div>
        <div className="col-5">
          <div className="row">
            <div className="col-6">
              <select
                className="form-control border border-dark"
                id="heightFeet"
                {...register("heightFeet")}
                value={heightFeet}
                onChange={handleHeightFeetChange}
              >
                <option value="">Feet</option>
                {feetOptions.map((feet) => (
                  <option key={feet} value={feet}>
                    {feet}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <select
                className="form-control border border-dark"
                id="heightInches"
                {...register("heightInches")}
                value={heightInches}
                onChange={handleHeightInchesChange}
              >
                <option value="">Inch</option>
                {inchesOptions.map((inch) => (
                  <option key={inch} value={inch}>
                    {inch}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-7 pt-2">
          <label htmlFor="weight" className="">
            What Is Your Approximate Weight?
          </label>
        </div>
        <div className="col-5 d-flex align-items-center">
          <input
            type="number"
            maxLength={256}
            className="form-control border border-dark"
            id="weight"
            {...register("weight", {})}
          />
          <div className="input-group-text">lbs</div>
        </div>
      </div>
      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">Do You Use Tobacco? </label>
        </div>
        <div className="col-5">
          <select
            className="form-control border border-dark"
            id="tobaccoUse"
            {...register("tobaccoUse", {
              required: false,
            })}
          >
            <option value="">Select</option>

            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">Do You Smoke Marijuana</label>
        </div>
        <div className="col-5">
          <select
            className="form-control border border-dark"
            id="smokeMarijuana"
            {...register("smokeMarijuana", {
              required: false,
            })}
          >
            <option value="">Select</option>

            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">
            {" "}
            Do You Have Any Physical, Mental, Or Substance Abuse Disorders?
          </label>
        </div>
        <div className="col-5">
          <select
            className="form-control border border-dark"
            id="disorder"
            {...register("disorder", {
              required: false,
            })}
            onChange={handleDisorderChange}
          >
            <option value="">Select</option>

            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
      {disorder === "Yes" && (
        <div className="row my-2">
          <div className="col-12 pt-2">
            <label className="">ReaPFD for abuse:-</label>
          </div>
          <div className="col-12">
            <input
              type="text"
              maxLength={256}
              className="form-control border border-dark"
              id="descriptionForDisorder"
              {...register("descriptionForDisorder", {
                required: false,
              })}
            />
          </div>
        </div>
      )}

      <div className="row my-2">
        <div className="col-12 pt-2">
          <label className="">
            {" "}
            In the last 5 years, have you received treatment by a physician,
            chiropractor, and/or mental health professional?
          </label>
        </div>
        <div className="col-5 offset-7">
          <select
            className="form-control form-control border border-dark"
            id="treatment"
            {...register("treatment", {
              required: false,
            })}
            onChange={handleTreatmentChange}
          >
            <option value="">Select</option>

            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>

      {treatment === "Yes" && (
        <div className="row my-2">
          <div className="col-12 pt-2">
            <label className=""> Reason for treatment:-</label>
          </div>
          <div className="col-12">
            <input
              type="text"
              maxLength={256}
              className="form-control border border-dark"
              id="descriptionForTreatment"
              {...register("descriptionForTreatment", {
                required: false,
              })}
            />
          </div>
        </div>
      )}

      <div className="row my-2">
        <div className="col-7 pt-2">
          <label className="">C)What Is Your Occupation? </label>
        </div>
        <div className="col-5">
          <input
            type="text"
            maxLength={256}
            // required
            className="form-control border border-dark"
            id="occupation"
            {...register("occupation", {
              required: false,
            })}
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col-7 pt-2">
          <label className="">What are Your Job Duties?</label>
        </div>
        <div className="col-5">
          <input
            type="text"
            maxLength={256}
            // required
            className="form-control border border-dark"
            id="jobDuties"
            {...register("jobDuties", {
              required: false,
            })}
          />
        </div>
      </div>

      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">
            Please Provide Me The Level Of Your Education?
          </label>
        </div>
        <div className="col-5">
          <select
            className="form-control border border-dark"
            id="levelOfEducation"
            {...register("levelOfEducation", {
              required: false,
            })}
          >
            <option value="">Select</option>

            <option value="High School">High School</option>
            <option value="College">College</option>
            <option value="Masters">Masters</option>
            <option value="PHD">PHD</option>
            <option value=" Medical Doctor">Medical Doctor</option>
            <option value="NA">NA</option>
          </select>
        </div>
      </div>

      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label className="">What Is Your Annual Income? </label>
        </div>
        <div className="col-5 input-group">
          <span className="input-group-text">$</span>

          <input
            type="number"
            maxLength={256}
            // required
            className="form-control border border-dark"
            id="annualIncome"
            {...register("annualIncome", {
              required: false,
            })}
          />
        </div>
      </div>

      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label>C)Do You Have Any Medical Conditions?</label>
        </div>
        <div className="col-5 ">
          <select
            className="form-control border border-dark"
            id="medicalConditions"
            {...register("medicalConditions", {
              required: false,
            })}
            onChange={handleMedicalConditionsChange}
          >
            <option value="">Select</option>

            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>

      {medicalConditions === "Yes" && (
        <MedicalConditionDetail
          medicalConditionDetails={medicalConditionDetails}
          register={register}
          setValue={setValue}
          control={control}
        />
      )}

      <div className="row my-2 pt-4">
        <div className="col-7 pt-2">
          <label>D)Are You Taking Any Medications?</label>
        </div>
        <div className="col-5 ">
          <select
            className="form-control border border-dark"
            id="medicationYesNo"
            {...register("medicationYesNo", {
              required: false,
            })}
            onChange={handleMedicationYesNoChange}
          >
            <option value="">Select</option>

            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>

      {medicationYesNo === "Yes" && (
        <TakingAnyMedicineDetail
          takingAnyMedicineDetail={takingAnyMedicineDetail}
          register={register}
          setValue={setValue}
          control={control}
        />
      )}
    </>
  );
};

export default UnderwritingInformation;
