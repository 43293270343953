import React from "react";
import Modal from "react-bootstrap/Modal";

const AddEmail = ({ emailModal, setEmailModal, onSave, email }) => {
  const handleCloseEmail = () => {
    setEmailModal(false);
  };

  const handleEmailSave = () => {
    onSave();
    handleCloseEmail();
  };

  return (
    <Modal show={emailModal} onHide={handleCloseEmail}>
      <div className="modal-content">
        <div className="modal-header">
          <h4
            className="modal-title text-dark font-weight-medium"
            id="exampleModalLabel"
          >
            Add Email
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseEmail}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group row">
            <label htmlFor="" className="col-sm-5 col-form-label">
              Email
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                maxLength={256}
                placeholder="Please enter valid email address"
                className="form-control"
                value={email}
                onChange={(e) => onSave(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={handleCloseEmail}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleEmailSave}
          >
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddEmail;
